import { DecisionDescriptor, StatusBy, ColumnsDefinitionsDescriptor } from 'constants/commonExportedInterfaces';

export const decideOnApplicableColumns = (
    decide: DecisionDescriptor,
    columnsDefinition: ColumnsDefinitionsDescriptor,
) => {
    let headers = columnsDefinition;

    if (decide === StatusBy.ANALYST)
        headers = columnsDefinition.filter(
            (set) => set.field !== StatusBy.REVIEWER && set.field !== 'saved_analyst_status',
        );

    if (decide === StatusBy.REVIEWER) headers = columnsDefinition.filter((set) => set.field !== StatusBy.ANALYST);

    return headers;
};

export default decideOnApplicableColumns;
