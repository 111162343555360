import './dashboard.scss';

import ButtonComponent from 'app/components/nds/button/button';
import CircleCheckIcon from '@nexus/core/dist/assets/icons/action/ic_check_circle_24px.svg';
import ColorInfoBlock from 'app/components/reusable/colorInfoBlock/ColorInfoBlock';
import DoneIcon from '@nexus/core/dist/assets/icons/action/ic_done_24px.svg';
import ListIcon from '@nexus/core/dist/assets/icons/action/ic_list_24px.svg';
import NexusBellIcon from '@nexus/core/dist/assets/icons/social/ic_notifications_none_24px.svg';
import { UserRole } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';

interface IDashboardInfoBlocks {
    user: UserRole;
    customOnClick: () => void;
}

const DashboardInfoBlocks: React.FC<IDashboardInfoBlocks> = ({ user, customOnClick }) => {
    return (
        <>
            <div
                data-testid='dashboard-info-blocks'
                className='dashboard-info-blocks-container nexus-row nexus-middle-sm'
            >
                <ColorInfoBlock
                    iconSrc={ListIcon}
                    info={i18n.t<string>('dashboard.inProgress')}
                    status={127}
                    color='blue'
                    classes={['nexus-mr-2']}
                />
                <ColorInfoBlock
                    iconSrc={NexusBellIcon}
                    info={i18n.t<string>('dashboard.actionRequired')}
                    status={127}
                    color='orange'
                    classes={['nexus-mr-2']}
                />
                <ColorInfoBlock
                    iconSrc={DoneIcon}
                    info={i18n.t<string>('dashboard.closed')}
                    status={127}
                    color='white'
                    classes={['nexus-mr-2']}
                />
                {user === UserRole.REVIEWER ? (
                    <ButtonComponent
                        click={() => customOnClick()}
                        type={['nexus-btn-primary', 'nexus-btn-large']}
                        label={i18n.t('dashboard.newCaseBtn')}
                        extraClass='new-case-btn'
                    />
                ) : (
                    // TODO:To be change after new design from UX is ready
                    <ColorInfoBlock
                        iconSrc={CircleCheckIcon}
                        info={i18n.t<string>('dashboard.newCasesInfo')}
                        status={3}
                        color='white-border'
                        classes={['nexus-mr-2', 'new-cases-info']}
                    />
                )}
            </div>
        </>
    );
};

export default DashboardInfoBlocks;
