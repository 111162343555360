import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import i18n from 'locales/i18n';
const mockRulesData = require('../../../components/tableToAuMapping/mockAuData.json');

/** newHomeObligationSelectors */
export const selectNewHomeObligation = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation ?? i18n.t('noData'),
);

export const selectNewHomeObligationForRules = createSelector(
    (state: RootState) => state.newHomeObligation,
    function () {
        return mockRulesData.result ?? i18n.t('noData');
    },
);

export const selectIsSubmittedDocumentExtractionAnalyst = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedDocumentExtractionAnalyst ?? i18n.t('noData'),
);

export const selectIsSubmittedDocumentExtractionReviewer = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedDocumentExtractionReviewer ?? i18n.t('noData'),
);

export const selectIsSubmittedObligationsToAuTableAnalyst = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedObligationsToAuTableAnalyst ?? i18n.t('noData'),
);

export const selectIsSubmittedObligationsToAuTableReviewer = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedObligationsToAuTableReviewer ?? i18n.t('noData'),
);

export const selectIsSubmittedAssessControlTableAnalyst = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedAssessControlTableAnalyst ?? i18n.t('noData'),
);

export const selectIsSubmittedProcessToRiskMappingAnalyst = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedProcessToRiskMappingAnalyst ?? i18n.t('noData'),
);

export const selectIsSubmittedRiskToControlMapping = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedRiskToControlMappingAnalyst ?? i18n.t('noData'),
);

export const selectIsSubmittedAssessControlTableReviewer = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedAssessControlTableReviewer ?? i18n.t('noData'),
);

export const selectIsSubmittedControlMappingTableAnalyst = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedControlMappingTableAnalyst ?? i18n.t('noData'),
);

export const selectIsSubmittedControlMappingTableReviewer = createSelector(
    (state: RootState) => state.newHomeObligation,
    (newHomeObligation) => newHomeObligation.isSubmittedControlMappingTableReviewer ?? i18n.t('noData'),
);
