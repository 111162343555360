import React, { useEffect, useState } from 'react';

import { NexusPagination } from '@nexus/react';
import { retrievePagination } from 'app/views/home/redux/homeSelectors';
import { useSelector } from 'react-redux';
import './pagination.scss';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

interface Props {
    offset: number; // Number record to be removed. Load the coresponding page 0 means first page
    limit: number; // Number of record per page
    size: number; // Total number of records
    selectedPage: (page: number) => void;
    page?: number;
}

const PaginationComponent: React.FC<Props> = (props: Props) => {
    let currentPage: number = 1;
    let totalPages: number = 1;

    const getCurrentPage = (offset: number, limit: number): number => {
        return Math.floor(offset / limit) + 1;
    };

    const getTotalPages = (limit: number, size: number): number => {
        return Math.ceil(Math.max(size, 1) / Math.max(limit, 1));
    };

    const getPages = (offset: number, limit: number, size: number) => {
        currentPage = getCurrentPage(offset, limit);
        totalPages = getTotalPages(limit, size);
        return currentPage;
    };

    const [page, setPage] = useState(getPages(props.offset, props.limit, props.size));
    const displayPaginationExternally = useSelector<boolean>(retrievePagination);

    useEffect(() => {
        setPage(getCurrentPage(props.offset, props.limit));
    }, [props.limit, props.offset]); // <- add the count variable here

    const setCurrentVal = (data: number) => {
        setPage(data);
        props.selectedPage(data);
    };

    return (
        <NexusPagination
            class={`pagination-style-${REACT_APP_CUSTOM_FOR_CLIENT} count-input-style-${REACT_APP_CUSTOM_FOR_CLIENT}`}
            slot='tfoot'
            data-testid='nexus-pagination'
            current={displayPaginationExternally ? props.page : page}
            max={totalPages}
            onChangeEvent={(event) => setCurrentVal(event.detail)}
        ></NexusPagination>
    );
};

export default PaginationComponent;
