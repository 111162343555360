import {
    DefaultSelect,
    categoryDropdownObligation,
    fileTypeDropdown,
    clientFileTypeDropdown,
    uploadComponentHeaders,
    UploadedDocumentText,
    //  Regulatory,
    // Policy,
    //Other,
} from 'constants/constants';
import { IFile, UploadFunctionProp, UserRole } from 'constants/commonExportedInterfaces';
import React, { useEffect, useState } from 'react';
import {
    clearObligationsData,
    selectedHomeCategory,
    selectedHomeTypeFile,
    uploadAllPageFile,
    uploadObligationFileToBackend,
    clearAllData,
} from 'app/views/home/redux/homeActions';
import { getUserRole, retrieveAllPagesFile } from 'app/views/home/redux/homeSelectors';

import LoaderComponent from '../nds/loader/loader';
import Select from '../nds/select/select';
import SingleFileUploadComponent from '../nds/singleFileUpload/singleFileUpload';
import TitleBar from '../reusable/titleBar/TitleBar';
import UploadButtons from '../reusable/uploadButtons/UploadButtons';
import i18n from 'locales/i18n';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';
import './uploadObligation.scss';
import { NexusAccordion, NexusAccordionContent, NexusAccordionTrigger } from '@nexus/react';

const UploadObligation: React.FC = () => {
    const [uploadedDoc, setUploadedDoc] = useState<IFile | any>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState<boolean>(true);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<string>('Not started');
    const dispatch = useAppDispatch();
    const allPagesFiles = useSelector(retrieveAllPagesFile);
    const userRole = useSelector(getUserRole);

    const [selectHomeCategory, setSelectHomeCategory] = useState<string>(DefaultSelect);
    const [fileTypeHome, setFileTypeHome] = useState<string>(DefaultSelect);
    //  const [clientFileTypeHome, setClientFileTypeHome] = useState<string>(DefaultSelect);

    useEffect(() => {
        if (!allPagesFiles.home.length) setUploadedDoc(null);
        if (allPagesFiles.home.length) setUploadedDoc(allPagesFiles.home[0]);
        setShowLoader(false);
    }, []);

    const clearUploadedFileInRedux = () => {
        dispatch(
            uploadAllPageFile({
                ...allPagesFiles,
                home: [],
            }),
        );
    };

    const uploadAllFiles = () => {
        const formData = new FormData();
        dispatch(clearObligationsData());

        dispatch(selectedHomeTypeFile(fileTypeHome));
        dispatch(selectedHomeCategory(selectHomeCategory));
        formData.append('fileToPredict', uploadedDoc);
        formData.append('flow_', 'ui_flow');
        formData.append('draft_obligation_filename', uploadedDoc.name);
        formData.append('draft_obligation_filesize', uploadedDoc.size.toString());
        // setShowLoader(true);
        setUploadProgress('In progress');

        const payload: UploadFunctionProp = { docType: changeFileTypeCasing() ?? '', data: formData };

        dispatch(uploadObligationFileToBackend(payload)).then((data: any) => {
            if (data && data.payload) {
                setUploadProgress('Completed');
                setShowUpload(false);
                setDataLoaded(true);
                dispatch(
                    uploadAllPageFile({
                        ...allPagesFiles,
                        home: [uploadedDoc],
                    }),
                );
            }
            // setShowLoader(false);
        });
    };

    const handleSubmit = () => {
        uploadedDoc && uploadAllFiles();
    };

    const removeAllFiles = () => {
        setUploadedDoc(null);
        dispatch(uploadAllPageFile({ ...allPagesFiles, home: [] }));
        setFileTypeHome(DefaultSelect);
        setSelectHomeCategory(DefaultSelect);
        dispatch(clearObligationsData());
        dispatch(clearAllData());
        setUploadProgress('Not started');
        setDataLoaded(false);
    };

    const handleCategoryValue = () => {
        return selectHomeCategory;
    };

    const handleCategory = (value: string) => {
        setSelectHomeCategory(value);
    };

    const handleFileType = (value: string) => {
        setFileTypeHome(value);
    };

    /*const handleClientFileType = (value: string) => {
        setClientFileTypeHome(value);
    };*/

    const changeFileTypeCasing = () => {
        /* switch (fileTypeHome) {
            case CFR:
                return cfr;
            case ASIC:
                return asic;
            case Generic:
                return generic;
        } */
        return fileTypeHome.toLowerCase();
    };

    /* const changeClientFileTypeCasing = () => {
        switch (clientFileTypeHome) {
            case Regulatory:
                return Regulatory;
            case Policy:
                return Policy;
            case Other:
                return Other;
        }
        return fileTypeHome.toLowerCase();
    };*/
    const activateUpload = () => {
        const isUploadButtonActive =
            uploadedDoc &&
            fileTypeHome !== 'Please Select Document Type' &&
            uploadProgress !== 'In progress' &&
            !dataLoaded;
        return !isUploadButtonActive;
    };

    const showFileUploadComponent = () => {
        setShowUpload(true);
        showUpload && setShowUpload(false);
    };

    const isAnalyst = userRole === UserRole.ANALYST;

    return isAnalyst ? (
        <>
            <div data-testid='upload-file-component' className='upload-file'>
                {REACT_APP_CUSTOM_FOR_CLIENT ? (
                    <NexusAccordion open={showUpload}>
                        <NexusAccordionTrigger onClick={() => showFileUploadComponent()}>
                            <h1 className='nexus-body'>
                                <b>{uploadComponentHeaders.home}</b>
                            </h1>
                        </NexusAccordionTrigger>
                        <NexusAccordionContent class='content-padding-zero'></NexusAccordionContent>
                    </NexusAccordion>
                ) : (
                    <TitleBar title={uploadComponentHeaders.home} />
                )}
                {showUpload && (
                    <div
                        className={`upload-file-styles nexus-row nexus-p-2 nexus-table no-border-${REACT_APP_CUSTOM_FOR_CLIENT}`}
                    >
                        {/* <span className={`upload-header-${REACT_APP_CUSTOM_FOR_CLIENT}`}></span> */}
                        <SingleFileUploadComponent
                            label={i18n.t<string>('uploadComponent.chooseFile')}
                            fileSizeInfo={i18n.t<string>('uploadComponent.fileSize')}
                            uploadedDoc={uploadedDoc}
                            setUploadedDoc={setUploadedDoc}
                            additionalFnOnRemoveFile={clearUploadedFileInRedux}
                            isAppliedStyle={REACT_APP_CUSTOM_FOR_CLIENT}
                            clear={removeAllFiles}
                            isProgressVisible={true}
                            uploadProgress={uploadProgress}
                        />
                        <Select
                            classes={
                                REACT_APP_CUSTOM_FOR_CLIENT
                                    ? 'nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 select-styles'
                                    : 'nexus-ml-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-my-2'
                            }
                            options={REACT_APP_CUSTOM_FOR_CLIENT === 'rwa' ? clientFileTypeDropdown : fileTypeDropdown}
                            customOnChange={handleFileType}
                            selectedValue={fileTypeHome}
                            showSelectedValueOnInit={true}
                            label={REACT_APP_CUSTOM_FOR_CLIENT && i18n.t<string>('uploadComponent.documentType')}
                        />
                        {REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa' && (
                            <Select
                                classes={
                                    'nexus-ml-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-my-2'
                                }
                                options={categoryDropdownObligation}
                                customOnChange={handleCategory}
                                selectedValue={handleCategoryValue()}
                                showSelectedValueOnInit={true}
                            />
                        )}

                        <UploadButtons
                            upload={handleSubmit}
                            clear={removeAllFiles}
                            disabledValue={REACT_APP_CUSTOM_FOR_CLIENT ? activateUpload() : false}
                            isClient={REACT_APP_CUSTOM_FOR_CLIENT}
                        />
                    </div>
                )}
                {showLoader && <LoaderComponent show={showLoader} fullscreen={false} />}
            </div>
            {dataLoaded && (
                <>
                    <div className='padding-label'>
                        <span className='header-text'> {UploadedDocumentText} </span>
                        {uploadedDoc?.name}
                        <div className='align-right'>
                            <span className='header-text'> Document Type: </span>
                            <span>{fileTypeHome}</span>
                        </div>
                    </div>
                    <hr />
                </>
            )}
        </>
    ) : null;
};

export default UploadObligation;
