import './modal.scss';

import { NexusModal, NexusModalBody, NexusModalFooter, NexusModalHeader } from '@nexus/react';

import React from 'react';

interface IModalProps {
    showModal?: boolean;
    headerContent?: string | React.ReactNode;
    size?: 'full' | 'lg' | 'md' | 'xl' | 'xs';
    subHeaderContent?: string;
    modalBodyContent?: React.ReactNode;
    primaryBtnText?: string;
    primaryBtnTestId?: string;
    secondaryBtnText?: string;
    secondaryBtnTestId?: string;
    primaryBtnOnclickCallBack?: () => void;
    secondaryBtnOnclickCallBack?: () => void;
    closeModal: () => void;
    disablePrimaryBtn?: boolean;
    disableSecondaryBtn?: boolean;
    isSecondButton?: boolean;
    footerOnRight?: boolean;
    modalTestId?: string;
    className?: string;
}
const ModalComponent = ({
    showModal,
    closeModal,
    headerContent,
    modalBodyContent,
    primaryBtnText,
    secondaryBtnText,
    primaryBtnTestId,
    size,
    primaryBtnOnclickCallBack,
    secondaryBtnOnclickCallBack,
    secondaryBtnTestId,
    disablePrimaryBtn,
    disableSecondaryBtn,
    isSecondButton = true,
    footerOnRight,
    modalTestId,
    className,
}: IModalProps) => {
    return (
        <div>
            <NexusModal
                data-testid={`modal-overlay${modalTestId ? '-' + modalTestId : ''}`}
                id='modal'
                show={showModal}
                size={size}
                className={className}
            >
                <NexusModalHeader data-testid='modal-header' onCloseEvent={closeModal}>
                    {headerContent}
                </NexusModalHeader>
                <NexusModalBody>{modalBodyContent}</NexusModalBody>
                <NexusModalFooter>
                    <div className={`${footerOnRight ? 'nexus-modal-footer-inner-container' : ''}`}>
                        <button
                            onClick={primaryBtnOnclickCallBack}
                            className='nexus-btn-primary'
                            data-testid={primaryBtnTestId}
                            disabled={disablePrimaryBtn}
                        >
                            {primaryBtnText}
                        </button>
                        {isSecondButton && (
                            <button
                                onClick={secondaryBtnOnclickCallBack}
                                className='nexus-btn'
                                data-testid={secondaryBtnTestId}
                                disabled={disableSecondaryBtn}
                            >
                                {secondaryBtnText}
                            </button>
                        )}
                    </div>
                </NexusModalFooter>
            </NexusModal>
        </div>
    );
};

export default ModalComponent;
