import './iconButton.scss';

import { NexusIcon } from '@nexus/react';

interface IIconButton {
    iconSrc: string;
    customOnClick: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    testId?: string;
    classes?: string;
}

const IconButton: React.FC<IIconButton> = ({ iconSrc, customOnClick, size = 'md', testId, classes }) => {
    return (
        <NexusIcon
            src={iconSrc}
            onClick={customOnClick}
            size={size}
            data-testid={testId}
            className={`icon ${classes ? classes : ''}`}
        />
    );
};

export default IconButton;
