import { IFile } from 'constants/commonExportedInterfaces';
import LoaderComponent from 'app/components/nds/loader/loader';
import Select from 'app/components/nds/select/select';
import { SelectOption } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';
import { selectAnalysts } from '../redux/dashboardSelectors';
import { useSelector } from 'react-redux';

interface INewCaseModalSecondStep {
    uploadedDoc: IFile;
    newCaseAnalyst: string;
    setNewAnalyst: React.Dispatch<React.SetStateAction<string>>;
}

const NewCaseModalSecondStep: React.FC<INewCaseModalSecondStep> = ({ uploadedDoc, newCaseAnalyst, setNewAnalyst }) => {
    const analysts: SelectOption[] = useSelector(selectAnalysts);

    const chooseAnalyst = (value: string) => {
        setNewAnalyst(value);
    };

    return (
        <div data-testid='new-case-modal-second-step'>
            <p>
                {i18n.t<string>('newCaseModal.uploadedDocumentName')} {uploadedDoc.name}
            </p>
            <div className='new-case-modal-body-second-step-instructions-container nexus-py-2 nexus-px-2 nexus-mb-2'>
                <p className='new-case-modal-body-second-step-total-obligation-info bold'>
                    {i18n.t<string>('newCaseModal.totalObligations')}
                </p>
                <p className='new-case-modal-body-second-step-instruction'>
                    {i18n.t<string>('newCaseModal.instructions')}
                </p>
            </div>
            <div className='nexus-mb-3'>
                <label>{i18n.t<string>('newCaseModal.analyst')}</label>
                {analysts.length > 0 ? (
                    <Select
                        options={analysts}
                        selectedValue={newCaseAnalyst}
                        customOnChange={chooseAnalyst}
                        specificTestId='new-case-modal-second-step-analyst'
                        specificOptionTestId='new-case-modal-second-step-analyst'
                        classes='new-case-modal-body-select'
                        showSelectedValueOnInit={true}
                    />
                ) : (
                    <div className='new-case-modal-body-loader-container'>
                        <LoaderComponent show={true} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewCaseModalSecondStep;
