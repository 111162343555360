import './dashboard.scss';

import {
    IDashboardAPIData,
    IDashboardBodyData,
    IFile,
    ITimeNotification,
    SelectOption,
    UserRole,
} from 'constants/commonExportedInterfaces';
import { NexusCheckbox, NexusTable } from '@nexus/react';
import { cancelationReasonCharactersLimit, defaultSelect } from './constants/dashboardConstants';
import {
    changeAssignedAnalyst,
    clearNotifications,
    createNewCase,
    deleteCase,
    getUsers,
} from './redux/dashboardActions';
import { decideOnApplicableColumns, decideOnChipBackgroundColor, decideOnChipContentColor } from './commonFunctions';
import { selectAnalysts, selectCases, selectNotifications } from './redux/dashboardSelectors';
import { useEffect, useState } from 'react';

import CancelCaseModalBody from './cancelCaseModal/CancelCaseModalBody';
import ChipComponent from 'app/components/nds/chip/chip';
import DashboardFilterArea from './DashboardFilterArea';
import DashboardInfoBlocks from './DashboardInfoBlocks';
import DashboardTableHeaderComponent from './DashboardTableHeader';
import DeleteIcon from '@nexus/core/dist/assets/icons/action/ic_delete_24px.svg';
import EditIcon from '@nexus/core/dist/assets/icons/editor/ic_mode_edit_24px.svg';
import IconButton from 'app/components/reusable/iconButton/IconButton';
import ModalComponent from 'app/components/nds/modal/modal';
import NewCaseModalBody from './newCaseModal/NewCaseModalBody';
import NotificationsContainer from 'app/components/reusable/notificationsContainer/NotificationsContainer';
import PaginationComponent from 'app/components/nds/pagination/pagination';
import ReassignModalBody from './reassignWorkModal/ReassignWorkModalBody';
import TableBodyComponent from 'app/components/reusable/tableBodyComponent/TableBodyComponent';
import TooltipComponent from 'app/components/nds/tooltip/tooltip';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import i18n from 'locales/i18n';
import { limit } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useModal } from 'hooks/useModal';
import { useSelectCheckbox } from 'hooks/useSelectCheckbox';
import { useSelectedPage } from 'hooks/useSelectedPage';
import { useSelector } from 'react-redux';

const Dashboard: React.FC = () => {
    const userRole: UserRole = useSelector(getUserRole);
    const [newCaseType, setNewCaseType] = useState(defaultSelect);
    const [newCaseDocType, setNewCaseDocType] = useState(defaultSelect);
    const [newCaseAnalyst, setNewCaseAnalyst] = useState('');
    const [reassignWorkAnalyst, setReassignWorkAnalyst] = useState(defaultSelect);
    const [cancelCaseReason, setCancelCaseReason] = useState('');
    const [activeStep, setActiveStep] = useState('uploadFile');
    const [uploadedDoc, setUploadedDoc] = useState<IFile | any>(null);
    const [showNewCaseModal, setShowNewCaseModal] = useState(false);
    const { page, selectedPage, pagesVisited } = useSelectedPage();
    const cases: IDashboardAPIData[] = useSelector(selectCases);
    const dispatch = useAppDispatch();
    const notifications: ITimeNotification[] = useSelector(selectNotifications);
    const analysts: SelectOption[] = useSelector(selectAnalysts);

    const [closeReassignWorkModal, reassignWorkModal, openReassignWorkModal, reassignWorkModalID] = useModal(
        'reassignWorkModal.reassignWorkModalHeader',
    );
    const [closeCancelCaseModal, cancelCaseModal, openCancelCaseModal, cancelCaseModalID] = useModal(
        'cancelCaseModal.cancelCaseModalHeader',
    );
    const [checkedState, setCheckedState, checkedStateAll, setCheckedStateAll, handleOnChange, handleOnChangeAll] =
        useSelectCheckbox();

    useEffect(() => {
        return () => {
            dispatch(clearNotifications());
        };
    }, []);

    const validateNewCaseModalStep1 = () => {
        const valid = newCaseType !== defaultSelect && newCaseDocType !== defaultSelect && uploadedDoc !== null;
        return !valid;
    };
    const validateNewCaseModalStep2 = () => newCaseAnalyst === defaultSelect;
    const validateReassignWorkModal = () => reassignWorkAnalyst === defaultSelect;
    const validateCancelCaseModal = () => cancelCaseReason.trim().length <= cancelationReasonCharactersLimit;

    const addNewCase = () => {
        toggleNewCaseModal();
        dispatch(
            createNewCase({
                assignedTo: newCaseAnalyst,
                cancelationReason: '',
                caseID: (parseInt(cases[cases.length - 1].caseID) + 1).toString(),
                caseName: uploadedDoc.name,
                caseType: newCaseType,
                lastEdit: new Date().toJSON().slice(0, 10).replace(/-/g, '/').split('/').reverse().join('/'),
                owner: newCaseAnalyst,
                status: 'Action required',
            }),
        );
    };

    const handleStepClick = () => {
        if (analysts.length === 0) dispatch(getUsers('ANALYST'));
        activeStep === 'uploadFile' ? setActiveStep('assignWork') : setActiveStep('uploadFile');
    };

    const clearUploadedFileInRedux = () => console.log('uploaded file cleared in redux');

    const reassignWork = (id: string) => {
        closeReassignWorkModal();
        dispatch(changeAssignedAnalyst({ caseID: id, analyst: reassignWorkAnalyst }));
        setReassignWorkAnalyst(defaultSelect);
    };

    const closeModal = (modal: string) => {
        switch (modal) {
            case 'reassignWork':
                setReassignWorkAnalyst(defaultSelect);
                closeReassignWorkModal();
                break;
            case 'cancelCase':
                setCancelCaseReason('');
                closeCancelCaseModal();
                break;
        }
    };

    const cancelCase = (id: string) => {
        closeCancelCaseModal();
        dispatch(deleteCase({ caseID: id, cancelationReason: cancelCaseReason }));
        setCancelCaseReason('');
    };

    const toggleNewCaseModal = () => {
        setShowNewCaseModal(!showNewCaseModal);
        setActiveStep('uploadFile');
        setUploadedDoc(null);
        setNewCaseAnalyst(defaultSelect);
        setNewCaseType(defaultSelect);
        setNewCaseDocType(defaultSelect);
    };

    const createTableBody = (data: IDashboardAPIData[]) => {
        const tableBodyData: IDashboardBodyData[] = [];

        data.forEach(
            ({ assignedTo, caseID, caseType, lastEdit, owner, caseName, status, cancelationReason }, index: number) => {
                if (cancelationReason === '')
                    tableBodyData.push({
                        assignedTo,
                        cancelationReason: '',
                        caseID,
                        caseName: (
                            <TooltipComponent message={caseName} placement='bottom' allowClick={false}>
                                <span data-testid='dashboard-regulation-name-tooltip' className='ellipsis'>
                                    {caseName}
                                </span>
                            </TooltipComponent>
                        ),
                        caseType,
                        delete: (
                            <IconButton
                                key={caseID}
                                iconSrc={DeleteIcon}
                                customOnClick={() => openCancelCaseModal(caseID, caseName)}
                                testId='dashboard-delete-icon-btn'
                            />
                        ),
                        edit:
                            status === 'Closed' ? (
                                ''
                            ) : (
                                <IconButton
                                    key={caseID}
                                    iconSrc={EditIcon}
                                    customOnClick={() => {
                                        if (analysts.length === 0) dispatch(getUsers('ANALYST'));
                                        openReassignWorkModal(caseID, caseName);
                                    }}
                                    testId='dashboard-edit-icon-btn'
                                />
                            ),
                        lastEdit,
                        owner,
                        select: (
                            <NexusCheckbox
                                data-testid='dashboard-table-checkbox'
                                className='nexus-rhythm-2'
                                onInput={() => handleOnChange(index, checkedState, setCheckedState)}
                                checked={checkedState[index]}
                            />
                        ),
                        status: (
                            <ChipComponent
                                backgroundColor={decideOnChipBackgroundColor(status)}
                                contentColor={decideOnChipContentColor(status)}
                                title={status}
                            />
                        ),
                    });
            },
        );

        return tableBodyData.reverse();
    };

    const tableBodyData = createTableBody(cases);

    return (
        <div data-testid='dashboard' className='dashboard-main-container nexus-padding-3 nexus-pt-3 nexus-pb-3'>
            <NotificationsContainer notifications={notifications} />
            <h3 className='nexus-h4 title'>{i18n.t<string>('dashboard.title')}</h3>
            <DashboardInfoBlocks user={userRole} customOnClick={() => toggleNewCaseModal()} />
            <DashboardFilterArea />
            <NexusTable type='custom'>
                <DashboardTableHeaderComponent
                    user={userRole}
                    checkedStateAll={checkedStateAll}
                    handleOnChangeAll={handleOnChangeAll}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    setCheckedStateAll={setCheckedStateAll}
                />
                {!!tableBodyData?.length && (
                    <TableBodyComponent
                        tableData={tableBodyData}
                        pagesVisited={pagesVisited}
                        checkedState={checkedState}
                        setCheckedState={setCheckedState}
                        decideOn={userRole}
                        decideOnApplicableColumns={decideOnApplicableColumns}
                    />
                )}
                <PaginationComponent
                    selectedPage={selectedPage}
                    offset={0}
                    limit={limit}
                    size={tableBodyData.length}
                    page={page}
                />
            </NexusTable>

            <ModalComponent
                modalTestId='dashboard-new-case-modal'
                showModal={showNewCaseModal}
                closeModal={toggleNewCaseModal}
                headerContent={'Create New Case'}
                modalBodyContent={
                    <NewCaseModalBody
                        newCaseType={newCaseType}
                        newDocType={newCaseDocType}
                        setNewCaseType={setNewCaseType}
                        setNewDocType={setNewCaseDocType}
                        activeStep={activeStep}
                        uploadedDoc={uploadedDoc}
                        setUploadedDoc={setUploadedDoc}
                        newCaseAnalyst={newCaseAnalyst}
                        setNewAnalyst={setNewCaseAnalyst}
                        additionalFnOnRemoveFile={() => clearUploadedFileInRedux}
                    />
                }
                primaryBtnText={'Cancel'}
                primaryBtnOnclickCallBack={toggleNewCaseModal}
                primaryBtnTestId='new-case-modal-primary-btn'
                secondaryBtnText={activeStep === 'uploadFile' ? 'Continue' : 'Assign Work'}
                secondaryBtnOnclickCallBack={activeStep === 'uploadFile' ? () => handleStepClick() : () => addNewCase()}
                secondaryBtnTestId={'new-case-modal-secondary-btn'}
                isSecondButton={true}
                size='lg'
                footerOnRight={true}
                disableSecondaryBtn={
                    activeStep === 'uploadFile' ? validateNewCaseModalStep1() : validateNewCaseModalStep2()
                }
            />

            <ModalComponent
                showModal={reassignWorkModal.open}
                closeModal={() => closeModal('reassignWork')}
                headerContent={reassignWorkModal.header ?? ''}
                modalBodyContent={
                    <ReassignModalBody
                        reassignWorkAnalyst={reassignWorkAnalyst}
                        setReassignWorkAnalyst={setReassignWorkAnalyst}
                    />
                }
                primaryBtnText={'Cancel'}
                primaryBtnOnclickCallBack={() => closeModal('reassignWork')}
                primaryBtnTestId='reassign-work-modal-primary-btn'
                secondaryBtnText={'Assign Work'}
                secondaryBtnOnclickCallBack={() => reassignWork(reassignWorkModalID)}
                secondaryBtnTestId='reassign-work-modal-secondary-btn'
                isSecondButton={true}
                size='md'
                footerOnRight={true}
                disableSecondaryBtn={validateReassignWorkModal()}
            />

            <ModalComponent
                showModal={cancelCaseModal.open}
                closeModal={() => closeModal('cancelCase')}
                headerContent={cancelCaseModal.header ?? ''}
                modalBodyContent={
                    <CancelCaseModalBody
                        setCancelCaseReason={setCancelCaseReason}
                        cancelCaseReason={cancelCaseReason}
                    />
                }
                primaryBtnText={'Cancel'}
                primaryBtnOnclickCallBack={() => closeModal('cancelCase')}
                primaryBtnTestId='cancel-case-modal-primary-btn'
                secondaryBtnText={'Erase case'}
                secondaryBtnOnclickCallBack={() => cancelCase(cancelCaseModalID)}
                isSecondButton={true}
                size='md'
                footerOnRight={true}
                disableSecondaryBtn={validateCancelCaseModal()}
            />
        </div>
    );
};

export default Dashboard;
