import './dashboard.scss';

import SearchboxPanel from 'app/components/reusable/searchboxPanel/SearchboxPanel';
import Select from 'app/components/nds/select/select';
import { fakeOptions } from './fakeTableData';
import i18n from 'locales/i18n';

const DashboardFilterArea: React.FC = () => {
    return (
        <div data-testid='dashboard-filter-bar' className='dashboard-filter-bar nexus-row nexus-middle-xs'>
            <p className='nexus-h5'>My Cases</p>
            <div className='dashboard-filter-area'>
                <label>Filter</label>
                <div className='nexus-row nexus-middle-xs'>
                    <Select
                        classes='dashboard-select'
                        options={fakeOptions}
                        selectedValue={i18n.t<string>('dashboard.defaultSelect')}
                    />
                    <SearchboxPanel isHidden={true} />
                </div>
            </div>
        </div>
    );
};

export default DashboardFilterArea;
