import React, { useState } from 'react';
import { NexusIcon, NexusTable, NexusCheckbox } from '@nexus/react';
import ContentIcRemove24px from '@nexus/core/dist/assets/icons/content/ic_remove_24px.svg';
import ContentIcAdd24px from '@nexus/core/dist/assets/icons/content/ic_add_24px.svg';
import ChipComponentPercent from '../chipComponentPercent/ChipComponentPercent';
import ButtonsComponentSelectStatus from '../buttonsComponentSelectStatus/ButtonsComponentSelectStatus';
import DescriptionComponentModal from '../descriptionComponentModal/DescriptionComponentModal';
import MultipleChoiceConfirmationPanel from '../multipleChoiceConfirmationPanel/MultipleChoiceConfirmationPanel';
import PaginationComponent from '../../nds/pagination/pagination';
import ModalComponent from '../../nds/modal/modal';
import { useSelectedPage } from 'hooks/useSelectedPage';
import { limit } from 'constants/constants';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import './customTable.scss';

type CustomTableProps = {
    attrId: string;
    pagination?: boolean;
    mainColumnsDef: {
        field: string;
        label: string;
        isSortable: boolean;
        headerClass: string;
        cellClass: string;
        tooltip: boolean;
    }[];
    subColumnDef: {
        field: string;
        label: string;
        isSortable: boolean;
        headerClass: string;
        cellClass: string;
        tooltip: boolean;
    }[];
    mainTabledata: any;
    subTableData: any;
    statusUpdateCallback: any;
    isSubmitted: boolean;
    checkedState: boolean[][];
    handleChange: (
        mainIndex: number,
        subIndex: number,
        checkedState: boolean[][],
        setCheckedState: (value: boolean[][]) => void,
    ) => void;
    setCheckedState: React.Dispatch<React.SetStateAction<boolean[][]>>;
    checkedStateAll: boolean;
    handleOnChangeAll: (
        checkedState: boolean[][],
        checkedStateAll: boolean,
        setCheckedState: (value: boolean[][]) => void,
        setCheckedStateAll: (value: boolean) => void,
    ) => void;
    setCheckedStateAll: (value: boolean) => void;
};

const CustomTable: React.FC<CustomTableProps> = ({
    attrId,
    pagination = true,
    mainColumnsDef,
    subColumnDef,
    mainTabledata,
    subTableData,
    statusUpdateCallback,
    isSubmitted,
    checkedState,
    handleChange,
    setCheckedState,
    checkedStateAll,
    handleOnChangeAll,
    setCheckedStateAll,
}) => {
    const isExpandedRow = [...Array(mainTabledata.length)].map(() => false);
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const [expandedRow, setExpandedRow] = useState(isExpandedRow);
    const [expandAll, setExpandAll] = useState<boolean>(false);
    const { page, selectedPage, pagesVisited } = useSelectedPage();

    const getIconTemplate = (rowidx: number) => {
        if (expandedRow[returnrowId(rowidx)]) {
            return (
                <div className='text-right icon'>
                    <NexusIcon src={ContentIcRemove24px}></NexusIcon>
                </div>
            );
        }

        return (
            <div className='text-right icon'>
                <NexusIcon src={ContentIcAdd24px}></NexusIcon>
            </div>
        );
    };

    const onRowClick = (rowIndex: number) => {
        const temp = [...expandedRow];
        temp[rowIndex] = !temp[rowIndex];
        setExpandedRow(temp);
    };

    const toggleAll = (isExpandAll: boolean) => {
        if (isExpandAll) {
            const isExpandedRow = [...Array(mainTabledata.length)].map(() => true);
            setExpandedRow(isExpandedRow);
        } else {
            const isExpandedRow = [...Array(mainTabledata.length)].map(() => false);
            setExpandedRow(isExpandedRow);
        }
        setExpandAll(isExpandAll);
    };

    const returnrowId = (id: number) => {
        if (pagesVisited() >= limit) {
            return id + limit;
        } else return id;
    };
    return (
        <>
            <MultipleChoiceConfirmationPanel
                tableData={subTableData}
                isHidden={checkedState.every((item) => item.every((i) => !i)) || isSubmitted}
                checkedState={checkedState}
                statusBy={''}
                callbackFn={statusUpdateCallback}
                isCustomStatus={true}
            />
            <NexusTable
                attrId={attrId}
                type='custom'
                pagination={pagination}
                key={'customTable'}
                className='main-table'
            >
                <tr slot='thead' className='table-header'>
                    <th className='toggle-all toggle'>
                        <NexusIcon
                            src={expandAll ? ContentIcRemove24px : ContentIcAdd24px}
                            onClick={() => toggleAll(!expandAll)}
                        ></NexusIcon>
                    </th>
                    <th className='custom-table-checkbox'>
                        <NexusCheckbox
                            checked={checkedStateAll}
                            onInput={() =>
                                handleOnChangeAll(checkedState, checkedStateAll, setCheckedState, setCheckedStateAll)
                            }
                            data-testid='indeterminateValue'
                            className='nexus-rhythm-2'
                            disabled={isSubmitted}
                        />
                    </th>
                    {mainColumnsDef.map((element, _index) => (
                        <th className={element.field} key={`column-def-${_index}`}>
                            {element.label}
                        </th>
                    ))}
                </tr>
                <div slot='tbody'>
                    {mainTabledata?.slice(pagesVisited(), pagesVisited() + limit).map((row: any, rowidx: number) => (
                        <div key={`row.make-${rowidx}`}>
                            <tr
                                data-testid={`expandable-row-${rowidx}`}
                                className='tr-expandable'
                                onClick={() => onRowClick(returnrowId(rowidx))}
                            >
                                <td className='toggle'>{getIconTemplate(rowidx)}</td>
                                <td className='custom-table-checkbox'></td>
                                {Object.values(Object.keys(mainTabledata[0])).map((col: any, cidx) => {
                                    return (
                                        <td className={col} key={`col-${cidx}`}>
                                            {row[col].tooltip ? (
                                                <DescriptionComponentModal
                                                    description={row[col].tooltipText}
                                                    popupDisplayText={row[col].label}
                                                    confidenceScore={0}
                                                    title={row[col].field}
                                                    subTitle={''}
                                                    hideConfidence={true}
                                                    tooltipText={'obligationSummary.tooltipText'}
                                                    openDescriptionModal={openDescriptionModal}
                                                />
                                            ) : (
                                                row[col].label
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>

                            {expandedRow[returnrowId(rowidx)] ? (
                                <div key={`hidden${row.make}-${returnrowId(rowidx)}`}>
                                    <NexusTable
                                        attrId={attrId}
                                        type='custom'
                                        pagination={false}
                                        key={'customTable'}
                                        className='sub-table'
                                    >
                                        <tr slot='thead'>
                                            <th className='custom-table-checkbox'></th>
                                            {subColumnDef.map((element, _index) => (
                                                <th
                                                    className={`${element.field}`}
                                                    key={`sub-header-def-${rowidx}${_index}`}
                                                >
                                                    {element.label}
                                                </th>
                                            ))}
                                        </tr>
                                        <div slot='tbody'>
                                            {subTableData[returnrowId(rowidx)].map((data: any, i: number) => (
                                                <tr key={`sub-row-def-${rowidx}${i}`}>
                                                    <td className='custom-table-checkbox'>
                                                        {' '}
                                                        <NexusCheckbox
                                                            data-testid='obligation-summary-table-checkbox'
                                                            className='nexus-rhythm-2'
                                                            onInput={() => {
                                                                handleChange &&
                                                                    setCheckedState &&
                                                                    handleChange(
                                                                        returnrowId(rowidx),
                                                                        i,
                                                                        checkedState,
                                                                        setCheckedState,
                                                                    );
                                                            }}
                                                            checked={
                                                                checkedState.length > 0 &&
                                                                checkedState[returnrowId(rowidx)][i]
                                                            }
                                                            disabled={isSubmitted}
                                                        />
                                                    </td>
                                                    {subColumnDef.map((d, key) => (
                                                        <td
                                                            className={`${d.field}`}
                                                            key={`sub-data-def-${rowidx}${i}${key}`}
                                                        >
                                                            {d.field === 'confidenceScore' ? (
                                                                <ChipComponentPercent value={data[d.field]} />
                                                            ) : d.field === 'status' ? (
                                                                <ButtonsComponentSelectStatus
                                                                    uniqueKeyID={data.uniqueKeyID}
                                                                    statusAnalyst={data.status}
                                                                    statusReviewer={''}
                                                                    callbackFn={statusUpdateCallback}
                                                                    name_status_analyst={'status'}
                                                                    name_status_reviewer={'status_to_be_reviewed'}
                                                                    isSubmitted={isSubmitted}
                                                                />
                                                            ) : d.tooltip ? (
                                                                <DescriptionComponentModal
                                                                    description={data[d.field]}
                                                                    confidenceScore={0}
                                                                    title={d.label}
                                                                    subTitle={''}
                                                                    hideConfidence={true}
                                                                    tooltipText={'obligationSummary.tooltipText'}
                                                                    openDescriptionModal={openDescriptionModal}
                                                                />
                                                            ) : (
                                                                data[d.field]
                                                            )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </div>
                                    </NexusTable>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </div>
                <PaginationComponent
                    selectedPage={selectedPage}
                    offset={0}
                    limit={limit}
                    size={mainTabledata.length}
                    page={page}
                />
            </NexusTable>
            <ModalComponent
                className='custom-table-modal'
                showModal={descriptionModal.open}
                closeModal={closeDescriptionModal}
                headerContent={descriptionModal.header ?? ''}
                modalBodyContent={descriptionModal.description}
                primaryBtnText={'Close'}
                primaryBtnOnclickCallBack={closeDescriptionModal}
                isSecondButton={false}
                size='lg'
                footerOnRight={true}
            />
        </>
    );
};

export default CustomTable;
