import './userProfile.scss';

import { DefaultSelect, dashboardPath } from 'constants/constants';
import { IUserProfileAttributes, UserRole } from 'constants/commonExportedInterfaces';
import { chipComponentAttributes, userRoles } from 'constants/userProfileConstants';

import ButtonComponent from 'app/components/nds/button/button';
import ChipComponent from 'app/components/nds/chip/chip';
import { NexusCard } from '@nexus/react';
import React from 'react';
import ReviewerProfileView from '../reviewerProfileView/ReviewerProfileView';
import Select from 'app/components/nds/select/select';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import i18n from 'locales/i18n';
import { retrieveUserName } from 'auth';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * Ability to view logged on user details against "User" field.
 * @param priority: Priority
 * @param status : New (For new file upload)
 * @param created : Provide details of Creator and time stamp in free flow format
 * @param updated : (If Updated) Provide details of user and time stamp in free flow format
 */

type IUSerProfileView = {
    handleUserProfile: (event?: any) => void;
};

const UserProfileView: React.FC<IUSerProfileView> = ({ handleUserProfile }: IUSerProfileView) => {
    const fullName: string = useSelector(retrieveUserName);
    const userRole: UserRole = useSelector(getUserRole);
    const navigate = useNavigate();

    const mockDataForDemo = {
        role: userRole === DefaultSelect ? UserRole.ANALYST : userRole,
        title: i18n.t('userProfileView.titleRegulatoryChange'),
    };

    const { role, title } = mockDataForDemo;

    const analystAttributes: Array<IUserProfileAttributes> = [
        { label: i18n.t('userProfileView.created'), testId: 'creatorName', value: fullName },
        { label: i18n.t('userProfileView.updated'), testId: 'updatedName', value: fullName },
    ];

    const isReviewer = userRole === UserRole.REVIEWER;

    const goToDashboard = () => navigate(dashboardPath);

    return (
        <div data-testid='user-profile-view' className='user-profile-view'>
            <div className='nexus-h4'>{title}</div>
            <div onClick={() => goToDashboard()} className='go-back nexus-subtitle-sm nexus-my-2'>
                &larr; &nbsp; {i18n.t<string>('userProfileView.back')}{' '}
            </div>
            <NexusCard>
                <div className='nexus-row'>
                    <div className='nexus-col-m-1'>
                        <ButtonComponent type={['nexus-btn']} label={i18n.t('userProfileView.action')} />
                    </div>
                    <div className='nexus-col'>
                        <Select
                            classes={'nexus-ml-2 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 select-dropdown-styles'}
                            options={userRoles}
                            customOnChange={handleUserProfile}
                            selectedValue={userRole}
                        />
                    </div>
                </div>
            </NexusCard>
            <NexusCard>
                <>
                    <div className='nexus-col-xs-4 nexus-row nexus-my-1'>
                        <p>
                            {i18n.t<string>('userProfileView.userType')}: <b data-testid='user-role'>{role}</b>
                        </p>
                    </div>
                    {chipComponentAttributes.map((element, index) => {
                        return (
                            <div className='nexus-col-xs-4 nexus-row ' key={`${element}-${index}`}>
                                <div className='nexus-mt-2'>{element.label}:</div>
                                <div className='nexus-ml-2 rcm-chips-styles nexus-mt-2'>
                                    <ChipComponent title={element.value} contentStyles='rcm-chip-content-style' />
                                </div>
                            </div>
                        );
                    })}
                    {analystAttributes.map((element, index) => {
                        return (
                            <div className='nexus-row nexus-col-xs-4' key={`${element}-${index}`}>
                                <p data-testid={element.testId}>
                                    {element.label}:<span className='nexus-pl-1'>{element.value}</span>
                                </p>
                            </div>
                        );
                    })}
                </>
            </NexusCard>
            {isReviewer && <ReviewerProfileView />}
        </div>
    );
};

export default UserProfileView;
