import ChipComponentPercent from 'app/components/reusable/chipComponentPercent/ChipComponentPercent';
import TextareaComponent from 'app/components/nds/textarea/textarea';
import i18n from 'locales/i18n';
import { updateEditSummaryText } from 'app/views/home/redux/newHomeObligationAction';
import { useAppDispatch } from 'store';
import { useState } from 'react';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';
import { saveSummaryTextWithRulesID } from 'app/views/home/redux/homeActions';
import { IObigationWIthRulesID } from 'constants/commonExportedInterfaces';

interface IEditObligationSummaryModal {
    bodyContent: React.ReactNode;
    open: boolean;
    header: React.ReactNode;
}

export function useEditObligationSummaryModal() {
    const dispatch = useAppDispatch();
    const [editObligationSummaryModal, setCloseEditObligationSummaryModal] = useState(
        {} as IEditObligationSummaryModal,
    );
    const [editSummaryText, setEditSummaryText] = useState<string>('');
    const [uniqueKeyID, setUniqueKeyID] = useState<string>('');
    const [ruleID, setRuleID] = useState<string>('');

    const handleEditSummaryText = (event: React.FormEvent<HTMLNexusTextareaElement>) => {
        let target = event.target as HTMLNexusTextareaElement;
        setEditSummaryText(target.value);
    };

    const openEditObligationSummaryModal = (
        title: string,
        subTitle: string,
        confidence: number,
        obligationSummaryText: string,
        obligationHighlightedText: string,
        uniqueKeyID: string,
        rule_id: string,
    ) => {
        setRuleID(rule_id);
        setUniqueKeyID(uniqueKeyID);
        setCloseEditObligationSummaryModal({
            bodyContent: (
                <div className='nexus-row'>
                    {REACT_APP_CUSTOM_FOR_CLIENT === 'rwa' ? (
                        <div className='nexus-col-md-8'>
                            <TextareaComponent
                                value={obligationSummaryText}
                                onInputHandler={(event: React.FormEvent<HTMLNexusTextareaElement>) =>
                                    handleEditSummaryText(event)
                                }
                            />
                        </div>
                    ) : (
                        <>
                            <div className='nexus-col-md-4'>
                                <h3 className='nexus-mb-1'>
                                    {i18n.t<string>('obligationSummary.obligationHighlightedText')}
                                </h3>
                                <div className='nexus-textarea'>{obligationHighlightedText}</div>
                            </div>
                            <div className='nexus-col-md-4'>
                                <h3 className='nexus-mb-1'>
                                    {i18n.t<string>('obligationSummary.obligationSummaryText')}
                                </h3>
                                <TextareaComponent
                                    value={obligationSummaryText}
                                    onInputHandler={(event: React.FormEvent<HTMLNexusTextareaElement>) =>
                                        handleEditSummaryText(event)
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
            ),
            header: (
                <div>
                    <h3 className='nexus-mb-1'>{i18n.t<string>(title)}</h3>
                    <div className='nexus-row nexus-middle-xs'>
                        {REACT_APP_CUSTOM_FOR_CLIENT === 'rwa' ? (
                            <>
                                <div className='nexus-row nexus-middle-xs sub-header'>
                                    <span className='nexus-mr-1 label'>
                                        {i18n.t<string>('obligationSummary.citationSectionLabel')}:{' '}
                                    </span>
                                    <span className='nexus-mr-1'>{subTitle} </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='nexus-row nexus-middle-xs'>
                                    <p className='nexus-mr-1'>{subTitle} </p>
                                </div>
                                <div className='nexus-row nexus-middle-xs'>
                                    <p className='nexus-mr-1 label'>
                                        | {i18n.t<string>('obligationSummary.confidence')}:{' '}
                                    </p>
                                    <ChipComponentPercent value={confidence} />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ),
            open: true,
        });
    };

    const closeEditObligationSummaryModal = () => {
        setCloseEditObligationSummaryModal({ bodyContent: '', header: '', open: false });
    };

    const body: IObigationWIthRulesID = {
        putBody: {
            updated_summary_text: editSummaryText,
        },
        rule_id: ruleID,
    };

    const saveChangesModal = () => {
        dispatch(saveSummaryTextWithRulesID(body));
        dispatch(updateEditSummaryText({ editSummaryText, uniqueKeyID }));
        setCloseEditObligationSummaryModal({ bodyContent: '', header: '', open: false });
    };

    return [
        closeEditObligationSummaryModal,
        editObligationSummaryModal,
        openEditObligationSummaryModal,
        saveChangesModal,
    ] as const;
}
