import { RootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import i18n from 'locales/i18n';

export const selectCases = createSelector(
    (state: RootState) => state.dashboard,
    (dashboard) => dashboard.cases ?? i18n.t('noData'),
);

export const selectAnalysts = createSelector(
    (state: RootState) => state.dashboard,
    (dashboard) => dashboard.analysts ?? i18n.t('noData'),
);

export const selectNotifications = createSelector(
    (state: RootState) => state.dashboard,
    (dashboard) => dashboard.notifications ?? i18n.t('noData'),
);
