import i18n from 'locales/i18n';
import './cancelCaseModal.scss';
import TextareaComponent from 'app/components/nds/textarea/textarea';

interface ICancelCaseModalBody {
    setCancelCaseReason: React.Dispatch<React.SetStateAction<string>>;
    cancelCaseReason: string;
}

const CancelCaseModalBody: React.FC<ICancelCaseModalBody> = ({ setCancelCaseReason, cancelCaseReason }) => {
    const typeCancelCaseReason = (event: React.FormEvent<HTMLNexusTextareaElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        setCancelCaseReason(target.value);
    };

    return (
        <div data-testid='cancel-case-modal-body' className='cancel-case-modal-body-container'>
            <label>{i18n.t<string>('cancelCaseModal.cancelationReason')}</label>
            <TextareaComponent
                placeholder={i18n.t<string>('cancelCaseModal.reason')}
                onInputHandler={(event: React.FormEvent<HTMLNexusTextareaElement>) => typeCancelCaseReason(event)}
                value={cancelCaseReason}
                maxLength={300}
            />
            <div className='cancel-case-modal-body-characters-info nexus-row nexus-middle-2xs'>
                <p className='cancel-case-modal-body-characters-info-p'>300 characters max</p>
                <p
                    data-testid='cancel-case-modal-body-characters-check'
                    className='cancel-case-modal-body-characters-info-p right'
                >
                    {cancelCaseReason === '' ? '0' : cancelCaseReason.trim().length}/300
                </p>
            </div>
        </div>
    );
};

export default CancelCaseModalBody;
