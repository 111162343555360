import './reassignWorkModal.scss';

import LoaderComponent from 'app/components/nds/loader/loader';
import Select from 'app/components/nds/select/select';
import { SelectOption } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';
import { selectAnalysts } from '../redux/dashboardSelectors';
import { useSelector } from 'react-redux';

interface IReassignModalBody {
    reassignWorkAnalyst: string;
    setReassignWorkAnalyst: React.Dispatch<React.SetStateAction<string>>;
}

const ReassignModalBody: React.FC<IReassignModalBody> = ({ reassignWorkAnalyst, setReassignWorkAnalyst }) => {
    const analysts: SelectOption[] = useSelector(selectAnalysts);

    const chooseAnalyst = (value: string) => {
        setReassignWorkAnalyst(value);
    };

    return (
        <div data-testid='reassign-work-modal-body' className='reassign-work-modal-body-container'>
            <label>{i18n.t<string>('reassignWorkModal.analyst')}</label>
            {analysts.length > 0 ? (
                <Select
                    options={analysts}
                    selectedValue={reassignWorkAnalyst}
                    customOnChange={chooseAnalyst}
                    classes='reassign-work-modal-body-select'
                    specificTestId='reassign-work-modal-body'
                    specificOptionTestId='reassign-work-modal-body'
                    showSelectedValueOnInit={true}
                />
            ) : (
                <div className='reassign-work-modal-body-loader-container'>
                    <LoaderComponent show={true} />
                </div>
            )}
        </div>
    );
};

export default ReassignModalBody;
