import ChipComponentPercent from 'app/components/reusable/chipComponentPercent/ChipComponentPercent';
import i18n from 'locales/i18n';
import { useState } from 'react';

interface IDescriptionModal {
    open: boolean;
    description: string;
    title: string;
    header: React.ReactNode;
}

export function useDescriptionModal() {
    const [descriptionModal, setDescriptionModal] = useState({} as IDescriptionModal);

    const openDescriptionModal = (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => {
        setDescriptionModal({
            description,
            header: (
                <div>
                    <h3 className='nexus-mb-1 header'>{i18n.t<string>(title)}</h3>
                    <div className='nexus-row nexus-middle-xs'>
                        {name && <div className='nexus-mr-1'>{name} | </div>}
                        <div className='nexus-row nexus-middle-xs'>
                            {!hideConfidence ? (
                                <>
                                    <p className='nexus-mr-1'>{i18n.t<string>('tableToAuMapping.confidence')}: </p>
                                    <ChipComponentPercent value={confidence} />
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            ),
            open: true,
            title,
        });
    };

    const closeDescriptionModal = () => {
        setDescriptionModal({ description: '', header: '', open: false, title: '' });
    };

    return [closeDescriptionModal, descriptionModal, openDescriptionModal] as const;
}
