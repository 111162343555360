import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import CustomTable from '../reusable/customTable/CustomTable';
import i18n from 'locales/i18n';
import { getRiskToControlMapping } from 'app/views/home/redux/homeActions';
import { retrieveHashValue, selectNotificationsData, getUserRole } from 'app/views/home/redux/homeSelectors';
import {
    ICustomControlsMappingStructure,
    INewHomeObligationSliceReducer,
    IRiskToControlsMappingStructure,
} from 'app/views/home/redux/newHomeObligationInterfaces';
import { buttonValidation } from 'services/buttonValidation';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import { addNotificationData } from 'app/views/home/redux/homeActions';
import { selectIsSubmittedRiskToControlMapping, selectNewHomeObligation } from 'app/views/home/redux/newHomeSelectors';
import LoaderComponent from '../nds/loader/loader';
import './controlsMapping.scss';
import { ButtonType, ITimeNotification, VariantChose, UserRole } from 'constants/commonExportedInterfaces';
import { updateStatusInRiskToControlMapping, setStatus } from 'app/views/home/redux/newHomeObligationAction';
import { useSelectCheckboxCustom } from 'hooks/useSelectCheckboxCustom';

const mainColumnHeaders = [
    {
        field: 'riskAUDescriptionUniqueId',
        label: 'RISK ID',
    },
    {
        field: 'riskLevel1',
        label: 'RISK LEVEL-1',
    },
    {
        field: 'riskLevel2',
        label: 'RISK LEVEL-2',
    },
    {
        field: 'riskAUDescription',
        label: 'RISK DESCRIPTION',
    },
];

const subColumnHeaders = [
    {
        field: 'controlID',
        label: 'CONTROL ID',
    },
    {
        field: 'controlType',
        label: 'CONTROL TYPE',
    },
    {
        field: 'controlAUDescription',
        label: 'CONTROL DESCRIPTION',
    },
    {
        field: 'confidenceScore',
        label: 'CONFIDENCE(%)',
    },
    {
        field: 'status',
        label: 'APPROVE/REJECT',
    },
];

type mainTableType = {
    riskAUDescriptionUniqueId: mainTableFields;
    riskLevel1: mainTableFields;
    riskLevel2: mainTableFields;
    riskAUDescription: mainTableFields;
};

type mainTableFields = {
    field: string;
    label: string;
    tooltip: boolean;
    tooltipText: string;
};

const CustomControlsMapping: React.FC = () => {
    const [checkedState, setCheckedState, handleOnChange, checkedStateAll, setCheckedStateAll, handleOnChangeAll] =
        useSelectCheckboxCustom();
    const dispatch = useAppDispatch();
    const hash: number = useSelector(retrieveHashValue);
    const [mainTableData, setMainTableData] = useState<mainTableType[]>([]);
    const [subTableData, setSubTableData] = useState<ICustomControlsMappingStructure[][]>([]);
    const [showLoader, setShowLoader] = useState(false);
    const userRole: UserRole = useSelector(getUserRole);
    const notificationsData: ITimeNotification[] = useSelector(selectNotificationsData);
    const obligations: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const isSubmitted: boolean = useSelector(selectIsSubmittedRiskToControlMapping);

    const mainColumnDef = mainColumnHeaders.map((field) => {
        return {
            cellClass: 'text-right',
            field: field.field,
            headerClass: 'text-right',
            isSortable: true,
            label: field.label,
            tooltip: field.field === 'riskAUDescription',
        };
    });

    const subColumnDef = subColumnHeaders.map((field) => {
        return {
            cellClass: 'text-right',
            field: field.field,
            headerClass: 'text-right',
            isSortable: true,
            label: field.label,
            tooltip: field.field === 'controlAUDescription',
        };
    });

    const generateSubTableData = () => {
        const tempSubTabledata: ICustomControlsMappingStructure[][] = [];
        obligations.riskToControlsMapping.forEach((item: IRiskToControlsMappingStructure) => {
            const tempData: ICustomControlsMappingStructure[] = [];
            item.controls?.forEach((control: ICustomControlsMappingStructure) => {
                const controlData = {
                    confidenceScore: control.confidenceScore,
                    controlAUDescription: control.controlAUDescription,
                    controlID: control.controlID,
                    controlType: control.controlType,
                    status: control.status,
                    uniqueKeyID: control.uniqueKeyID,
                };
                tempData.push(controlData);
            });
            tempSubTabledata.push(tempData);
        });

        const tempMainTabledata = obligations.riskToControlsMapping.map((item) => {
            return {
                riskAUDescriptionUniqueId: {
                    field: 'Risk Id',
                    label: item.riskAUDescriptionUniqueId,
                    tooltip: false,
                    tooltipText: '',
                },
                riskLevel1: {
                    field: 'Risk Level-1',
                    label: item.riskLevel1,
                    tooltip: false,
                    tooltipText: item.riskLevel1,
                },
                riskLevel2: {
                    field: 'Risk Level-2',
                    label: item.riskLevel2,
                    tooltip: false,
                    tooltipText: item.riskLevel2,
                },
                riskAUDescription: {
                    field: 'Risk Description',
                    label: item.riskAUDescription,
                    tooltip: true,
                    tooltipText: item.riskAUDescription,
                },
            };
        });
        setMainTableData(tempMainTabledata);
        setSubTableData(tempSubTabledata);
    };

    const validateTableRows = (obligations: INewHomeObligationSliceReducer, buttonType: string) => {
        let rowsStatuses: string[] = [];
        obligations.riskToControlsMapping.forEach((risk: IRiskToControlsMappingStructure) => {
            risk.controls?.forEach((control: ICustomControlsMappingStructure) => {
                rowsStatuses.push(control.status);
            });
        });
        const valid = buttonValidation(buttonType, rowsStatuses);
        return !valid;
    };

    const handleSubmit = () => {
        handleSave();

        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 3000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );

        dispatch(setStatus({ status: 'isSubmittedRiskToControlMapping', userRole, value: true }));
    };

    const handleSave = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: `${i18n.t('generalAnswersForSavingDataCalls.success')}`,
                    time: 3000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );
    };

    const generateCheckedItems = (items: IRiskToControlsMappingStructure[]) => {
        const checkedItems: boolean[][] = [];
        items.forEach((item: IRiskToControlsMappingStructure) => {
            const chekedItem: boolean[] = new Array(item?.controls?.length).fill(false);
            checkedItems.push(chekedItem);
        });
        setCheckedState(checkedItems);
    };

    useEffect(() => {
        generateSubTableData();
    }, [obligations.riskToControlsMapping]);

    useEffect(() => {
        if (obligations.riskToControlsMapping.length === 0) {
            setShowLoader(true);
            dispatch(getRiskToControlMapping(hash)).then((data: any) => {
                if (data && data.payload) {
                    generateCheckedItems(data?.payload?.data?.result);
                    setShowLoader(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (checkedState.length === 0 && obligations.riskToControlsMapping.length > 0) {
            generateCheckedItems(obligations.riskToControlsMapping);
        }
    }, [checkedState, obligations.riskToControlsMapping]);

    return (
        <div className='controls-mapping'>
            {showLoader && <LoaderComponent show={true} fullscreen={true} />}
            <h5 className='nexus-h5'>Controls mapping</h5>
            <CustomTable
                attrId='controls-mapping'
                pagination={false}
                mainTabledata={[...mainTableData]}
                mainColumnsDef={[...mainColumnDef]}
                subColumnDef={[...subColumnDef]}
                subTableData={[...subTableData]}
                statusUpdateCallback={updateStatusInRiskToControlMapping}
                isSubmitted={isSubmitted}
                checkedState={checkedState}
                handleChange={handleOnChange}
                setCheckedState={setCheckedState}
                checkedStateAll={checkedStateAll}
                setCheckedStateAll={setCheckedStateAll}
                handleOnChangeAll={handleOnChangeAll}
            />
            <ControlButtons
                submit={handleSubmit}
                save={handleSave}
                validate={isSubmitted || validateTableRows(obligations, ButtonType.SUBMIT)}
                validateSave={isSubmitted || validateTableRows(obligations, ButtonType.SAVE)}
            />
        </div>
    );
};

export default CustomControlsMapping;
