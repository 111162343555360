import { ITableTopBar } from 'app/components/reusable/tableTopBar/TableTopBar';
import { useAppDispatch } from 'store';

export function useHandleBulk({ checkedState, statusBy, tableData, callbackFn }: ITableTopBar) {
    const dispatch = useAppDispatch();
    const handleBulk = (value: string) => {
        tableData.forEach((e) => {
            if (checkedState[e.indexSelect])
                dispatch(
                    callbackFn({
                        statusBy,
                        uniqueKeyID: e.uniqueKeyID,
                        value: value,
                    }),
                );
        });
    };

    return { handleBulk };
}
