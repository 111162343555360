import { IStep } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';

export const newCaseModalSteps: IStep[] = [
    { name: 'uploadFile', label: i18n.t('newCaseModal.firstStepperLabel') },
    { name: 'assignWork', label: i18n.t('newCaseModal.secondStepperLabel') },
];

export const newCaseModalCaseOptions = [
    {
        disabled: false,
        label: 'Regulatory',
        value: 'Regulatory',
    },
    {
        disabled: false,
        label: 'Process',
        value: 'Process',
    },
    {
        disabled: false,
        label: 'Procedure',
        value: 'Procedure',
    },
];

export const newCaseModalDocumentsOptions = [
    {
        disabled: false,
        label: 'CFR',
        value: 'CFR',
    },
    {
        disabled: false,
        label: 'ASIC',
        value: 'ASIC',
    },
    {
        disabled: false,
        label: 'Generic',
        value: 'Generic',
    },
];
