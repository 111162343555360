import { ITimeNotification } from 'constants/commonExportedInterfaces';
import TimeNotification from '../timeNotification/TimeNotification';
import './notificationsContainer.scss';

interface INotificationsContainer {
    notifications: ITimeNotification[];
}

//* You have to make parent of this component position: relative according for it to work properly
const NotificationsContainer: React.FC<INotificationsContainer> = ({ notifications }) => {
    return (
        <>
            {notifications.length > 0 && (
                <div className='notifications-container' data-testid='notifications-container'>
                    {notifications.map((notification: ITimeNotification, index: number) => (
                        <TimeNotification
                            key={index}
                            message={notification.message}
                            variant={notification.variant}
                            time={notification.time}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default NotificationsContainer;
