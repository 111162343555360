import { DecisionDescriptor, StatusBy } from 'constants/commonExportedInterfaces';
import { IAssessControls, IAssessControlsRow, UserRole, Validate5ws } from 'constants/commonExportedInterfaces';
import { NexusCheckbox, NexusIcon } from '@nexus/react';

import ButtonsComponentSelectStatus from '../reusable/buttonsComponentSelectStatus/ButtonsComponentSelectStatus';
import DescriptionComponentModal from '../reusable/descriptionComponentModal/DescriptionComponentModal';
import { INewHomeObligationSliceReducer } from 'app/views/home/redux/newHomeObligationInterfaces';
import TooltipComponent from '../nds/tooltip/tooltip';
import { assessControlHeaderColumnDefinition } from './tableHeaders';
import { buttonValidation } from 'services/buttonValidation';
import check24px from '@nexus/core/dist/assets/icons/navigation/ic_check_24px.svg';
import close24px from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import { updateStatusInObligationsToAssessControl } from 'app/views/home/redux/newHomeObligationAction';

export const decideOnApplicableColumns = (decide: DecisionDescriptor) => {
    let headers = assessControlHeaderColumnDefinition;

    if (decide === StatusBy.ANALYST)
        headers = assessControlHeaderColumnDefinition.filter(
            (set) => set.field !== StatusBy.REVIEWER && set.field !== 'saved_analyst_status',
        );

    if (decide === StatusBy.REVIEWER)
        headers = assessControlHeaderColumnDefinition.filter((set) => set.field !== StatusBy.ANALYST);

    return headers;
};

export const translateAssessControlToSaveAdjustedDataSet = (response: IAssessControls[]) => {
    const assessControlToSave = [] as Validate5ws[];

    for (let i = 0; i < response.length; i++) {
        assessControlToSave.push({
            Control_Description: response[i].Control_Description,
            Control_Name: response[i].Control_Name,
            What: response[i].What,
            What_Confidence_Score: response[i].What_Confidence_Score,
            When: response[i].When,
            When_Confidence_Score: response[i].When_Confidence_Score,
            Where: response[i].Where,
            Where_Confidence_Score: response[i].Where_Confidence_Score,
            Who: response[i].Who,
            Who_Confidence_Score: response[i].Who_Confidence_Score,
            Why: response[i].Why,
            Why_Confidence_Score: response[i].Why_Confidence_Score,
            ctrl_id: response[i].ctrl_id,
            status: response[i].obligation_status_by_reviewer,
            status_by_analyst: response[i].obligation_status_by_analyst,
            status_by_reviewer: response[i].obligation_status_by_reviewer,
            status_to_be_reviewed: response[i].obligation_status_by_analyst,
        });
    }

    return assessControlToSave;
};

const decideOnIcon = (value: string) => {
    return (
        <TooltipComponent allow-click='false' placement='left' message={value} allowClick={false}>
            <NexusIcon
                data-testid={value === 'None' ? 'assess-control-icon-cross' : 'assess-control-icon-check'}
                className={value === 'None' ? 'icon-cross' : 'icon-check'}
                src={value === 'None' ? close24px : check24px}
            ></NexusIcon>
        </TooltipComponent>
    );
};

type tableRows = { userRole: UserRole; obligations: INewHomeObligationSliceReducer };
export const validateTableRows = ({ userRole, obligations }: tableRows, buttonType: string) => {
    const option = userRole === UserRole.REVIEWER ? 'obligation_status_by_reviewer' : 'obligation_status_by_analyst';
    const rowsStatuses: string[] = obligations.assessControl.map((obligation) => obligation[option]);
    const valid = buttonValidation(buttonType, rowsStatuses);
    return !valid;
};

type bodyTable = {
    data: IAssessControls[];
    openDescriptionModal: (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => void;
    handleOnChange: (position: number, checkedState: boolean[], setCheckedState: (value: boolean[]) => void) => void;
    checkedState: boolean[];
    setCheckedState: React.Dispatch<React.SetStateAction<boolean[]>>;
    isSubmitted: boolean;
};

export const createBodyTable = ({
    checkedState,
    data,
    handleOnChange,
    openDescriptionModal,
    setCheckedState,
    isSubmitted,
}: bodyTable) => {
    const tableBodyData: IAssessControlsRow[] = [];

    data?.forEach(
        (
            {
                Control_Description,
                Control_Name,
                What,
                What_Confidence_Score,
                When,
                When_Confidence_Score,
                Where,
                Where_Confidence_Score,
                Who,
                Who_Confidence_Score,
                Why,
                Why_Confidence_Score,
                ctrl_id,
                obligation_status_by_analyst,
                obligation_status_by_reviewer,
                uniqueKeyID,
            },
            index: number,
        ) =>
            tableBodyData.push({
                Control_Description: (
                    <DescriptionComponentModal
                        description={Control_Description}
                        confidenceScore={123}
                        title={'tableToAuMapping.descriptionModalHeader'}
                        subTitle={Control_Name}
                        tooltipText={'tableToAuMapping.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={false}
                    />
                ),
                Control_Name,
                What: decideOnIcon(What),
                What_Confidence_Score,
                When: decideOnIcon(When),
                When_Confidence_Score,
                Where: decideOnIcon(Where),
                Where_Confidence_Score,
                Who: decideOnIcon(Who),
                Who_Confidence_Score,
                Why: decideOnIcon(Why),
                Why_Confidence_Score,
                ctrl_id: 1,
                indexSelect: index,
                obligation_status_by_analyst: (
                    <ButtonsComponentSelectStatus
                        uniqueKeyID={ctrl_id.toString()}
                        statusAnalyst={obligation_status_by_analyst}
                        statusReviewer={obligation_status_by_reviewer}
                        callbackFn={updateStatusInObligationsToAssessControl}
                        name_status_analyst={'obligation_status_by_analyst'}
                        name_status_reviewer={'obligation_status_by_reviewer'}
                        isSubmitted={isSubmitted}
                    />
                ),
                obligation_status_by_reviewer: (
                    <ButtonsComponentSelectStatus
                        uniqueKeyID={ctrl_id.toString()}
                        statusAnalyst={obligation_status_by_analyst}
                        statusReviewer={obligation_status_by_reviewer}
                        callbackFn={updateStatusInObligationsToAssessControl}
                        name_status_analyst={'obligation_status_by_analyst'}
                        name_status_reviewer={'obligation_status_by_reviewer'}
                        isSubmitted={isSubmitted}
                    />
                ),
                saved_analyst_status: obligation_status_by_analyst,
                select: (
                    <NexusCheckbox
                        data-testid='assess-control-table-checkbox'
                        className='nexus-rhythm-2'
                        onInput={() => handleOnChange(index, checkedState, setCheckedState)}
                        checked={checkedState[index]}
                        disabled={isSubmitted}
                    />
                ),
                uniqueKeyID,
            }),
    );

    return tableBodyData;
};
