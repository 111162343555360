import { IAuObligationStatus, IEditSummaryText, IStatus, IRejectionReason } from './newHomeObligationInterfaces';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from 'utils/fetch';

import { REACT_APP_BASE_URL } from 'config/settings';

/** newHomeObligationAction.ts */
export const postUploadAFile = createAsyncThunk(
    'ObligationController/UploadAFile',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await post({
                // baseURL: `${REACT_APP_BASE_URL}/uploadFile`,
                baseURL: `https://nexus4risk-backend-rcm-dev.sbp.eyclienthub.com/ui-service/v1/uploadFile`,
                // data: payload.data,
                data: 'required_obligationFile_string',
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getAllObligations = createAsyncThunk('ObligationController/GetAllObligations', async () => {
    const response = await get({ baseURL: `${REACT_APP_BASE_URL}/obligations` });
    return response;
});

export const updateStatusInObligationsToAuTable = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInObligationsToAuTable',
);

export const updateStatusInObligationsToDocumentExtractionTable = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInObligationsToDocumentExtractionTable',
);

export const rejectionReasonForObligationAtScreen2 = createAction<IRejectionReason>(
    'ObligationController/rejectionReasonForObligationAtScreen2',
);

export const updateStatusInObligationsToAssessControl = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInObligationsToAssessControl',
);

export const updateStatusInObligationsToRulesStatementTable = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInObligationsToRulesStatementTable',
);

export const updateStatusInProcessToRiskMapping = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInProcessToRiskMapping',
);

export const updateStatusInRiskToControlMapping = createAction<IAuObligationStatus>(
    'ObligationController/updateStatusInRiskToControlMapping',
);

export const setStatus = createAction<IStatus>('Global/updateStatusIn');
export const updateEditSummaryText = createAction<IEditSummaryText>('ObligationController/updateEditSummaryText');
