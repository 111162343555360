/** newHomeObligationTestData.ts */

export const newApiResponseBodyPostUploadAFile = {
    content_type: 'string',
    file_name: 'string',
    size: 'string',
    status: 'string',
};

export const newApiDataInterface = [
    {
        hasAssessmentUnits: [
            {
                bu_code: 'string',
                bu_description: 'string',
                bu_id: 'string',
                bu_name: 'string',
                full_path: 'string',
                processes: [
                    {
                        controls: [
                            {
                                control_code: 'string',
                                control_description: 'string',
                                control_id: 'string',
                                control_level: 'string',
                                control_maturity: 'string',
                                control_name: 'string',
                                control_status: 'string',
                                control_type: 'string',
                            },
                        ],
                        process_code: 'string',
                        process_description: 'string',
                        process_id: 'string',
                        process_name: 'string',
                        risks: [
                            {
                                risk_code: 'string',
                                risk_description: 'string',
                                risk_id: 'string',
                                risk_level: {},
                                risk_name: 'string',
                                risk_rating: {},
                                risk_status: {},
                            },
                        ],
                    },
                ],
                sector: 'string',
                sub_bu_code: 'string',
                sub_bu_description: 'string',
                sub_bu_name: 'string',
            },
        ],
        obligation_confidence_score: 0,
        obligation_description: 'string',
        obligation_id: 'string',
        obligation_index: 'string',
        obligation_status_by_analyst: 'string',
        obligation_status_by_reviewer: 'string',
        obligation_title: 'string',
        obligation_type: 'string',
    },
];
