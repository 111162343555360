import './colorInfoBlock.scss';

import { NexusIcon } from '@nexus/react';

interface IColorInfoBlock {
    iconSrc: string;
    info: string;
    status: number | string;
    color: 'blue' | 'orange' | 'white' | 'white-border';
    classes?: string[];
}

const ColorInfoBlock: React.FC<IColorInfoBlock> = ({ iconSrc, info, status, color, classes }) => {
    return (
        <div
            className={`color-info-block-main-container nexus-padding-2 nexus-pt-2 nexus-pb-2 ${color} ${
                classes ? classes.join(' ') : ''
            }`}
            data-testid='color-info-block'
        >
            <div className='color-info-block-inner-container nexus-row nexus-middle-2xs'>
                <div className='nexus-mr-2'>
                    <NexusIcon src={iconSrc} size='lg' />
                </div>
                <div>
                    <div>{info}</div>
                    <div className='nexus-h4'>{status}</div>
                </div>
            </div>
        </div>
    );
};

export default ColorInfoBlock;
