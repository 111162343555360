import { IAuMappingData, IObligations, IAssessControls, IRulesStatementData } from 'constants/commonExportedInterfaces';
import {
    IObligationTable,
    IObligationTableScreen1,
    assessControlObligationPythonAPIresponseStructure,
    IRulesStatementStructure,
    IProcessToRiskMappingStructure,
    IRiskStructure,
    IRiskToControlsMappingStructure,
    ICustomControlsMappingStructure,
    ISummaryScreen5MappingStructure,
    IDistinctFilters,
} from './newHomeObligationInterfaces';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

/** newHelper Pure functions */
export const createObligationsToAuTable = (response: IAuMappingData[]) => {
    const tableAuObligation = [] as IObligationTable[];

    for (let i = 0; i < response.length; i++) {
        const element = response[i];

        for (let j = 0; j < element.applicable_aus.length; j++) {
            tableAuObligation.push({
                bu_name: element.applicable_aus[j].au_name,
                obligation_confidence_score: element.applicable_aus[j].confidence,
                obligation_description: element.applicable_aus[j].activity_description,
                obligation_id: element.ob_id,
                obligation_status_by_analyst: '',
                obligation_status_by_reviewer: '',
                obligation_title: element.obligation_name,
                rejectionReason: '',
                uniqueKeyID: `${element.ob_id} ${element.applicable_aus[j].au_id}`,
            });
        }
    }

    return tableAuObligation;
};

export const createObligationsToExtractionTable = (response: IObligations[]) => {
    const tableDocumentExtractionObligation = [] as IObligationTableScreen1[];

    for (let i = 0; i < response.length; i++) {
        tableDocumentExtractionObligation.push({
            confidence: response[i].confidence,
            justification: response[i].justification,
            ob_id: response[i].ob_id,
            obligation_highlighted_text: response[i].obligation_highlighted_text,
            obligation_name: response[i].obligation_name,
            obligation_summary_text: response[i].obligation_summary_text,
            rule_id: response[i].rule_id,
            status: response[i].status,
            status_to_be_reviewed: response[i].status_to_be_reviewed,
            uniqueKeyID: `${response[i].ob_id}`,
        });
    }

    return tableDocumentExtractionObligation;
};

export const createObligationsToRulesStatementTable = (response: IRulesStatementData[]) => {
    const tableDocumentExtractionObligation = [] as IRulesStatementStructure[];

    for (let i = 0; i < response.length; i++) {
        tableDocumentExtractionObligation.push({
            basel_process_definition: response[i].basel_process_definition,
            basel_process_name: response[i].basel_process_name,
            basel_sub_process: response[i].basel_sub_process,
            confidence_score: response[i].confidence_score,
            process_definition_tool_tip: response[i].process_definition_tool_tip,
            rule_id: response[i].rule_id,
            rules_highlighted_text: response[i].rules_highlighted_text,
            status: '',
            sub_process_description_tool_tip: response[i].sub_process_description_tool_tip,
            uniqueKeyID: i.toString(),
        });
    }

    return tableDocumentExtractionObligation;
};

export const createProcessToRiskMapping = (response: IProcessToRiskMappingStructure[]) => {
    const tableProcessToRiskMapping = [] as IProcessToRiskMappingStructure[];

    for (let i = 0; i < response.length; i++) {
        const risks = [] as IRiskStructure[];
        response[i]?.risks?.forEach((risk: IRiskStructure) => {
            risks.push({
                confidenceScore: risk.confidenceScore,
                riskAUDescription: risk.riskAUDescription,
                riskAUDescriptionUniqueId: risk.riskAUDescriptionUniqueId,
                riskLevel1: risk.riskLevel1,
                riskLevel2: risk.riskLevel2,
                status: '',
                uniqueKeyID: risk.riskAUDescriptionUniqueId,
            });
        });
        tableProcessToRiskMapping.push({
            baselSubProcessDescription: response[i].baselSubProcessDescription,
            processName: response[i].processName,
            risks: risks,
            subProcessName: response[i].subProcessName,
        });
    }
    return tableProcessToRiskMapping;
};

export const createRiskToControlMapping = (response: IRiskToControlsMappingStructure[]) => {
    const tableRiskToControlMapping = [] as IRiskToControlsMappingStructure[];

    for (let i = 0; i < response.length; i++) {
        const controls = [] as ICustomControlsMappingStructure[];
        response[i]?.controls?.forEach((control: ICustomControlsMappingStructure) => {
            controls.push({
                confidenceScore: control.confidenceScore,
                controlAUDescription: control.controlAUDescription,
                controlID: control.controlID,
                controlType: control.controlType,
                status: '',
                uniqueKeyID: control.controlID,
            });
        });
        tableRiskToControlMapping.push({
            controls: controls,
            riskAUDescription: response[i].riskAUDescription,
            riskAUDescriptionUniqueId: response[i].riskAUDescriptionUniqueId,
            riskId: '',
            riskLevel1: response[i].riskLevel1,
            riskLevel2: response[i].riskLevel2,
        });
    }
    return tableRiskToControlMapping;
};

export const createSummaryList = (response: ISummaryScreen5MappingStructure[]) => {
    const tableSummaryList = [] as ISummaryScreen5MappingStructure[];

    for (let i = 0; i < response.length; i++) {
        tableSummaryList.push({
            citationSection: response[i].citationSection,
            controlDescription: response[i].controlDescription,
            controlId: response[i].controlId,
            controlType: response[i].controlType,
            processDefinition: response[i].processDefinition,
            processName: response[i].processName,
            riskDescription: response[i].riskDescription,
            riskId: response[i].riskId,
            riskLevel1: response[i].riskLevel1,
            riskLevel2: response[i].riskLevel2,
            ruleId: response[i].ruleId,
            rulesHighlightedText: response[i].rulesHighlightedText,
            rulesSummaryText: response[i].rulesSummaryText,
            subProcessDescription: response[i].subProcessDescription,
            subProcessName: response[i].subProcessName,
        });
    }
    return tableSummaryList;
};

export const createFilterResults = (response: IDistinctFilters) => {
    return response;
};

export const createObligationsToAssessControl = (response: assessControlObligationPythonAPIresponseStructure[]) => {
    const assessControlObligation = [] as IAssessControls[];

    for (let i = 0; i < response.length; i++) {
        assessControlObligation.push({
            Control_Description: response[i].Control_Description,
            Control_Name: response[i].Control_Name,
            What: response[i].What,
            What_Confidence_Score: response[i].What_Confidence_Score,
            When: response[i].When,
            When_Confidence_Score: response[i].When_Confidence_Score,
            Where: response[i].Where,
            Where_Confidence_Score: response[i].Where_Confidence_Score,
            Who: response[i].Who,
            Who_Confidence_Score: response[i].Who_Confidence_Score,
            Why: response[i].Why,
            Why_Confidence_Score: response[i].Why_Confidence_Score,
            ctrl_id: response[i].ctrl_id,
            obligation_status_by_analyst: '',
            obligation_status_by_reviewer: '',
            saved_analyst_status: '',
            uniqueKeyID: `${response[i].ctrl_id}`,
        });
    }

    return assessControlObligation;
};

export const getBaseUrl = () => {
    if (REACT_APP_CUSTOM_FOR_CLIENT === 'rwa') {
        return 'rcm-demo/v1/rcm';
    } else {
        return 'api';
    }
};
