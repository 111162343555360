import './tableToAuMapping.scss';

import {
    ButtonType,
    IAllDocumentsData,
    ITimeNotification,
    StatusBy,
    UserRole,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import { INewHomeObligationSliceReducer, IObligationTable } from 'app/views/home/redux/newHomeObligationInterfaces';
import { NexusCheckbox, NexusTable } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import { addNotificationData, getSavedObligation } from 'app/views/home/redux/homeActions';
import {
    getUserRole,
    retrieveAllPagesFile,
    retrieveHashValue,
    selectNotificationsData,
} from 'app/views/home/redux/homeSelectors';
import {
    selectIsSubmittedObligationsToAuTableAnalyst,
    selectIsSubmittedObligationsToAuTableReviewer,
    selectNewHomeObligation,
} from 'app/views/home/redux/newHomeSelectors';
import {
    setStatus,
    updateStatusInObligationsToAuTable,
    rejectionReasonForObligationAtScreen2,
} from 'app/views/home/redux/newHomeObligationAction';

import ChipComponentPercent from '../reusable/chipComponentPercent/ChipComponentPercent';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import DescriptionComponentModal from '../reusable/descriptionComponentModal/DescriptionComponentModal';
import { IObligationRow } from './ITableToAuMapping';
import ModalComponent from '../nds/modal/modal';
import NoRecordsTemplate from '../reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import TableBodyComponent from '../reusable/tableBodyComponent/TableBodyComponent';
import TableHeaderComponent from './TableHeaderComponent';
import TableTopBar from '../reusable/tableTopBar/TableTopBar';
import TitleBar from '../reusable/titleBar/TitleBar';
import { auMappingHeaderColumnDefinition } from './tableHeaders';
import { buttonValidation } from 'services/buttonValidation';
import decideOnApplicableColumns from 'services/decideOnApplicableColumns';
import i18n from 'locales/i18n';
import { limit, rejectionReasonCharactersLimit } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import { useSelectCheckbox } from 'hooks/useSelectCheckbox';
import { useSelectedPage } from 'hooks/useSelectedPage';
import { useSelector } from 'react-redux';
import ButtonsComponentSelectStatusForRejection from '../reusable/buttonsComponentSelectStatusForRejection/ButtonsComponentSelectStatusForRejection';
import RejectionRationaleModalBody from 'app/views/dashboard/rejectionRationaleModal/rejectionRationaleModalBody';
import { useRejectionModal } from 'hooks/useRejectionModal';

/** Helper Pure functions */
type bodyTable = {
    data: IObligationTable[];
    openDescriptionModal: (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => void;
    handleOnChange: (position: number, checkedState: boolean[], setCheckedState: (value: boolean[]) => void) => void;
    checkedState: boolean[];
    setCheckedState: React.Dispatch<React.SetStateAction<boolean[]>>;
    openRejectionRationaleModal: (obligation_description: string, modalID: number, uniqueKeyID: string) => void;
    isSubmitted: boolean;
};

const createBodyTable = ({
    checkedState,
    data,
    handleOnChange,
    openRejectionRationaleModal,
    openDescriptionModal,
    setCheckedState,
    isSubmitted,
}: bodyTable) => {
    const tableBodyData: IObligationRow[] = [];

    data?.forEach(
        (
            {
                bu_name,
                obligation_confidence_score,
                obligation_description,
                obligation_id,
                uniqueKeyID,
                obligation_status_by_analyst,
                obligation_status_by_reviewer,
                obligation_title,
            }: IObligationTable,
            index: number,
        ) =>
            tableBodyData.push({
                bu_name: bu_name,
                indexSelect: index,
                obligation_confidence_score: <ChipComponentPercent value={obligation_confidence_score} />,
                obligation_description: (
                    <DescriptionComponentModal
                        description={obligation_description}
                        confidenceScore={obligation_confidence_score}
                        title={'tableToAuMapping.descriptionModalHeader'}
                        subTitle={obligation_title}
                        tooltipText={'tableToAuMapping.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={false}
                    />
                ),
                obligation_id: obligation_id,
                obligation_status_by_analyst: (
                    <ButtonsComponentSelectStatusForRejection
                        uniqueKeyID={uniqueKeyID}
                        statusAnalyst={obligation_status_by_analyst}
                        statusReviewer={obligation_status_by_reviewer}
                        callbackFn={updateStatusInObligationsToAuTable}
                        name_status_analyst={'obligation_status_by_analyst'}
                        name_status_reviewer={'obligation_status_by_reviewer'}
                        openRejectionRationaleModal={() =>
                            openRejectionRationaleModal(obligation_title, obligation_confidence_score, uniqueKeyID)
                        }
                        isSubmitted={isSubmitted}
                    />
                ),
                obligation_status_by_reviewer: (
                    <ButtonsComponentSelectStatusForRejection
                        uniqueKeyID={uniqueKeyID}
                        statusAnalyst={obligation_status_by_analyst}
                        statusReviewer={obligation_status_by_reviewer}
                        callbackFn={updateStatusInObligationsToAuTable}
                        name_status_analyst={'obligation_status_by_analyst'}
                        name_status_reviewer={'obligation_status_by_reviewer'}
                        openRejectionRationaleModal={() =>
                            openRejectionRationaleModal(obligation_title, obligation_confidence_score, uniqueKeyID)
                        }
                        isSubmitted={isSubmitted}
                    />
                ),
                obligation_title: obligation_title,
                saved_analyst_status: obligation_status_by_analyst,
                select: (
                    <NexusCheckbox
                        data-testid='Check'
                        className='nexus-rhythm-2'
                        onInput={() => handleOnChange(index, checkedState, setCheckedState)}
                        checked={checkedState[index]}
                        disabled={isSubmitted}
                    />
                ),
                uniqueKeyID: uniqueKeyID,
            }),
    );

    return tableBodyData;
};

type tableRows = { userRole: UserRole; obligations: INewHomeObligationSliceReducer };
const validateTableRows = ({ userRole, obligations }: tableRows, buttonType: string) => {
    const option = userRole === UserRole.REVIEWER ? 'obligation_status_by_reviewer' : 'obligation_status_by_analyst';
    const rowsStatuses: string[] = obligations.obligationsToAuTable.map((obligation) => obligation[option]);
    const valid = buttonValidation(buttonType, rowsStatuses);
    return !valid;
};

const TableToAuMapping: React.FC = () => {
    const dispatch = useAppDispatch();
    const obligations: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const isSubmittedAnalyst: boolean = useSelector(selectIsSubmittedObligationsToAuTableAnalyst);
    const isSubmittedReviewer: boolean = useSelector(selectIsSubmittedObligationsToAuTableReviewer);
    const hash: number = useSelector(retrieveHashValue);
    const allPagesFiles: IAllDocumentsData = useSelector(retrieveAllPagesFile);
    const userRole: UserRole = useSelector(getUserRole);
    const notificationsData: ITimeNotification[] = useSelector(selectNotificationsData);

    const { page, selectedPage, pagesVisited } = useSelectedPage();
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const [checkedState, setCheckedState, checkedStateAll, setCheckedStateAll, handleOnChange, handleOnChangeAll] =
        useSelectCheckbox();

    const statusBy = userRole === UserRole.ANALYST ? StatusBy.ANALYST : StatusBy.REVIEWER;

    const [rejectionRationaleReason, setRejectionRationaleReason] = useState('');
    const validateRejectionRationaleModal = () =>
        rejectionRationaleReason.trim().length <= rejectionReasonCharactersLimit;

    const [
        closeRejectionRationaleModal,
        rejectionRationaleModal,
        openRejectionRationaleModal,
        rejectionRationaleModalKey,
    ] = useRejectionModal('rejectionRationaleModal.obligationCaseModalHeader');

    const closeRejectionModal = () => {
        setRejectionRationaleReason('');
        closeRejectionRationaleModal();
        dispatch(
            updateStatusInObligationsToAuTable({
                statusBy,
                uniqueKeyID: rejectionRationaleModalKey,
                value: '',
            }),
        );
    };

    const rejectionRationaleCase = (uniqueKeyID: string) => {
        closeRejectionRationaleModal();
        setRejectionRationaleReason('');
        dispatch(
            rejectionReasonForObligationAtScreen2({
                uniqueKeyID: uniqueKeyID,
                rejectionReason: rejectionRationaleReason,
            }),
        );
    };
    const isSubmitted = userRole === UserRole.ANALYST ? isSubmittedAnalyst : isSubmittedReviewer;

    useEffect(() => {
        if (userRole === UserRole.REVIEWER && allPagesFiles.home.length) {
            dispatch(getSavedObligation(hash));
        }
    }, [userRole]);

    if (!obligations?.obligationsToAuTable?.length || (userRole === UserRole.REVIEWER && !isSubmittedAnalyst))
        return (
            <div data-testid='nexus-table-to-au-mapping'>
                <TitleBar title={i18n.t('tableToAuMapping.titleTableToAuMapping')} />
                <NoRecordsTemplate />
            </div>
        );

    const tableData = createBodyTable({
        checkedState,
        data: obligations.obligationsToAuTable,
        handleOnChange,
        isSubmitted,
        openDescriptionModal,
        openRejectionRationaleModal,
        setCheckedState,
    });

    const handleSave = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: `${i18n.t('generalAnswersForSavingDataCalls.success')}`,
                    time: 5000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );
    };

    const handleSubmit = () => {
        handleSave();

        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 5000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );

        dispatch(setStatus({ status: 'isSubmittedObligationsToAuTable', userRole, value: true }));
    };

    return (
        <div data-testid='nexus-table-to-au-mapping'>
            <TitleBar title={i18n.t('tableToAuMapping.titleTableToAuMapping')} />
            <TableTopBar
                isHidden={checkedState.every((e) => !e) || isSubmitted}
                tableData={tableData}
                checkedState={checkedState}
                statusBy={statusBy}
                callbackFn={updateStatusInObligationsToAuTable}
            />
            <NexusTable type='custom'>
                <TableHeaderComponent
                    statusBy={statusBy}
                    checkedStateAll={checkedStateAll}
                    handleOnChangeAll={handleOnChangeAll}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    setCheckedStateAll={setCheckedStateAll}
                    disabledSelectAll={isSubmitted}
                    headers={auMappingHeaderColumnDefinition}
                />
                {!!tableData?.length && (
                    <TableBodyComponent
                        tableData={tableData}
                        pagesVisited={pagesVisited}
                        checkedState={checkedState}
                        setCheckedState={setCheckedState}
                        decideOn={statusBy}
                        decideOnApplicableColumns={() =>
                            decideOnApplicableColumns(statusBy, auMappingHeaderColumnDefinition)
                        }
                    />
                )}
                <PaginationComponent
                    selectedPage={selectedPage}
                    offset={0}
                    limit={limit}
                    size={tableData.length}
                    page={page}
                />
            </NexusTable>
            <ControlButtons
                submit={handleSubmit}
                save={handleSave}
                validate={isSubmitted || validateTableRows({ userRole, obligations }, ButtonType.SUBMIT)}
                validateSave={isSubmitted || validateTableRows({ userRole, obligations }, ButtonType.SAVE)}
            />

            <ModalComponent
                showModal={descriptionModal.open}
                closeModal={closeDescriptionModal}
                headerContent={descriptionModal.header ?? ''}
                modalBodyContent={descriptionModal.description}
                primaryBtnText={'Close'}
                primaryBtnOnclickCallBack={closeDescriptionModal}
                isSecondButton={false}
                size='lg'
            />

            <ModalComponent
                showModal={rejectionRationaleModal.open}
                closeModal={() => closeRejectionModal()}
                headerContent={rejectionRationaleModal.header ?? ''}
                modalBodyContent={
                    <RejectionRationaleModalBody
                        setRejectionRationaleReason={setRejectionRationaleReason}
                        rejectionRationaleReason={rejectionRationaleReason}
                    />
                }
                primaryBtnText={'Cancel'}
                primaryBtnOnclickCallBack={() => closeRejectionModal()}
                primaryBtnTestId='cancel-case-modal-primary-btn'
                secondaryBtnText={'Reject'}
                secondaryBtnOnclickCallBack={() => rejectionRationaleCase(rejectionRationaleModalKey)}
                isSecondButton={true}
                size='md'
                footerOnRight={true}
                disableSecondaryBtn={validateRejectionRationaleModal()}
            />
        </div>
    );
};

export default TableToAuMapping;
