import { DecisionDescriptor, UserRole } from 'constants/commonExportedInterfaces';

import { dashboardHeaderColumnDefinition } from './dashboardTableHeaders';

export const decideOnApplicableColumns = (decide: DecisionDescriptor) => {
    let headers = dashboardHeaderColumnDefinition;

    if (decide === UserRole.ANALYST) {
        headers = dashboardHeaderColumnDefinition.filter((set) => set.field !== 'delete' && set.field !== 'edit');
    }

    return headers;
};

export const decideOnChipBackgroundColor = (status: string) => {
    switch (status) {
        case 'Action required':
            return 'orange';
        case 'In progress':
            return 'blue';
        case 'Closed':
            return;
    }
};

export const decideOnChipContentColor = (status: string) => {
    switch (status) {
        case 'Action required':
            return 'black';
        case 'In progress':
            return 'black';
        case 'Closed':
            return;
    }
};
