import { NexusCheckbox } from '@nexus/react';
import React from 'react';
import { UserRole } from 'constants/commonExportedInterfaces';
import { decideOnApplicableColumns } from './commonFunctions';
import SelectIcon from '@nexus/core/dist/assets/icons/content/ic_sort_24px.svg';
import IconButton from 'app/components/reusable/iconButton/IconButton';
import { useAppDispatch } from 'store';
import { sortCases } from './redux/dashboardActions';

interface ITableHeaderComponent {
    user: UserRole;
    checkedState: boolean[];
    checkedStateAll: boolean;
    setCheckedState: (value: boolean[]) => void;
    setCheckedStateAll: (value: boolean) => void;
    handleOnChangeAll: (
        checkedState: boolean[],
        checkedStateAll: boolean,
        setCheckedState: (value: boolean[]) => void,
        setCheckedStateAll: (value: boolean) => void,
    ) => void;
}

const DashboardTableHeaderComponent: React.FC<ITableHeaderComponent> = ({
    user,
    checkedStateAll,
    handleOnChangeAll,
    checkedState,
    setCheckedState,
    setCheckedStateAll,
}) => {
    const dispatch = useAppDispatch();

    return (
        <tr slot='thead'>
            {decideOnApplicableColumns(user).map((element) => (
                <th key={element.id} className='custom-thead-style' style={{ width: `${element.minWidth}px` }}>
                    {element.field === 'select' && (
                        <NexusCheckbox
                            checked={checkedStateAll}
                            onInput={() =>
                                handleOnChangeAll(checkedState, checkedStateAll, setCheckedState, setCheckedStateAll)
                            }
                            data-testid='dashboard-table-header-checkbox'
                            className='nexus-rhythm-2'
                        />
                    )}
                    <div className='dashboard-table-header-label-wrapper'>
                        {element.label}
                        {element.field !== 'select' && element.field !== 'delete' && element.field !== 'edit' ? (
                            <IconButton
                                iconSrc={SelectIcon}
                                customOnClick={() => dispatch(sortCases(element.field))}
                                classes='nexus-ml-1'
                            />
                        ) : null}
                    </div>
                </th>
            ))}
        </tr>
    );
};

const MemoDashboardTableHeaderComponent = React.memo(DashboardTableHeaderComponent);

export default MemoDashboardTableHeaderComponent;
