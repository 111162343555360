import { useAppDispatch } from 'store';

export function useHandleBulkCustom({ checkedState, statusBy, tableData, callbackFn }: any) {
    const dispatch = useAppDispatch();
    const handleBulkCustom = (value: string) => {
        tableData.forEach((data: any, mainIdx: number) => {
            data?.forEach((risk: any, subIdx: number) => {
                if (checkedState[mainIdx][subIdx]) {
                    dispatch(
                        callbackFn({
                            statusBy,
                            uniqueKeyID: risk.uniqueKeyID,
                            value: value,
                        }),
                    );
                }
            });
        });
    };

    return { handleBulkCustom };
}
