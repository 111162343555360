import { DefaultSelect, categoryDropdownAUMapping, uploadComponentHeaders } from 'constants/constants';
import { IFile, UploadFunctionProp, UserRole } from 'constants/commonExportedInterfaces';
import React, { useEffect, useState } from 'react';
import {
    clearAuMappingData,
    selectedStep1Category,
    uploadAllPageFile,
    uploadAuMappingFileToBackend,
} from 'app/views/home/redux/homeActions';
import { getUserRole, retrieveAllPagesFile, retrieveHashValue } from 'app/views/home/redux/homeSelectors';

import LoaderComponent from '../nds/loader/loader';
import Select from '../nds/select/select';
import SingleFileUploadComponent from '../nds/singleFileUpload/singleFileUpload';
import TitleBar from '../reusable/titleBar/TitleBar';
import UploadButtons from '../reusable/uploadButtons/UploadButtons';
import i18n from 'locales/i18n';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

const UploadAuMapping: React.FC = () => {
    const [uploadedDoc, setUploadedDoc] = useState<IFile | any>(null);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const allPagesFiles = useSelector(retrieveAllPagesFile);
    const userRole = useSelector<string>(getUserRole);
    const displayHashValue = useSelector(retrieveHashValue);

    const [selectStep2Category, setSelectStep2Category] = useState<string>(DefaultSelect);

    useEffect(() => {
        if (!allPagesFiles.step2.length) setUploadedDoc(null);
        if (allPagesFiles.step2.length) setUploadedDoc(allPagesFiles.step2[0]);
    }, []);

    const clearUploadedFileInRedux = () => {
        dispatch(
            uploadAllPageFile({
                ...allPagesFiles,
                step2: [],
            }),
        );
    };

    const uploadAllFiles = () => {
        const formData = new FormData();
        dispatch(clearAuMappingData());

        dispatch(selectedStep1Category(selectStep2Category));
        formData.append('inputFile2', uploadedDoc);
        formData.append('au_risk_control_filesize', uploadedDoc.size.toString());
        formData.append('flow_', 'ui_flow');
        formData.append('au_risk_control_filename', uploadedDoc.name);
        formData.append('runid', displayHashValue);
        setShowLoader(true);

        const payload: UploadFunctionProp = { docType: '', data: formData };

        dispatch(uploadAuMappingFileToBackend(payload)).then((data: any) => {
            if (data && data.payload) {
                dispatch(
                    uploadAllPageFile({
                        ...allPagesFiles,
                        step2: [uploadedDoc],
                    }),
                );
            }
            setShowLoader(false);
        });
    };

    const handleSubmit = () => {
        uploadedDoc && selectStep2Category !== DefaultSelect && uploadAllFiles();
    };

    const removeAllFiles = () => {
        setUploadedDoc(null);
        dispatch(uploadAllPageFile({ ...allPagesFiles, step2: [] }));

        setSelectStep2Category(DefaultSelect);
        dispatch(clearAuMappingData());
    };

    const handleCategoryValue = () => {
        return selectStep2Category;
    };

    const handleCategory = (value: string) => {
        setSelectStep2Category(value);
    };

    const isAnalyst = userRole === UserRole.ANALYST;
    return isAnalyst && REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa' ? (
        <div data-testid='upload-file-component' className='upload-file'>
            <TitleBar title={uploadComponentHeaders.step2} />
            <div className='upload-file-styles nexus-row nexus-p-2 nexus-table'>
                <SingleFileUploadComponent
                    label={i18n.t<string>('uploadComponent.chooseFile')}
                    fileSizeInfo={i18n.t<string>('uploadComponent.fileSize')}
                    uploadedDoc={uploadedDoc}
                    setUploadedDoc={setUploadedDoc}
                    additionalFnOnRemoveFile={clearUploadedFileInRedux}
                />
                <Select
                    classes={
                        'nexus-ml-2 nexus-col-lg-3 nexus-col-xl-3 nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-my-2'
                    }
                    options={categoryDropdownAUMapping}
                    customOnChange={handleCategory}
                    selectedValue={handleCategoryValue()}
                    showSelectedValueOnInit={true}
                />
                <UploadButtons upload={handleSubmit} clear={removeAllFiles} />
            </div>
            {showLoader && <LoaderComponent show={showLoader} fullscreen={false} />}
        </div>
    ) : null;
};

export default UploadAuMapping;
