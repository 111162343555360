import {
    IObligationRow as IObligationRowAuMapping,
    IObligationRowScreen2,
    ISummaryRowScreen5,
} from 'app/components/tableToAuMapping/ITableToAuMapping';
import { IObligationRow as IObligationRowObligationSummary } from 'app/components/obligationSummary/ITableToObligationSummary';
import { assessControlHeaderColumnDefinition } from 'app/components/assessControlTable/tableHeaders';
import { auMappingHeaderColumnDefinition } from 'app/components/tableToAuMapping/tableHeaders';
import { obligationsHeaderColumnDefinition } from 'app/components/obligationSummary/tableHeader';

/** Here we create/export enums that are used in 2 separate files */
export enum StatusChose {
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}

export enum UserRole {
    ANALYST = 'Analyst',
    REVIEWER = 'Reviewer',
}

export enum ButtonType {
    SUBMIT = 'Submit',
    SAVE = 'Save',
}

export enum VariantChose {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export enum StatusBy {
    ANALYST = 'obligation_status_by_analyst',
    REVIEWER = 'obligation_status_by_reviewer',
}

/** Here we create/export interfaces that are used in 2 separate files */
export interface IObligations {
    confidence: number;
    justification: string;
    ob_id: number;
    obligation_highlighted_text: string;
    obligation_id?: string;
    obligation_name: string;
    obligation_summary_text: string;
    status: StatusChose;
    status_to_be_reviewed: StatusChose;
    rule_id: string;
}

export interface IObligationData {
    citation: string;
    obligations: IObligations[];
    regulation_id: string;
    regulation_name: string;
    source_url: string;
}

export interface IRulesStatementData {
    rule_id: string;
    rules_highlighted_text: string;
    basel_process_name: string;
    process_definition_tool_tip: string;
    basel_process_definition: string;
    basel_sub_process: string;
    sub_process_description_tool_tip: string;
    confidence_score: number;
}
export interface ObligationStatus {
    detectOpenRow?: string;
    name: string;
    status: StatusChose;
    user: UserRole;
}

export interface EditedObligationSumText {
    name: string;
    sum_text: string;
}

export interface IApplicableAus {
    activity_description: string;
    au_id: string;
    au_name: string;
    confidence: number;
    justification: string;
    status: StatusChose | string;
    status_to_be_reviewed: StatusChose | string;
    ctrl_name?: string;
}

export interface IAuMappingData {
    applicable_aus: IApplicableAus[];
    ob_id: number;
    obligation_id: string;
    obligation_name: string;
}

export interface IAssessControlsRow {
    Control_Description: React.ReactNode;
    Control_Name: string;
    What: React.ReactNode;
    What_Confidence_Score: number;
    When: React.ReactNode;
    When_Confidence_Score: number;
    Where: React.ReactNode;
    Where_Confidence_Score: number;
    Who: React.ReactNode;
    Who_Confidence_Score: number;
    Why: React.ReactNode;
    Why_Confidence_Score: number;
    ctrl_id: number;
    select: React.ReactNode;
    obligation_status_by_analyst: React.ReactNode;
    obligation_status_by_reviewer: React.ReactNode;
    uniqueKeyID: string;
    saved_analyst_status: StatusChose | '';
    indexSelect: number;

    // status: StatusChose;
    // status_to_be_reviewed: StatusChose | '';
}

export interface IAssessControls {
    Control_Description: string;
    Control_Name: string;
    What: string;
    What_Confidence_Score: number;
    When: string;
    When_Confidence_Score: number;
    Where: string;
    Where_Confidence_Score: number;
    Who: string;
    Who_Confidence_Score: number;
    Why: string;
    Why_Confidence_Score: number;
    ctrl_id: number;
    saved_analyst_status: StatusChose | '';
    obligation_status_by_analyst: '';
    obligation_status_by_reviewer: '';
    uniqueKeyID: string;

    // status?: string;
    // status_to_be_reviewed: StatusChose;
}

export interface AssessControlStatus {
    ctrl_id: number;
    status: StatusChose;
    user: UserRole;
}

export interface IStep {
    key?: string;
    label?: string;
    name: string;
}

export interface IExpandedRow {
    [key: string]: boolean;
}

export interface IUploadDocuments {
    name: string;
    size: number;
}

export interface ReviewerDataStep {
    approved: boolean;
    text: string[];
}

export interface ReviewerData {
    home: ReviewerDataStep;
    step2: ReviewerDataStep;
    step3: ReviewerDataStep;
    step4: ReviewerDataStep;
}
export interface IAllDocumentsData {
    home: Array<any>;
    step2: Array<any>;
    step4: Array<any>;
}
export type UploadFunctionProp = {
    data: any;
    docType: string;
};

export interface ITimeNotification {
    message: string;
    time: number;
    variant: VariantChose;
}

export interface SavedObligationData {
    Reviewer: string;
    Submit: string;
    User: string;
    User_ID: string;
    citation: string;
    comments: string;
    draftobg: IObligations[];
    filesize: number;
    regulation_id: string;
    regulation_name: string;
    regulation_type: string;
    run_id: string;
    source_url: string;
    status: string;
}

export interface SelectOption {
    disabled?: boolean;
    label: string;
    value: string;
}

export interface IUserProfileAttributes {
    label: string;
    testId: string;
    value: string;
}

export interface Applicable_aus {
    activity_description: string;
    au_id: number;
    au_name: string;
    confidence: 0;
    justification: string;
    status: StatusChose;
    status_to_be_reviewed: StatusChose;
}

export interface AU_Mapping {
    applicable_aus: Applicable_aus[];
    ob_id: number;
    obligation_id?: string;
    obligation_name: string;
}

export interface SavedControlMapping {
    Reviewer: string;
    Submit: string;
    User: string;
    User_ID: string;
    au_risk_control_filename: string;
    au_risk_control_filesize: number;
    aumapping: AU_Mapping[];
    citation: string;
    comments: string;
    regulation_id: string;
    regulation_name: string;
    run_id: string;
    source_url: string;
    status: string;
}

export interface Validate5ws {
    Control_Description: string;
    Control_Name: string;
    What: string;
    What_Confidence_Score: number;
    When: string;
    When_Confidence_Score: number;
    Where: string;
    Where_Confidence_Score: number;
    Who: string;
    Who_Confidence_Score: number;
    Why: string;
    Why_Confidence_Score: number;
    ctrl_id: number;
    obligation_id?: string;
    status_by_analyst: React.ReactNode;
    status_by_reviewer: React.ReactNode;

    status?: string;
    status_to_be_reviewed?: StatusChose | '';
}

export interface SavedAssessControl {
    Reviewer: string;
    Submit: string;
    User: string;
    User_ID: string;
    citation: string;
    comments: string;
    regulation_id: string;
    regulation_name: string;
    run_id: string;
    source_url: string;
    status: string;
    validate5ws: Validate5ws[];
}

export type IFile = {
    name: string;
    size: number;
};

export interface IHeader {
    field: string;
    isSortable?: boolean;
    label: string;
    id?: number;
    minWidth?: number;
}

export interface User {
    user_id: string;
    user_full_name: string;
    user_email: string;
    user_role: string;
}

export interface IDashboardAPIData {
    assignedTo: string;
    caseID: string;
    caseType: string;
    lastEdit: string;
    owner: string;
    caseName: string;
    status: string;
    cancelationReason: string;
}

export interface IDashboardBodyData {
    assignedTo: string;
    caseID: string;
    caseType: string;
    lastEdit: string;
    owner: string;
    caseName: React.ReactNode;
    status: React.ReactNode;
    delete?: React.ReactNode;
    edit: React.ReactNode;
    select: React.ReactNode;
    cancelationReason: string;
}

/** Here we create/export types that are used in 2 separate files */

export type DecisionDescriptor = UserRole | StatusBy;
export type DataDescriptor =
    | IObligationRowAuMapping[]
    | IDashboardBodyData[]
    | IObligationRowObligationSummary[]
    | IAssessControlsRow[]
    | IObligationRowScreen2[]
    | ISummaryRowScreen5[];

export type ColumnsDefinitionsDescriptor =
    | typeof assessControlHeaderColumnDefinition
    | typeof obligationsHeaderColumnDefinition
    | typeof auMappingHeaderColumnDefinition;

export interface IObigationWIthRulesID {
    putBody: {
        updated_summary_text: string;
    };
    rule_id: string;
}

export interface ISummaryListRequest {
    ruleId: string;
    citationSection: string;
    processName: string;
    subProcessName: string;
    riskLevel1: string;
    riskLevel2: string;
    riskId: string;
    controlId: string;
    page: number;
    size: number;
}

export interface IFilterRequest {
    ruleId: string;
    citationSection: string;
    processName: string;
    subProcessName: string;
    riskLevel1: string;
    riskLevel2: string;
    riskId: string;
    controlId: string;
}
