import './tableToAuMapping.scss';

import {
    ButtonType,
    IAllDocumentsData,
    ITimeNotification,
    StatusBy,
    UserRole,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import {
    INewHomeObligationSliceReducer,
    IRulesStatementStructure,
} from 'app/views/home/redux/newHomeObligationInterfaces';
import { NexusTable } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import { addNotificationData, getSavedObligation, getRulesToProcessMapping } from 'app/views/home/redux/homeActions';
import {
    getUserRole,
    retrieveAllPagesFile,
    retrieveHashValue,
    selectNotificationsData,
} from 'app/views/home/redux/homeSelectors';
import {
    selectIsSubmittedObligationsToAuTableAnalyst,
    selectIsSubmittedObligationsToAuTableReviewer,
    selectNewHomeObligation,
} from 'app/views/home/redux/newHomeSelectors';
import {
    setStatus,
    updateStatusInObligationsToAuTable,
    rejectionReasonForObligationAtScreen2,
    updateStatusInObligationsToRulesStatementTable,
} from 'app/views/home/redux/newHomeObligationAction';

import ChipComponentPercent from '../reusable/chipComponentPercent/ChipComponentPercent';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import { IObligationRowScreen2 } from './ITableToAuMapping';
import ModalComponent from '../nds/modal/modal';
import NoRecordsTemplate from '../reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import TableBodyComponent from '../reusable/tableBodyComponent/TableBodyComponent';
import TableHeaderComponent from './TableHeaderComponent';
import TableTopBar from '../reusable/tableTopBar/TableTopBar';
import TitleBar from '../reusable/titleBar/TitleBar';
import { auMappingHeaderColumnDefinitionForClient } from './tableHeaders';
import { buttonValidation } from 'services/buttonValidation';
import decideOnApplicableColumns from 'services/decideOnApplicableColumns';
import i18n from 'locales/i18n';
import { limit, rejectionReasonCharactersLimit } from 'constants/constants';
import { useAppDispatch } from 'store';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import { useSelectCheckbox } from 'hooks/useSelectCheckbox';
import { useSelectedPage } from 'hooks/useSelectedPage';
import { useSelector } from 'react-redux';
import ButtonsComponentSelectStatus from '../reusable/buttonsComponentSelectStatus/ButtonsComponentSelectStatus';
import RejectionRationaleModalBody from 'app/views/dashboard/rejectionRationaleModal/rejectionRationaleModalBody';
import { useRejectionModal } from 'hooks/useRejectionModal';
import DescriptionComponentModal from '../reusable/descriptionComponentModal/DescriptionComponentModal';
import { NexusCheckbox } from '@nexus/react';
import LoaderComponent from '../nds/loader/loader';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

/** Helper Pure functions */
type bodyTable = {
    data: IRulesStatementStructure[];
    openDescriptionModal: (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => void;
    handleOnChange: (position: number, checkedState: boolean[], setCheckedState: (value: boolean[]) => void) => void;
    checkedState: boolean[];
    setCheckedState: React.Dispatch<React.SetStateAction<boolean[]>>;
    openRejectionRationaleModal: (regulationName: string, caseID: number, uniqueKey?: string) => void;
    isSubmitted: boolean;
};

const createBodyTable = ({
    checkedState,
    data,
    handleOnChange,
    openDescriptionModal,
    setCheckedState,
    isSubmitted,
}: bodyTable) => {
    const tableBodyData: IObligationRowScreen2[] = [];

    data?.forEach(
        (
            {
                rule_id,
                rules_highlighted_text,
                // basel_process_definition,
                basel_process_name,
                process_definition_tool_tip,
                basel_sub_process,
                sub_process_description_tool_tip,
                confidence_score,
                uniqueKeyID,
                status,
            }: IRulesStatementStructure,
            index: number,
        ) =>
            tableBodyData.push({
                basel_process_name: (
                    <DescriptionComponentModal
                        description={process_definition_tool_tip}
                        popupDisplayText={basel_process_name}
                        confidenceScore={confidence_score}
                        title={basel_process_name}
                        subTitle=''
                        tooltipText={'tableToAuMapping.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={true}
                    />
                ),
                basel_sub_process: (
                    <DescriptionComponentModal
                        description={sub_process_description_tool_tip}
                        popupDisplayText={basel_sub_process}
                        confidenceScore={confidence_score}
                        title={basel_sub_process}
                        subTitle=''
                        tooltipText={'tableToAuMapping.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={true}
                    />
                ),
                indexSelect: index,
                obligation_confidence_score: <ChipComponentPercent value={confidence_score} />,
                obligation_status_by_analyst: (
                    <ButtonsComponentSelectStatus
                        uniqueKeyID={uniqueKeyID}
                        statusAnalyst={status}
                        statusReviewer=''
                        callbackFn={updateStatusInObligationsToRulesStatementTable}
                        name_status_analyst={'status'}
                        name_status_reviewer={'status_to_be_reviewed'}
                        isSubmitted={isSubmitted}
                    />
                ),
                obligation_status_by_reviewer: undefined,
                rules_highlighted_text: (
                    <DescriptionComponentModal
                        description={rules_highlighted_text}
                        confidenceScore={confidence_score}
                        title={'obligationSummary.descriptionModalHeader'}
                        subTitle=''
                        tooltipText={'tableToAuMapping.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={true}
                    />
                ),
                rules_id: rule_id,
                saved_analyst_status: '',
                select: (
                    <NexusCheckbox
                        data-testid='obligation-summary-table-checkbox'
                        className='nexus-rhythm-2'
                        onInput={() => handleOnChange(index, checkedState, setCheckedState)}
                        checked={checkedState[index]}
                        disabled={isSubmitted}
                    />
                ),
                uniqueKeyID,
            }),
    );
    return tableBodyData;
};

//type tableRows = { userRole: UserRole; obligations: INewHomeObligationSliceReducer };
const validateTableRows = (obligations: INewHomeObligationSliceReducer, buttonType: string) => {
    // const option = userRole === UserRole.REVIEWER ? 'obligation_status_by_reviewer' : 'obligation_status_by_analyst';

    const rowsStatuses: string[] = obligations.obligationsToRulesStatementTable.map(
        (obligation: any) => obligation.status,
    );
    const valid = buttonValidation(buttonType, rowsStatuses);
    return !valid;
};

const RulesToProcessMappingTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const obligations: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const isSubmittedAnalyst: boolean = useSelector(selectIsSubmittedObligationsToAuTableAnalyst);
    const isSubmittedReviewer: boolean = useSelector(selectIsSubmittedObligationsToAuTableReviewer);
    const hash: number = useSelector(retrieveHashValue);
    const allPagesFiles: IAllDocumentsData = useSelector(retrieveAllPagesFile);
    const userRole: UserRole = useSelector(getUserRole);
    const notificationsData: ITimeNotification[] = useSelector(selectNotificationsData);

    const { page, selectedPage, pagesVisited } = useSelectedPage();
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const [checkedState, setCheckedState, checkedStateAll, setCheckedStateAll, handleOnChange, handleOnChangeAll] =
        useSelectCheckbox();

    const statusBy = userRole === UserRole.ANALYST ? StatusBy.ANALYST : StatusBy.REVIEWER;

    const [rejectionRationaleReason, setRejectionRationaleReason] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const validateRejectionRationaleModal = () =>
        rejectionRationaleReason.trim().length <= rejectionReasonCharactersLimit;

    const [
        closeRejectionRationaleModal,
        rejectionRationaleModal,
        openRejectionRationaleModal,
        rejectionRationaleModalKey,
    ] = useRejectionModal('rejectionRationaleModal.obligationCaseModalHeader');

    const closeRejectionModal = () => {
        setRejectionRationaleReason('');
        closeRejectionRationaleModal();
        dispatch(
            updateStatusInObligationsToAuTable({
                statusBy,
                uniqueKeyID: rejectionRationaleModalKey,
                value: '',
            }),
        );
    };

    const rejectionRationaleCase = (uniqueKeyID: string) => {
        closeRejectionRationaleModal();
        setRejectionRationaleReason('');
        dispatch(
            rejectionReasonForObligationAtScreen2({
                uniqueKeyID: uniqueKeyID,
                rejectionReason: rejectionRationaleReason,
            }),
        );
    };
    const isSubmitted = userRole === UserRole.ANALYST ? isSubmittedAnalyst : isSubmittedReviewer;

    useEffect(() => {
        if (userRole === UserRole.REVIEWER && allPagesFiles.home.length) {
            dispatch(getSavedObligation(hash));
        }
    }, [userRole]);

    useEffect(() => {
        if (
            obligations?.obligationsToRulesStatementTable.length === 0 &&
            obligations.obligationsToDocumentExtractionTable.length > 0
        ) {
            setShowLoader(true);
            dispatch(getRulesToProcessMapping(hash)).then((data) => {
                if (data && data.payload) {
                    setShowLoader(false);
                }
            });
        }
    }, []);

    if (!obligations.obligationsToRulesStatementTable.length || (userRole === UserRole.REVIEWER && !isSubmittedAnalyst))
        return (
            <div data-testid='nexus-table-to-au-mapping'>
                {showLoader && <LoaderComponent show={true} fullscreen={true} />}
                <TitleBar title={i18n.t('tableToAuMapping.titleTableToAuMapping')} />
                <NoRecordsTemplate />
            </div>
        );

    const tableData = createBodyTable({
        checkedState,
        data: obligations.obligationsToRulesStatementTable,
        handleOnChange,
        isSubmitted,
        openDescriptionModal,
        openRejectionRationaleModal,
        setCheckedState,
    });

    const handleSave = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: `${i18n.t('generalAnswersForSavingDataCalls.success')}`,
                    time: 3000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );
    };

    const handleSubmit = () => {
        handleSave();

        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 3000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );

        dispatch(setStatus({ status: 'isSubmittedObligationsToAuTable', userRole, value: true }));
    };

    return (
        <div data-testid='nexus-table-to-au-mapping'>
            <TitleBar title={i18n.t('tableToAuMapping.titleTableToAuMapping')} />
            <TableTopBar
                isHidden={checkedState.every((e) => !e) || isSubmitted}
                isSearchVisible={REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa'}
                tableData={tableData}
                checkedState={checkedState}
                statusBy={statusBy}
                callbackFn={updateStatusInObligationsToRulesStatementTable}
            />
            <NexusTable type='custom'>
                <TableHeaderComponent
                    statusBy={statusBy}
                    checkedStateAll={checkedStateAll}
                    handleOnChangeAll={handleOnChangeAll}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    setCheckedStateAll={setCheckedStateAll}
                    disabledSelectAll={isSubmitted}
                    headers={auMappingHeaderColumnDefinitionForClient}
                />
                {!!tableData?.length && (
                    <TableBodyComponent
                        tableData={tableData}
                        pagesVisited={pagesVisited}
                        checkedState={checkedState}
                        setCheckedState={setCheckedState}
                        decideOn={statusBy}
                        decideOnApplicableColumns={() =>
                            decideOnApplicableColumns(statusBy, auMappingHeaderColumnDefinitionForClient)
                        }
                    />
                )}
                <PaginationComponent
                    selectedPage={selectedPage}
                    offset={0}
                    limit={limit}
                    size={tableData.length}
                    page={page}
                />
            </NexusTable>
            <ControlButtons
                submit={handleSubmit}
                save={handleSave}
                validate={isSubmitted || validateTableRows(obligations, ButtonType.SUBMIT)}
                validateSave={isSubmitted || validateTableRows(obligations, ButtonType.SAVE)}
            />

            <ModalComponent
                showModal={descriptionModal.open}
                closeModal={closeDescriptionModal}
                headerContent={descriptionModal.header ?? ''}
                modalBodyContent={descriptionModal.description}
                primaryBtnText={'Close'}
                primaryBtnOnclickCallBack={closeDescriptionModal}
                isSecondButton={false}
                size='lg'
            />

            <ModalComponent
                showModal={rejectionRationaleModal.open}
                closeModal={() => closeRejectionModal()}
                headerContent={rejectionRationaleModal.header ?? ''}
                modalBodyContent={
                    <RejectionRationaleModalBody
                        setRejectionRationaleReason={setRejectionRationaleReason}
                        rejectionRationaleReason={rejectionRationaleReason}
                    />
                }
                primaryBtnText={'Cancel'}
                primaryBtnOnclickCallBack={() => closeRejectionModal()}
                primaryBtnTestId='cancel-case-modal-primary-btn'
                secondaryBtnText={'Reject'}
                secondaryBtnOnclickCallBack={() => rejectionRationaleCase(rejectionRationaleModalKey)}
                isSecondButton={true}
                size='md'
                footerOnRight={true}
                disableSecondaryBtn={validateRejectionRationaleModal()}
            />
        </div>
    );
};

export default RulesToProcessMappingTable;
