import './homePage.scss';

import { setPaginationExternally, updateUserRole } from './redux/homeActions';
import { stepperRoutes, steps } from 'constants/stepperConstants';
import { useEffect, useState } from 'react';

import AssessControlTable from 'app/components/assessControlTable/AssessControlTable';
import ControlsMapping from 'app/components/controlsMapping/ControlsMapping';
import ObligationSummary from 'app/components/obligationSummary/ObligationSummary';
import OverallSkeletonPane from 'app/views/overallSkeletonPane/OverallSkeletonPane';
import { Stepper } from '../../components/stepper/Stepper';
import RulesToProcessMappingTable from 'app/components/tableToAuMapping/RulesToProcessMappingTable';
import UploadAssessControl from 'app/components/uploadAssessControl/UploadAssessControl';
import UploadAuMapping from 'app/components/uploadAuMapping/UploadAuMapping';
import UploadObligation from 'app/components/uploadObligation/UploadObligation';
import UploadedDocuments from 'app/components/uploadedDocuments/UploadedDocuments';
import UserProfileView from 'app/components/userProfileView/UserProfileView';
import { useAppDispatch } from 'store';
import { useLocation } from 'react-router';
import { REACT_APP_BASE_URL, REACT_APP_CUSTOM_FOR_CLIENT } from '../../../config/settings';
import TableToAuMapping from 'app/components/tableToAuMapping/TableToAuMapping';
import RiskMapping from 'app/components/riskMapping/RiskMapping';
import CustomControlsMapping from 'app/components/controlsMapping/customControlMappings';
import EndToEndMapping from 'app/components/endToEndMapping/EndToEndMapping';

const HomePage = () => {
    const currentStep = useLocation().pathname.split('/')[1];
    const [activeStep, setActiveStep] = useState(currentStep);
    const [operationTop, setOperationTop] = useState<JSX.Element>(<></>);
    const [operationsBottom, setOperationsBottom] = useState<JSX.Element>(<></>);
    const dispatch = useAppDispatch();

    const handleStepClick = (currentStep: string) => {
        setActiveStep(currentStep);
    };

    useEffect(() => {
        switch (activeStep) {
            case stepperRoutes.Home:
                setOperationTop(<UploadObligation />);
                setOperationsBottom(<ObligationSummary />);
                break;
            case stepperRoutes.Step2:
                setOperationTop(<UploadAuMapping />);
                REACT_APP_CUSTOM_FOR_CLIENT === 'rwa'
                    ? setOperationsBottom(<RulesToProcessMappingTable />)
                    : setOperationsBottom(<TableToAuMapping />);
                break;
            case stepperRoutes.Step3:
                setOperationTop(<UploadedDocuments />);
                REACT_APP_CUSTOM_FOR_CLIENT === 'rwa'
                    ? setOperationsBottom(<RiskMapping />)
                    : setOperationsBottom(<ControlsMapping />);
                break;
            case stepperRoutes.Step4:
                setOperationTop(<UploadAssessControl />);
                REACT_APP_CUSTOM_FOR_CLIENT === 'rwa'
                    ? setOperationsBottom(<CustomControlsMapping />)
                    : setOperationsBottom(<AssessControlTable />);
                break;
            case stepperRoutes.Step5:
                setOperationTop(<UploadAssessControl />);
                REACT_APP_CUSTOM_FOR_CLIENT === 'rwa'
                    ? setOperationsBottom(<EndToEndMapping />)
                    : setOperationsBottom(<AssessControlTable />);
                break;
        }
    }, [activeStep]);

    useEffect(() => {
        if (REACT_APP_CUSTOM_FOR_CLIENT) {
            const requestOptions = {
                method: 'PUT',
            };
            fetch(`${REACT_APP_BASE_URL}/rcm-demo/v1/rcm/obligations/cache/reset`, requestOptions)
                .then((response) => response.json())
                .then((data) => console.log('data>>>', data));
        }
    }, []);

    const handleUserProfile = (value: string) => {
        dispatch(updateUserRole(value));
        dispatch(setPaginationExternally(true));
    };
    const user = <UserProfileView handleUserProfile={(event) => handleUserProfile(event)} />;
    const navigation = <Stepper handleStepClick={handleStepClick} steps={steps} currentStep={activeStep} />;

    return (
        <div data-testid='nexus-home'>
            <OverallSkeletonPane
                user={REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa' ? user : null}
                navigation={navigation}
                operationsTop={operationTop}
                operationsBottom={operationsBottom}
            />
        </div>
    );
};

export default HomePage;
