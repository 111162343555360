import ButtonComponent from 'app/components/nds/button/button';
import React from 'react';
import i18n from 'locales/i18n';

interface IControlButtons {
    save: () => void;
    submit: () => void;
    validate: boolean;
    validateSave: boolean;
}

/**
 * UploadButtons component
 * @function submit handle submit function
 * @function save handle save function
 * @function validate handle validate function
 */

const ControlButtons: React.FC<IControlButtons> = ({ submit, save, validate, validateSave }) => {
    return (
        <div className='nexus-row nexus-flex-row-reverse' data-testid='buttons-wrapper'>
            <div className='nexus-col nexus-m-1'>
                <ButtonComponent
                    click={submit}
                    type={['nexus-btn-primary']}
                    label={i18n.t('obligationSummary.submit')}
                    disabled={validate}
                />
            </div>
            <div className='nexus-col nexus-m-1 '>
                <ButtonComponent
                    type={['nexus-btn']}
                    label={i18n.t('obligationSummary.save')}
                    click={save}
                    extraClass='rcm-no-margin'
                    disabled={validateSave}
                />
            </div>
        </div>
    );
};

export default ControlButtons;
