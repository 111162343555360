import { useState } from 'react';

export function useSelectCheckbox() {
    const [checkedState, setCheckedState] = useState<boolean[]>([]);
    const [checkedStateAll, setCheckedStateAll] = useState<boolean>(false);

    const handleOnChange = (position: number, checkedState: boolean[], setCheckedState: (value: boolean[]) => void) => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
        setCheckedState(updatedCheckedState);
    };

    const handleOnChangeAll = (
        checkedState: boolean[],
        checkedStateAll: boolean,
        setCheckedState: (value: boolean[]) => void,
        setCheckedStateAll: (value: boolean) => void,
    ) => {
        const updatedCheckedState = checkedState.map(() => {
            setCheckedStateAll(!checkedStateAll);
            return checkedStateAll ? false : true;
        });
        setCheckedState(updatedCheckedState);
    };

    return [
        checkedState,
        setCheckedState,
        checkedStateAll,
        setCheckedStateAll,
        handleOnChange,
        handleOnChangeAll,
    ] as const;
}
