import { ButtonType } from 'constants/commonExportedInterfaces';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

export const buttonValidation = (buttonType: string, rowsStatuses: string[]) => {
    if (REACT_APP_CUSTOM_FOR_CLIENT === 'rwa') {
        return buttonType === ButtonType.SUBMIT
            ? rowsStatuses.some((status: string) => status !== '')
            : rowsStatuses.some((status: string) => status !== '');
    } else {
        return buttonType === ButtonType.SUBMIT
            ? rowsStatuses.every((status: string) => status !== '')
            : rowsStatuses.some((status: string) => status !== '');
    }
};
