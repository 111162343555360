import './titleBar.scss';

import React from 'react';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

/**
 * TitleBar Component
 * @param title use to display title inside the component
 */
interface ITitleBar extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title: string;
    header1?: string;
    header2?: string;
    header3?: string;
    extraClass?: string;
}

const TitleBar: React.FC<ITitleBar> = React.memo(({ title, ...restProps }: ITitleBar) => {
    const { header1, header2, header3, extraClass } = restProps;
    return (
        <div
            data-testid='title-bar'
            className={
                REACT_APP_CUSTOM_FOR_CLIENT
                    ? `nexus-col nexus-h4 card-header-styles nexus-p-2 card-header-styles-${REACT_APP_CUSTOM_FOR_CLIENT} ${extraClass}`
                    : `nexus-col nexus-h4 card-header-styles nexus-p-2 card-header-styles`
            }
            {...restProps}
        >
            {title}
            {header1 && <span className='header1-style'>{header1}</span>}
            {header2 && <span className='header2-style'>{header2}</span>}
            {header3 && <span className='header3-style'>{header3}</span>}
        </div>
    );
});

export default TitleBar;
