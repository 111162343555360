import { IHeader } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';

export const dashboardHeaderColumnDefinition: IHeader[] = [
    {
        field: 'select',
        id: 1,
        label: '',
        minWidth: 20,
    },
    {
        field: 'caseID',
        id: 2,
        label: i18n.t('dashboard.caseID'),
        minWidth: 50,
    },
    {
        field: 'caseName',
        id: 3,
        isSortable: true,
        label: i18n.t('dashboard.caseName'),
        minWidth: 100,
    },
    {
        field: 'caseType',
        id: 4,
        label: i18n.t('dashboard.caseType'),
        minWidth: 50,
    },
    {
        field: 'status',
        id: 5,
        label: i18n.t('dashboard.status'),
        minWidth: 60,
    },
    {
        field: 'assignedTo',
        id: 6,
        label: i18n.t('dashboard.assignedTo'),
        minWidth: 50,
    },
    {
        field: 'edit',
        id: 7,
        label: '',
        minWidth: 50,
    },
    {
        field: 'owner',
        id: 8,
        label: i18n.t('dashboard.owner'),
        minWidth: 50,
    },
    {
        field: 'lastEdit',
        id: 9,
        label: i18n.t('dashboard.lastEdit'),
        minWidth: 50,
    },
    {
        field: 'delete',
        id: 10,
        isSortable: true,
        label: '',
        minWidth: 50,
    },
];
