import './newCaseModalBody.scss';

import { IFile } from 'constants/commonExportedInterfaces';
import NewCaseModalFirstStep from './NewCaseModalFirstStep';
import NewCaseModalSecondStep from './NewCaseModalSecondStep';
import { Stepper } from 'app/components/stepper/Stepper';
import { newCaseModalSteps } from '../constants/newCaseModalConstants';

interface INewCaseModalBody {
    activeStep: string;
    uploadedDoc: IFile;
    setUploadedDoc: React.Dispatch<React.SetStateAction<IFile>>;
    additionalFnOnRemoveFile: () => void;
    setNewCaseType: React.Dispatch<React.SetStateAction<string>>;
    setNewDocType: React.Dispatch<React.SetStateAction<string>>;
    setNewAnalyst: React.Dispatch<React.SetStateAction<string>>;
    newCaseType: string;
    newDocType: string;
    newCaseAnalyst: string;
}

const NewCaseModalBody: React.FC<INewCaseModalBody> = ({
    activeStep,
    uploadedDoc,
    setUploadedDoc,
    additionalFnOnRemoveFile,
    setNewCaseType,
    setNewDocType,
    setNewAnalyst,
    newCaseType,
    newDocType,
    newCaseAnalyst,
}) => {
    return (
        <div data-testid='new-case-modal-body' className='new-case-modal-body-container'>
            <div className='nexus-mb-4'>
                <Stepper steps={newCaseModalSteps} currentStep={activeStep} />
            </div>
            {activeStep === newCaseModalSteps[0].name ? (
                <NewCaseModalFirstStep
                    uploadedDoc={uploadedDoc}
                    setUploadedDoc={setUploadedDoc}
                    additionalFnOnRemoveFile={additionalFnOnRemoveFile}
                    setNewCaseType={setNewCaseType}
                    setNewDocType={setNewDocType}
                    newCaseType={newCaseType}
                    newDocType={newDocType}
                />
            ) : (
                <NewCaseModalSecondStep
                    uploadedDoc={uploadedDoc}
                    newCaseAnalyst={newCaseAnalyst}
                    setNewAnalyst={setNewAnalyst}
                />
            )}
        </div>
    );
};

export default NewCaseModalBody;
