import { useState } from 'react';

export function useSelectCheckboxCustom() {
    const [checkedState, setCheckedState] = useState<boolean[][]>([]);
    const [checkedStateAll, setCheckedStateAll] = useState<boolean>(false);

    const handleOnChange = (
        mainIndex: number,
        subIndex: number,
        checkedState: boolean[][],
        setCheckedState: (value: boolean[][]) => void,
    ) => {
        const copyCheckedState: boolean[][] = [...checkedState];
        copyCheckedState[mainIndex][subIndex] = !copyCheckedState[mainIndex][subIndex];
        setCheckedState(copyCheckedState);
    };

    const handleOnChangeAll = (
        checkedState: boolean[][],
        checkedStateAll: boolean,
        setCheckedState: (value: boolean[][]) => void,
        setCheckedStateAll: (value: boolean) => void,
    ) => {
        const checkedItems: boolean[][] = [];
        checkedState.forEach((item) => {
            const chekedItem: boolean[] = new Array(item?.length).fill(!checkedStateAll);
            checkedItems.push(chekedItem);
        });
        setCheckedState(checkedItems);
        setCheckedStateAll(!checkedStateAll);
    };

    return [
        checkedState,
        setCheckedState,
        handleOnChange,
        checkedStateAll,
        setCheckedStateAll,
        handleOnChangeAll,
    ] as const;
}
