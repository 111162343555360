import * as React from 'react';

import { StatusChose, UserRole } from 'constants/commonExportedInterfaces';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import ApproveNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_check_24px.svg';
import { IAuObligationStatus } from 'app/views/home/redux/newHomeObligationInterfaces';
import RejectNexusIcon from '@nexus/core/dist/assets/icons/navigation/ic_close_24px.svg';
import SelectStatusButton from '../selectStatusButton/SelectStatusButton';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import { isSpaceBarPressed } from 'services/isKeyPressed';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';

interface IButtonsComponentSelectStatus {
    uniqueKeyID: string;
    statusAnalyst: string;
    statusReviewer: string;
    name_status_analyst: string;
    name_status_reviewer: string;
    callbackFn: ActionCreatorWithPayload<IAuObligationStatus, string>;
    isSubmitted: boolean;
}

const ButtonsComponentSelectStatus: React.FC<IButtonsComponentSelectStatus> = ({
    uniqueKeyID,
    statusAnalyst,
    statusReviewer,
    callbackFn,
    name_status_analyst,
    name_status_reviewer,
    isSubmitted,
}) => {
    const userRole = useSelector(getUserRole);
    const dispatch = useAppDispatch();
    const handleIconValue = (value: string, uniqueKeyID: string, statusBy: string) => {
        dispatch(callbackFn({ statusBy, uniqueKeyID, value }));
    };
    const statusBy = userRole === UserRole.ANALYST ? name_status_analyst : name_status_reviewer;
    const currentStatus = userRole === UserRole.ANALYST ? statusAnalyst : statusReviewer;

    return (
        <div className='nexus-row' data-testid={uniqueKeyID}>
            <SelectStatusButton
                icon={ApproveNexusIcon}
                status={currentStatus}
                type={StatusChose.APPROVED}
                handleClick={
                    isSubmitted ? () => {} : () => handleIconValue(StatusChose.APPROVED, uniqueKeyID, statusBy)
                }
                handleOnKeyDown={isSpaceBarPressed}
            />
            <SelectStatusButton
                icon={RejectNexusIcon}
                status={currentStatus}
                type={StatusChose.REJECTED}
                handleClick={
                    isSubmitted ? () => {} : () => handleIconValue(StatusChose.REJECTED, uniqueKeyID, statusBy)
                }
                handleOnKeyDown={isSpaceBarPressed}
            />
        </div>
    );
};

export default ButtonsComponentSelectStatus;
