import { IUserProfileAttributes, SelectOption, UserRole } from './commonExportedInterfaces';

import i18n from 'locales/i18n';

export const userRoles: SelectOption[] = [
    { label: UserRole.ANALYST, value: UserRole.ANALYST },
    { label: UserRole.REVIEWER, value: UserRole.REVIEWER },
];

export const chipComponentAttributes: Array<IUserProfileAttributes> = [
    {
        label: i18n.t('userProfileView.priority'),
        testId: 'priorityChip',
        value: i18n.t('userProfileView.priorityValue'),
    },
    {
        label: i18n.t('userProfileView.status'),
        testId: 'statusChip',
        value: i18n.t('userProfileView.new'),
    },
];
