import { ITimeNotification, IDashboardAPIData } from 'constants/commonExportedInterfaces';
import { IChangeAssignedAnalyst, IDeleteCase } from './dashboardReduxInterfaces';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { get } from 'utils/fetch';

export const changeAssignedAnalyst = createAction<IChangeAssignedAnalyst>('dashboard/changeAssignedAnalyst');
export const deleteCase = createAction<IDeleteCase>('dashboard/deleteCase');
export const createNewCase = createAction<IDashboardAPIData>('dashboard/createNewCase');
export const addNotification = createAction<ITimeNotification[]>('dashboard/addNotification');
export const clearNotifications = createAction<void>('dashboard/clearNotifications');
export const sortCases = createAction<string>('dashboard/sortCases');

export const getUsers = createAsyncThunk('dashboard/getUsers', async (role: string, { rejectWithValue }) => {
    try {
        const response = await get({
            baseURL: `https://nexus4risk-backend-rcm-dev.sbp.eyclienthub.com/ui-service/v1/users?role=${role}`,
            timeout: 1000000,
        });
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});
