import './multipleChoiceConfirmationPanel.scss';

import ButtonComponent from 'app/components/nds/button/button';
import { ITableTopBar } from '../tableTopBar/TableTopBar';
import React from 'react';
import { StatusChose } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';
import { useHandleBulk } from 'hooks/useHandleBulk';
import { useHandleBulkCustom } from 'hooks/useHandleBulkCustom';

const MultipleChoiceConfirmationPanel: React.FC<ITableTopBar> = ({
    tableData,
    checkedState,
    statusBy,
    isHidden,
    callbackFn,
    isCustomStatus = false,
}) => {
    const { handleBulk } = useHandleBulk({
        callbackFn,
        checkedState,
        isCustomStatus,
        statusBy,
        tableData,
    });
    const { handleBulkCustom } = useHandleBulkCustom({
        callbackFn,
        checkedState,
        isCustomStatus,
        statusBy,
        tableData,
    });
    const bulkButtons = [
        {
            click: StatusChose.APPROVED,
            extraClass: isHidden ? 'nexus-m-1' : 'bulk approve-green nexus-m-1',
            label: 'bulkApprove',
            type: 'nexus-btn-large',
        },
        {
            click: StatusChose.REJECTED,
            extraClass: isHidden ? '' : 'bulk reject-red',
            label: 'bulkReject',
            type: 'nexus-btn-large',
        },
        {
            extraClass: 'nexus-m-1',
            label: 'bulkCancel',
        },
    ];
    return (
        <div className='nexus-col position-right'>
            {bulkButtons.map(({ click = '', extraClass = '', label = '', type = '' }) => (
                <ButtonComponent
                    key={label}
                    type={['nexus-btn', type]}
                    label={i18n.t(`nexusTable.${label}`)}
                    extraClass={extraClass}
                    click={() => (isCustomStatus ? handleBulkCustom(click) : handleBulk(click))}
                    disabled={isHidden}
                />
            ))}
        </div>
    );
};

export default MultipleChoiceConfirmationPanel;
