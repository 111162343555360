import './chip.scss';

import * as React from 'react';

import { NexusChip } from '@nexus/react';

interface Props {
    title: string;
    disabled?: boolean;
    error?: boolean;
    removable?: boolean;
    success?: boolean;
    contentStyles?: string;
    //* background color is black by default but we can overwrite it by providing backgroundColor prop
    backgroundColor?: 'blue' | 'orange';
    //* text color is white by default but we can overwrite it by providing backgroundColor prop
    contentColor?: 'black';
}

const ChipComponent: React.FC<Props> = ({
    title,
    disabled,
    error,
    removable,
    success,
    contentStyles,
    backgroundColor,
    contentColor,
}) => {
    return (
        <NexusChip
            className={backgroundColor ? backgroundColor : ''}
            data-testid='nexus-chip'
            disabled={disabled}
            success={success}
            removable={removable}
            error={error}
        >
            <span className={`${contentStyles} ${contentColor ? contentColor + '-text' : ''}`}>{title}</span>
        </NexusChip>
    );
};

export default ChipComponent;
