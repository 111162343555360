import './assessControlTable.scss';

import {
    ButtonType,
    IAllDocumentsData,
    ITimeNotification,
    SavedAssessControl,
    StatusBy,
    UserRole,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import {
    addNotificationData,
    getSavedAssessControl,
    saveAnalystAssessControl,
    setPaginationExternally,
} from '../../views/home/redux/homeActions';
import { createBodyTable, translateAssessControlToSaveAdjustedDataSet, validateTableRows } from './commonFunctions';
import {
    getUserRole,
    retrieveAllPagesFile,
    retrieveHashValue,
    selectNotificationsData,
} from '../../views/home/redux/homeSelectors';
import {
    selectIsSubmittedAssessControlTableAnalyst,
    selectIsSubmittedAssessControlTableReviewer,
    selectNewHomeObligation,
} from 'app/views/home/redux/newHomeSelectors';
import { useEffect, useState } from 'react';

import ControlButtons from '../reusable/controlButtons/ControlButtons';
import { INewHomeObligationSliceReducer } from 'app/views/home/redux/newHomeObligationInterfaces';
import MemoAssessControlTableHeader from './AssessControlTableHeader';
import ModalComponent from '../nds/modal/modal';
import { NexusTable } from '@nexus/react';
import NoRecordsTemplate from 'app/components/reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import TableBodyComponent from '../reusable/tableBodyComponent/TableBodyComponent';
import TableTopBar from '../reusable/tableTopBar/TableTopBar';
import TitleBar from '../reusable/titleBar/TitleBar';
import { assessControlHeaderColumnDefinition } from './tableHeaders';
import decideOnApplicableColumns from 'services/decideOnApplicableColumns';
import i18n from 'locales/i18n';
import { limit } from '../../../constants/constants';
import { setStatus } from 'app/views/home/redux/newHomeObligationAction';
import { updateStatusInObligationsToAssessControl } from 'app/views/home/redux/newHomeObligationAction';
import { useAppDispatch } from 'store';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import { useSelectCheckbox } from 'hooks/useSelectCheckbox';
import { useSelector } from 'react-redux';

const AssessControlTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const userRole: UserRole = useSelector(getUserRole);
    const notificationsData: ITimeNotification[] = useSelector(selectNotificationsData);
    const hash: string = useSelector(retrieveHashValue);
    const [page, setPage] = useState<number>(1);
    const pagesVisited = () => (page - 1) * limit;
    const allPagesFiles: IAllDocumentsData = useSelector(retrieveAllPagesFile);
    const [checkedState, setCheckedState, checkedStateAll, setCheckedStateAll, handleOnChange, handleOnChangeAll] =
        useSelectCheckbox();
    const statusBy = userRole === UserRole.ANALYST ? StatusBy.ANALYST : StatusBy.REVIEWER;
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const selectedPage = (data: number) => {
        setPage(data);
        dispatch(setPaginationExternally(false));
    };

    const obligations: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const isSubmittedAnalyst: boolean = useSelector(selectIsSubmittedAssessControlTableAnalyst);
    const isSubmittedReviewer: boolean = useSelector(selectIsSubmittedAssessControlTableReviewer);
    const isSubmitted = userRole === UserRole.ANALYST ? isSubmittedAnalyst : isSubmittedReviewer;

    useEffect(() => {
        setPage(1);
        if (userRole === UserRole.REVIEWER && allPagesFiles.step4.length) {
            dispatch(getSavedAssessControl(hash));
        }
    }, [userRole]);

    if (!obligations.assessControl?.length || (userRole === UserRole.REVIEWER && !isSubmittedAnalyst))
        return (
            <div data-testid='nexus-table-to-au-mapping'>
                <TitleBar title={i18n.t('assessControlTable.titleAccessControl')} />
                <NoRecordsTemplate />
            </div>
        );

    const tableData = createBodyTable({
        checkedState,
        data: obligations.assessControl,
        handleOnChange,
        isSubmitted,
        openDescriptionModal,
        setCheckedState,
    });

    const handleSave = () => {
        // TODO SavedAssessControl must be filled
        const body: SavedAssessControl = {
            Reviewer: 'string',
            Submit: 'string',
            User: 'string',
            User_ID: 'string',
            citation: 'string',
            comments: 'string',
            regulation_id: 'string',
            regulation_name: 'string',
            run_id: hash,
            source_url: 'string',
            status: 'string',
            validate5ws: translateAssessControlToSaveAdjustedDataSet(obligations.assessControl),
        };

        dispatch(saveAnalystAssessControl(body));
    };

    const handleSubmit = () => {
        handleSave();

        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 5000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );

        dispatch(setStatus({ status: 'isSubmittedAssessControlTable', userRole, value: true }));
    };

    return (
        <div data-testid='nexus-assess-control-table'>
            <TableTopBar
                isHidden={checkedState.every((e) => !e) || isSubmitted}
                tableData={tableData}
                checkedState={checkedState}
                statusBy={statusBy}
                callbackFn={updateStatusInObligationsToAssessControl}
            />
            <NexusTable type='custom'>
                <MemoAssessControlTableHeader
                    statusBy={statusBy}
                    checkedStateAll={checkedStateAll}
                    handleOnChangeAll={handleOnChangeAll}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    setCheckedStateAll={setCheckedStateAll}
                    disabledSelectAll={isSubmitted}
                    headers={assessControlHeaderColumnDefinition}
                />
                {!!tableData?.length && (
                    <TableBodyComponent
                        bodyTestId='assess-control-table-body'
                        tableData={tableData}
                        pagesVisited={pagesVisited}
                        checkedState={checkedState}
                        setCheckedState={setCheckedState}
                        decideOn={statusBy}
                        decideOnApplicableColumns={() =>
                            decideOnApplicableColumns(statusBy, assessControlHeaderColumnDefinition)
                        }
                    />
                )}
            </NexusTable>
            <PaginationComponent
                selectedPage={selectedPage}
                offset={0}
                limit={limit}
                size={obligations.assessControl?.length}
                page={page}
            />
            <ControlButtons
                submit={handleSubmit}
                save={handleSave}
                validate={isSubmitted || validateTableRows({ userRole, obligations }, ButtonType.SUBMIT)}
                validateSave={isSubmitted || validateTableRows({ userRole, obligations }, ButtonType.SAVE)}
            />

            <ModalComponent
                showModal={descriptionModal.open}
                closeModal={closeDescriptionModal}
                headerContent={descriptionModal.header ?? 'OBLIGATION DESCRIPTION'}
                modalBodyContent={descriptionModal.description}
                primaryBtnText={'Close'}
                primaryBtnOnclickCallBack={closeDescriptionModal}
                isSecondButton={false}
                size='lg'
            />
        </div>
    );
};

export default AssessControlTable;
