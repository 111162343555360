import { NexusTooltip, NexusTooltipContent, NexusTooltipTrigger } from '@nexus/react';
import React, { ReactNode } from 'react';

interface InputProps {
    message: string;
    children: ReactNode;
    placement?: string;
    allowClick?: boolean;
    attrId?: string;
    className?: string;
}
const TooltipComponent: React.FC<InputProps> = ({ message, children, placement, allowClick, attrId, className }) => (
    <NexusTooltip placement={placement} allow-click={allowClick} data-testid={'nexus-tooltip'} id={attrId}>
        <NexusTooltipTrigger>{children}</NexusTooltipTrigger>

        <NexusTooltipContent data-testid={'nexus-tooltip-content'} className={className}>
            {message}
        </NexusTooltipContent>
    </NexusTooltip>
);

export default TooltipComponent;
