import * as _ from 'lodash';

import { IStep } from 'constants/commonExportedInterfaces';
import { NexusIcon } from '@nexus/react';
import completeIcon from '@nexus/core/dist/assets/icons/navigation/ic_check_24px.svg';
import styles from './stepper.module.scss';

interface IStepper {
    steps: IStep[];
    currentStep?: string;
    breakSpaces?: boolean;
    skippedSteps?: string[];
    handleStepClick?: (e: string) => void;
}

/**
 * Stepper component
 * @param currentStep what is name of current step which will be marked in orange
 * @param breakSpaces if true, word will be breked at every space
 * @param steps array of all steps that should be shown
 * @param skippedSteps array of names for skipped steps ( those will be greyed out
 * @function callback to set current step
 * @constructor
 */

export const Stepper = ({ currentStep, breakSpaces, steps, skippedSteps, handleStepClick }: IStepper): JSX.Element => {
    // * Check of current step
    const activeStepIndex = _.findIndex(steps, (step: IStep) => step.name === currentStep);

    const renderStep = (index: number, step: IStep) => {
        const { name, key, label } = step;
        const wasSkipped = _.some(skippedSteps, (name: string) => step.name === name);
        const isActive = name === currentStep;
        const isComplete = !wasSkipped && index < activeStepIndex;
        // * Build classes
        const activeClass = isActive ? `${styles.active}` : '';
        const completeClass = isComplete ? `${styles.completed}` : '';
        const skippedClass = wasSkipped ? `${styles.skipped}` : '';

        return (
            <div
                data-testid={`step-${index}`}
                key={index}
                className={`${styles.step} ${activeClass} ${completeClass} ${skippedClass}`}
                onClick={handleStepClick ? () => handleStepClick(name) : undefined}
            >
                <div
                    className={`${styles['step-counter']} ${steps.length === index + 1 && styles['last-after']}`}
                    data-testid={`step-counter-${index}`}
                >
                    {!isComplete ? index + 1 : <NexusIcon src={completeIcon} className={styles.check} />}
                </div>
                <div
                    data-testid={`step-label-${index}`}
                    className={`nexus-subtitle ${styles['step-label']} ${breakSpaces ? styles['break-label'] : ''}`}
                >
                    {key} {label}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className={`nexus-row ${styles.stepper} nexus-mt-2 nexus-mb-2`}>
                {steps.map((step, index) => renderStep(index, step))}
            </div>
        </>
    );
};
