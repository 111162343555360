import { NexusTable } from '@nexus/react';
import { getUserRole, selectNotificationsData } from 'app/views/home/redux/homeSelectors';
import {
    IDistinctFilters,
    INewHomeObligationSliceReducer,
    ISummaryScreen5MappingStructure,
} from 'app/views/home/redux/newHomeObligationInterfaces';
import { selectNewHomeObligation } from 'app/views/home/redux/newHomeSelectors';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';
import {
    IFilterRequest,
    IHeader,
    ISummaryListRequest,
    ITimeNotification,
    SelectOption,
    StatusBy,
    UserRole,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import {
    DefaultSelectCitationSection,
    DefaultSelectControlId,
    DefaultSelectProcessName,
    DefaultSelectRiskId,
    DefaultSelectRiskLevel1,
    DefaultSelectRiskLevel2,
    DefaultSelectRuleId,
    DefaultSelectSubProcessName,
    limit,
} from 'constants/constants';
import { useSelectedPage } from 'hooks/useSelectedPage';
import i18n from 'locales/i18n';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from '../nds/select/select';
import decideOnApplicableColumns from 'services/decideOnApplicableColumns';
import PaginationComponent from '../nds/pagination/pagination';
import DescriptionComponentModal from '../reusable/descriptionComponentModal/DescriptionComponentModal';
import TableBodyComponent from '../reusable/tableBodyComponent/TableBodyComponent';
import TitleBar from '../reusable/titleBar/TitleBar';
import { ISummaryRowScreen5 } from '../tableToAuMapping/ITableToAuMapping';
import TableHeaderComponent from '../tableToAuMapping/TableHeaderComponent';
import { endToEndMappingColumnDefinitionRwa } from './tableHeader';
import './endToEndMapping.scss';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import ModalComponent from '../nds/modal/modal';
import ButtonComponent from '../nds/button/button';
import {
    addNotificationData,
    // getRestFilterResults,
    getSummaryList,
    downloadExcelSummary,
} from 'app/views/home/redux/homeActions';
import { useAppDispatch } from 'store';
import LoaderComponent from '../nds/loader/loader';
import plus from '../../assets/images/icon-plus.png';
import minus from '../../assets/images/icon-minus.png';
import React from 'react';
import { post } from 'utils/fetch';

type bodyTable = {
    data: ISummaryScreen5MappingStructure[];
    openDescriptionModal: (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => void;
};

const EndToEndMapping: React.FC = () => {
    const dispatch = useAppDispatch();
    const [tableHeaders, setTableHeaders] = useState<IHeader[]>([]);
    const summaryData: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    // const resultDistinctFilters: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const [ruleId, setRuleId] = useState<string>(DefaultSelectRuleId);
    const [renderAgain, setRenderAgain] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);
    const [citationSection, setCitationSection] = useState<string>(DefaultSelectCitationSection);
    const [processName, setProcessName] = useState<string>(DefaultSelectProcessName);
    const [subProcessName, setSubProcessName] = useState<string>(DefaultSelectSubProcessName);
    const [riskLevel1, setRiskLevel1] = useState<string>(DefaultSelectRiskLevel1);
    const [riskLevel2, setRiskLevel2] = useState<string>(DefaultSelectRiskLevel2);
    const [riskId, setRiskId] = useState<string>(DefaultSelectRiskId);
    const [controlId, setControlId] = useState<string>(DefaultSelectControlId);
    const [showLoader, setShowLoader] = useState(false);
    const userRole: UserRole = useSelector(getUserRole);
    const { page, selectedPage, pagesVisited } = useSelectedPage();
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const notificationsData: ITimeNotification[] = useSelector(selectNotificationsData);
    const [filterResults, setFilterResults] = useState<IDistinctFilters>({
        distinctRuleIdValues: [],
        distinctCitationSectionValues: [],
        distinctProcessNameValues: [],
        distinctSubProcessNameValues: [],
        distinctRiskLevel1Values: [],
        distinctRiskLevel2Values: [],
        distinctRiskIdValues: [],
        distinctControlIdValues: [],
    });
    const [disableApplyFilter, setDisableApplyFilter] = useState<boolean>(true);
    const [disableDownload, setDisableDownload] = useState<boolean>(true);

    const statusBy = userRole === UserRole.ANALYST ? StatusBy.ANALYST : StatusBy.REVIEWER;

    let payload: ISummaryListRequest = {
        citationSection: '',
        controlId: '',
        page: 1,
        processName: '',
        riskId: '',
        riskLevel1: '',
        riskLevel2: '',
        ruleId: '',
        size: 0,
        subProcessName: '',
    };

    let filterPayload: IFilterRequest = {
        citationSection: '',
        controlId: '',
        processName: '',
        riskId: '',
        riskLevel1: '',
        riskLevel2: '',
        ruleId: '',
        subProcessName: '',
    };

    useEffect(() => {
        if (REACT_APP_CUSTOM_FOR_CLIENT === 'rwa') {
            setTableHeaders(endToEndMappingColumnDefinitionRwa);
        }
    }, [REACT_APP_CUSTOM_FOR_CLIENT]);

    useEffect(() => {
        const run = async () => {
            try {
                const response = await post({
                    baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                    data: filterPayload,
                    timeout: 1000000,
                });

                setFilterResults(response.data);

                return response;
            } catch (error: any) {
                //
            }
        };
        run();

        viewAll();
    }, []);

    const ruleIdOptions = (values: any) => {
        const mappedIds: SelectOption[] = [];
        values.forEach((id: any) => {
            mappedIds.push({ label: id, value: id });
        });
        return mappedIds;
    };

    const createBodyTable = ({ data, openDescriptionModal }: bodyTable) => {
        const tableBodyData: ISummaryRowScreen5[] = [];

        data?.forEach(
            ({
                ruleId,
                rulesHighlightedText,
                rulesSummaryText,
                processName,
                processDefinition,
                subProcessName,
                subProcessDescription,
                riskLevel1,
                riskLevel2,
                citationSection,
                riskDescription,
                controlDescription,
                controlId,
                controlType,
                riskId,
            }: ISummaryScreen5MappingStructure) =>
                tableBodyData.push({
                    citationSection: citationSection,
                    controlDescription: (
                        <DescriptionComponentModal
                            description={controlDescription}
                            title={'endToEndMapping.controlDescription'}
                            popupDisplayText={controlDescription}
                            subTitle=''
                            tooltipText={'tableToAuMapping.tooltipText'}
                            openDescriptionModal={openDescriptionModal}
                            hideConfidence={true}
                            confidenceScore={0}
                        />
                    ),
                    controlId: controlId,
                    controlType: controlType,
                    processName: (
                        <DescriptionComponentModal
                            description={processDefinition}
                            title={'tableToAuMapping.baselprocessname'}
                            popupDisplayText={processName}
                            subTitle=''
                            tooltipText={'tableToAuMapping.tooltipText'}
                            openDescriptionModal={openDescriptionModal}
                            hideConfidence={true}
                            confidenceScore={0}
                        />
                    ),
                    riskDescription: (
                        <DescriptionComponentModal
                            description={riskDescription}
                            title={'endToEndMapping.riskDescription'}
                            popupDisplayText={riskDescription}
                            subTitle=''
                            tooltipText={'tableToAuMapping.tooltipText'}
                            openDescriptionModal={openDescriptionModal}
                            hideConfidence={true}
                            confidenceScore={0}
                        />
                    ),
                    riskId: riskId,
                    riskLevel1: riskLevel1,
                    riskLevel2: riskLevel2,
                    ruleId: ruleId,
                    rulesHighlightedText: (
                        <DescriptionComponentModal
                            description={rulesHighlightedText}
                            title={'obligationSummary.obligationHighlightedText'}
                            popupDisplayText={rulesHighlightedText}
                            subTitle=''
                            tooltipText={'tableToAuMapping.tooltipText'}
                            openDescriptionModal={openDescriptionModal}
                            hideConfidence={true}
                            confidenceScore={0}
                        />
                    ),
                    rulesSummaryText: (
                        <DescriptionComponentModal
                            description={rulesSummaryText}
                            title={'obligationSummary.obligationSummaryText'}
                            popupDisplayText={rulesSummaryText}
                            subTitle=''
                            tooltipText={'tableToAuMapping.tooltipText'}
                            openDescriptionModal={openDescriptionModal}
                            hideConfidence={true}
                            confidenceScore={0}
                        />
                    ),
                    subProcessName: (
                        <DescriptionComponentModal
                            description={subProcessDescription}
                            title={'tableToAuMapping.baselsubprocess'}
                            popupDisplayText={subProcessName}
                            subTitle=''
                            tooltipText={'tableToAuMapping.tooltipText'}
                            openDescriptionModal={openDescriptionModal}
                            hideConfidence={true}
                            confidenceScore={0}
                        />
                    ),
                }),
        );
        return tableBodyData;
    };

    const tableData = createBodyTable({
        data: summaryData.summaryDataForEndToEndMapping,
        openDescriptionModal,
    });

    const notify = () => {
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: `${i18n.t('endToEndMapping.downloadSuccessMsg')}`,
                    time: 3000,
                    variant: VariantChose.SUCCESS,
                },
            ]),
        );
    };

    const downloadExcel = () => {
        payload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            page: 1,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
            size: 0,
        };
        dispatch(downloadExcelSummary(payload));
    };

    const clearAll = async () => {
        setRuleId(DefaultSelectRuleId);
        setRiskId(DefaultSelectRiskId);
        setCitationSection(DefaultSelectCitationSection);
        setRiskLevel1(DefaultSelectRiskLevel1);
        setRiskLevel2(DefaultSelectRiskLevel2);
        setProcessName(DefaultSelectProcessName);
        setSubProcessName(DefaultSelectSubProcessName);
        setControlId(DefaultSelectControlId);
        setRiskId(DefaultSelectRiskId);
        setShowLoader(true);
        setDisableApplyFilter(true);
        setDisableDownload(true);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();

            dispatch(getSummaryList(payload)).then((data: any) => {
                if (data && data.payload) {
                    setShowLoader(false);
                    setDataLoaded(true);
                }
            });
        } catch (error: any) {
            //
        }
    };

    const applyFilters = () => {
        payload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            page: 1,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            size: 0,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setShowLoader(true);
        setDisableDownload(false);
        dispatch(getSummaryList(payload)).then((data: any) => {
            if (data && data.payload) {
                setShowLoader(false);
                setDataLoaded(true);
            }
        });
    };

    const viewAll = () => {
        payload = {
            citationSection: '',
            controlId: '',
            page: 1,
            processName: '',
            riskId: '',
            riskLevel1: '',
            riskLevel2: '',
            ruleId: '',
            size: 0,
            subProcessName: '',
        };
        setShowLoader(true);
        hideFilterComponent();
        dispatch(getSummaryList(payload)).then((data: any) => {
            if (data && data.payload) {
                setShowLoader(false);
                setDataLoaded(true);
            }
        });
    };

    const showFilterComponent = () => {
        setShowFilter(true);
    };

    const hideFilterComponent = () => {
        setShowFilter(false);
    };

    const setRenderAgainValue = () => {
        if (renderAgain) {
            setRenderAgain(false);
        } else {
            setRenderAgain(true);
        }
    };

    const handleRuleIdFilters = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: value,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);

        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);

            setRenderAgainValue();
            setRuleId(value);

            return response;
        } catch (error: any) {
            //
        }
    };

    const handleCitationSectionFilters = async (value: string) => {
        filterPayload = {
            citationSection: value,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setCitationSection(value);

            return response;
        } catch (error: any) {
            //
        }
    };

    const handleProcessName = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: value,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setProcessName(value);
            return response;
        } catch (error: any) {
            //
        }
    };

    const handleSubProcessName = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: value,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setSubProcessName(value);
            return response;
        } catch (error: any) {
            //
        }
    };
    const handleRiskLevel1 = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: value,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setRiskLevel1(value);
            return response;
        } catch (error: any) {
            //
        }
    };

    const handleRiskLevel2 = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: value,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setRiskLevel2(value);
            return response;
        } catch (error: any) {
            //
        }
    };

    const handleRiskId = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: controlId === DefaultSelectControlId ? '' : controlId,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: value,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setRiskId(value);

            return response;
        } catch (error: any) {
            //
        }
    };

    const handleControlId = async (value: string) => {
        filterPayload = {
            citationSection: citationSection === DefaultSelectCitationSection ? '' : citationSection,
            controlId: value,
            processName: processName === DefaultSelectProcessName ? '' : processName,
            riskId: riskId === DefaultSelectRiskId ? '' : riskId,
            riskLevel1: riskLevel1 === DefaultSelectRiskLevel1 ? '' : riskLevel1,
            riskLevel2: riskLevel2 === DefaultSelectRiskLevel2 ? '' : riskLevel2,
            ruleId: ruleId === DefaultSelectRuleId ? '' : ruleId,
            subProcessName: subProcessName === DefaultSelectSubProcessName ? '' : subProcessName,
        };
        setDisableApplyFilter(false);
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: filterPayload,
                timeout: 1000000,
            });
            setFilterResults(response.data);
            setRenderAgainValue();
            setControlId(value);

            return response;
        } catch (error: any) {
            //
        }
    };
    // const handleFilters = async (key, value) => {
    //     console.log('keyvalue>>>>>>', key, value);

    //     filterPayload = {
    //         citationSection: '',
    //         controlId: '',
    //         processName: '',
    //         riskId: '',
    //         riskLevel1: '',
    //         riskLevel2: '',
    //         ruleId: value,
    //         subProcessName: '',
    //     };
    //     try {
    //         const response = await post({
    //             baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
    //             data: filterPayload,
    //             timeout: 1000000,
    //         });
    //         console.log('responseinsidehandler>>>>', response);

    //         setFilterResults({ ...response.data, key: filterResults[key] });
    //         setFilterResults1(response.data);
    //         setShowFilter(true);

    //         return response;
    //     } catch (error: any) {
    //         // return console.(error.message);
    //         console.log('error>>', error);
    //     }
    // };

    return (
        <>
            <div>
                <TitleBar title={i18n.t('endToEndMapping.titleEndToEndMapping')} />
                {/* <div className='right-btn-align button-group filter-button'>
                    <ButtonComponent
                        click={viewAll}
                        extraClass={'width-button button-spacing'}
                        type={['nexus-btn-primary']}
                        label={i18n.t('endToEndMapping.viewAll')}
                    />
                    <ButtonComponent
                        click={showFilterComponent}
                        extraClass={'width-button button-spacing'}
                        type={['nexus-btn-primary']}
                        label={i18n.t('endToEndMapping.applyFilters')}
                    />
                </div> */}
                <div className='filter-div'>
                    {!showFilter && (
                        <div className='div-align-items'>
                            <img className='icon-show' src={plus} alt={'show'} onClick={showFilterComponent} />
                            <div className='ruleId-margin'>
                                <h3>Filters</h3>
                            </div>
                            {/* <div className='margin-left-accordion'>Citation Section</div>
                            <div className='margin-left-accordion'>Process Name</div>
                            <div className='margin-left-accordion'>Sub Process Name</div> */}
                        </div>
                    )}
                    {showFilter && (
                        <div>
                            <div>
                                <img className='icon-hide' src={minus} alt={'hide'} onClick={hideFilterComponent} />
                            </div>
                            <div className='grid-container'>
                                <div className='grid-item' id='ruleId'>
                                    {/* {Object.keys(filterResults).map((key: any) => {
                                        return (
                                            <div key={key}>
                                                {' '}
                                                <Select
                                                    key={key}
                                                    classes={'grid-sub-item'}
                                                    // options={ruleIdOptions(distinctRuleId)}
                                                    options={ruleIdOptions(filterResults[key])}
                                                    // showSelectedValueOnInit={true}
                                                    label={i18n.t('endToEndMapping.rulesId')}
                                                    selectedValue={ruleId}
                                                    customOnChange={(value) => handleFilters(key, value)}
                                                    data-testid={'test'}
                                                    defaultValue={DefaultSelectRuleId}
                                                />{' '}
                                            </div>
                                        );
                                    })} */}
                                    {!renderAgain && filterResults && filterResults.distinctRuleIdValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            // options={ruleIdOptions(distinctRuleId)}
                                            options={ruleIdOptions(filterResults.distinctRuleIdValues)}
                                            // showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.rulesId')}
                                            // selectedValue={filterResults.distinctRuleIdValues[0]}
                                            selectedValue={ruleId}
                                            customOnChange={handleRuleIdFilters}
                                            data-testid={'test'}
                                            defaultValue={DefaultSelectRuleId}
                                        />
                                    )}
                                    {renderAgain && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            // options={ruleIdOptions(distinctRuleId)}
                                            options={ruleIdOptions(filterResults.distinctRuleIdValues)}
                                            // showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.rulesId')}
                                            // selectedValue={filterResults.distinctRuleIdValues[0]}
                                            selectedValue={ruleId}
                                            customOnChange={handleRuleIdFilters}
                                            data-testid={'test'}
                                            defaultValue={DefaultSelectRuleId}
                                        />
                                    )}
                                    {/* <label>Choose :</label>
                                    <select onChange={handleRuleIdFilters}>
                                        <option value=''>Select</option>
                                        {filterResults &&
                                            ruleIdOptions(filterResults.distinctRuleIdValues).map((options) => (
                                                <option key={options.label} value={options.value}>
                                                    {options.label}
                                                </option>
                                            ))}
                                    </select> */}
                                    {/* )} */}
                                </div>
                                {/* <Select
                                    classes={'grid-sub-item'}
                                    options={{ label: 'Process1', value: 'Process1' }}
                                    showSelectedValueOnInit={true}
                                    label={i18n.t('endToEndMapping.rulesId')}
                                    selectedValue={ruleId}
                                    // customOnChange={handleRuleIdFilters}
                                    data-testid={'test'}
                                    defaultValue={DefaultSelectRuleId}
                                /> */}
                                <div className='grid-item' id='cs'>
                                    {!renderAgain && filterResults && filterResults.distinctCitationSectionValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctCitationSectionValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.citationSection')}
                                            selectedValue={citationSection}
                                            customOnChange={handleCitationSectionFilters}
                                            defaultValue={DefaultSelectCitationSection}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctCitationSectionValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctCitationSectionValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.citationSection')}
                                            selectedValue={citationSection}
                                            customOnChange={handleCitationSectionFilters}
                                            defaultValue={DefaultSelectCitationSection}
                                        />
                                    )}
                                </div>
                                <div className='grid-item'>
                                    {!renderAgain && filterResults && filterResults.distinctProcessNameValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctProcessNameValues)}
                                            // options={ruleI}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.processName')}
                                            selectedValue={processName}
                                            customOnChange={handleProcessName}
                                            defaultValue={DefaultSelectProcessName}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctProcessNameValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctProcessNameValues)}
                                            // options={ruleI}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.processName')}
                                            selectedValue={processName}
                                            customOnChange={handleProcessName}
                                            defaultValue={DefaultSelectProcessName}
                                        />
                                    )}
                                </div>
                                <div className='grid-item'>
                                    {!renderAgain && filterResults && filterResults.distinctSubProcessNameValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctSubProcessNameValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.subProcessName')}
                                            selectedValue={subProcessName}
                                            customOnChange={handleSubProcessName}
                                            defaultValue={DefaultSelectSubProcessName}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctSubProcessNameValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctSubProcessNameValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.subProcessName')}
                                            selectedValue={subProcessName}
                                            customOnChange={handleSubProcessName}
                                            defaultValue={DefaultSelectSubProcessName}
                                        />
                                    )}
                                </div>
                                <div className='fit-grid'>
                                    <ButtonComponent
                                        click={applyFilters}
                                        extraClass={'width-button single-btn-spacing'}
                                        type={['nexus-btn-primary']}
                                        label={i18n.t('endToEndMapping.applyFilters')}
                                        disabled={disableApplyFilter}
                                    />
                                </div>
                                <div className='grid-item'>
                                    {!renderAgain && filterResults && filterResults.distinctRiskLevel1Values && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctRiskLevel1Values)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.riskLevel1')}
                                            selectedValue={riskLevel1}
                                            customOnChange={handleRiskLevel1}
                                            defaultValue={DefaultSelectRiskLevel1}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctRiskLevel1Values && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctRiskLevel1Values)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.riskLevel1')}
                                            selectedValue={riskLevel1}
                                            customOnChange={handleRiskLevel1}
                                            defaultValue={DefaultSelectRiskLevel1}
                                        />
                                    )}
                                </div>
                                <div className='grid-item'>
                                    {!renderAgain && filterResults && filterResults.distinctRiskLevel2Values && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctRiskLevel2Values)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.riskLevel2')}
                                            selectedValue={riskLevel2}
                                            customOnChange={handleRiskLevel2}
                                            defaultValue={DefaultSelectRiskLevel2}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctRiskLevel2Values && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctRiskLevel2Values)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.riskLevel2')}
                                            selectedValue={riskLevel2}
                                            customOnChange={handleRiskLevel2}
                                            defaultValue={DefaultSelectRiskLevel2}
                                        />
                                    )}
                                </div>
                                <div className='grid-item'>
                                    {!renderAgain && filterResults && filterResults.distinctRiskIdValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctRiskIdValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.riskId')}
                                            selectedValue={riskId}
                                            customOnChange={handleRiskId}
                                            defaultValue={DefaultSelectRiskId}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctRiskIdValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctRiskIdValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.riskId')}
                                            selectedValue={riskId}
                                            customOnChange={handleRiskId}
                                            defaultValue={DefaultSelectRiskId}
                                        />
                                    )}
                                </div>
                                <div className='grid-item'>
                                    {!renderAgain && filterResults && filterResults.distinctControlIdValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctControlIdValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.controlId')}
                                            selectedValue={controlId}
                                            customOnChange={handleControlId}
                                            defaultValue={DefaultSelectControlId}
                                        />
                                    )}
                                    {renderAgain && filterResults && filterResults.distinctControlIdValues && (
                                        <Select
                                            classes={'grid-sub-item'}
                                            options={ruleIdOptions(filterResults.distinctControlIdValues)}
                                            showSelectedValueOnInit={true}
                                            label={i18n.t('endToEndMapping.controlId')}
                                            selectedValue={controlId}
                                            customOnChange={handleControlId}
                                            defaultValue={DefaultSelectControlId}
                                        />
                                    )}
                                </div>
                                <div className='grid-item button-group'>
                                    <ButtonComponent
                                        click={downloadExcel}
                                        extraClass={'width-button button-spacing'}
                                        type={['nexus-btn-primary']}
                                        label={i18n.t('endToEndMapping.download')}
                                        disabled={disableDownload}
                                    />
                                    <ButtonComponent
                                        click={clearAll}
                                        extraClass={'width-button button-spacing'}
                                        type={['nexus-btn-primary']}
                                        label={i18n.t('endToEndMapping.clearAll')}
                                        disabled={disableApplyFilter}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='table-style'>
                    {showLoader && <LoaderComponent show={true} fullscreen={true} />}
                    <TitleBar
                        title={i18n.t('endToEndMapping.titleSummary')}
                        header1={i18n.t('endToEndMapping.processSummary')}
                        header2={i18n.t('endToEndMapping.risk')}
                        header3={i18n.t('endToEndMapping.control')}
                        extraClass={'card-width-style-rwa'}
                    />
                    {dataLoaded && (
                        <NexusTable type='custom'>
                            <TableHeaderComponent
                                headers={tableHeaders}
                                statusBy={statusBy}
                                checkedState={[]}
                                checkedStateAll={false}
                                setCheckedState={() => {}}
                                setCheckedStateAll={() => {}}
                                handleOnChangeAll={() => {}}
                                disabledSelectAll={false}
                            />
                            <TableBodyComponent
                                bodyTestId='obligation-summary-table-body-id'
                                tableData={tableData}
                                decideOn={statusBy}
                                decideOnApplicableColumns={() => decideOnApplicableColumns(statusBy, tableHeaders)}
                                pagesVisited={pagesVisited}
                                checkedState={[]}
                                setCheckedState={() => {}}
                            />
                            <PaginationComponent
                                selectedPage={selectedPage}
                                offset={0}
                                limit={limit}
                                size={tableData.length}
                                page={page}
                            />
                        </NexusTable>
                    )}
                    <ModalComponent
                        showModal={descriptionModal.open}
                        closeModal={closeDescriptionModal}
                        headerContent={descriptionModal.header ?? ''}
                        modalBodyContent={descriptionModal.description}
                        primaryBtnText={'Close'}
                        primaryBtnOnclickCallBack={closeDescriptionModal}
                        isSecondButton={false}
                        size='lg'
                    />
                </div>
                <div className='rightAlign'>
                    <a href={'/ConsolidatedData.xlsx'} target='_blank' rel='noopener noreferrer' download>
                        <ButtonComponent
                            click={notify}
                            type={['nexus-btn-primary']}
                            label={i18n.t('endToEndMapping.downloadExcel')}
                            extraClass='width-button'
                        />
                    </a>
                </div>
            </div>
        </>
    );
};

const memoReact = React.memo(EndToEndMapping);
export default memoReact;
