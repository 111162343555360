import './loginPage.scss';

import { NexusCard, NexusFormField, NexusIcon, NexusInput, NexusLabel } from '@nexus/react';
import React, { useEffect, useRef, useState } from 'react';
import { selectAuthEmail, selectAuthToken, selectIsAuthTokenExpired, setAuthToken, setAuthUser } from '../../../auth';
import { useDispatch, useSelector } from 'react-redux';

import LoaderComponent from '../../components/nds/loader/loader';
import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import { authenticate } from '../../../auth/ad/adAuthProvider';
import i18n from 'locales/i18n';
import jwtDecode from 'jwt-decode';
import { parse } from 'query-string';
// import store from '../../../store';
import { useNavigate } from 'react-router-dom';

const formatTokenData = (accessToken: any, expiresIn: any) => ({
    accessToken,
    tokenExpiration: Date.now() + expiresIn * 1000,
});
/**
 * Extracts relevant user data from the id token
 * @param {} token
 * @param {*} dispatch
 */
const decodeIdToken = (token: any, dispatch: any) => {
    if (!token) {
        return;
    }
    const { name, preferred_username, unique_name }: { [id: string]: string } = jwtDecode(token);
    dispatch(setAuthUser({ name, email: unique_name || preferred_username }));
};

const LoginPage = () => {
    const email = useSelector(selectAuthEmail);
    const accessToken = useRef(useSelector(selectAuthToken));
    const isTokenExpired = useSelector(selectIsAuthTokenExpired);
    const [isLoading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    function handleSignIn() {
        setLoading(true);
        setTimeout(() => {
            navigate('/home');
        }, 2000);
    }

    useEffect(() => {
        const { hash, pathname, search } = window.location;
        const response = parse(hash);
        if (Object.keys(response).length) {
            const { access_token, id_token, expires_in } = response;
            window.history.pushState('', document.title, `${pathname}${search}`);

            // If no token, something went wrong, update token state to prevent re-rendering
            if (access_token) {
                accessToken.current = access_token || 'INVALID_TOKEN';
                const tokenData = formatTokenData(access_token, Number(expires_in));
                dispatch(setAuthToken(tokenData));

                decodeIdToken(id_token, dispatch);
                handleSignIn();
            }
        }
        // if (accessToken) {
        //     handleSignIn();
        // }
    }, []);

    useEffect(() => {
        if (!accessToken.current || accessToken.current === '') {
            authenticate();
        }
    }, [isTokenExpired]);

    return (
        <div className='nexus-container nexus-body'>
            <div className='nexus-row'>
                <div className='nexus-col-xs-4 nexus-col-lg-offset-3 nexus-col-lg-5'>
                    <div className='nexus-center-xs login-page-logo'>
                        <NexusIcon src={Logo} />
                        <div className='login-page-subheader'>
                            <p className='nexus-h4'>{i18n.t<string>('loginPage.title')} </p>
                        </div>
                    </div>

                    <div className='nexus-theme-dark'>
                        <NexusCard bg-color='black'>
                            <NexusLabel>Email :</NexusLabel>
                            <NexusFormField>
                                <NexusInput value={email} type='email' placeholder='Email' />
                            </NexusFormField>
                            {isLoading ? (
                                <button className='nexus-btn nexus-btn-primary nexus-btn-fluid'>
                                    {' '}
                                    <LoaderComponent show={isLoading} />
                                </button>
                            ) : (
                                <button className='nexus-btn nexus-btn-primary nexus-btn-fluid' onClick={handleSignIn}>
                                    Sign In
                                </button>
                            )}
                        </NexusCard>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LoginPage;
