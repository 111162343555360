import './obligationSummary.scss';

import { ButtonType, SavedObligationData, StatusBy, UserRole, IHeader } from 'constants/commonExportedInterfaces';
import {
    INewHomeObligationSliceReducer,
    IObligationTableScreen1,
} from 'app/views/home/redux/newHomeObligationInterfaces';
import { NexusCheckbox, NexusTable } from '@nexus/react';
import React, { useEffect, useState } from 'react';
import {
    addNotificationData,
    getSavedObligation,
    saveAnalystObligations,
    saveReviewerObligations,
} from 'app/views/home/redux/homeActions';
import { retrieveAllPagesFile, selectObligationsToSave } from 'app/views/home/redux/homeSelectors';
import { retrieveHashValue, selectNotificationsData, selectObligation } from '../../views/home/redux/homeSelectors';
import {
    selectIsSubmittedDocumentExtractionAnalyst,
    selectIsSubmittedDocumentExtractionReviewer,
    selectNewHomeObligation,
} from 'app/views/home/redux/newHomeSelectors';
import {
    setStatus,
    updateStatusInObligationsToDocumentExtractionTable,
} from 'app/views/home/redux/newHomeObligationAction';

import ButtonsComponentSelectStatus from '../reusable/buttonsComponentSelectStatus/ButtonsComponentSelectStatus';
import ChipComponentPercent from '../reusable/chipComponentPercent/ChipComponentPercent';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import DescriptionComponentModal from '../reusable/descriptionComponentModal/DescriptionComponentModal';
import EditIcon from '@nexus/core/dist/assets/icons/editor/ic_mode_edit_24px.svg';
import { IObligationRow } from './ITableToObligationSummary';
import IconButton from '../reusable/iconButton/IconButton';
import ModalComponent from '../nds/modal/modal';
import NoRecordsTemplate from 'app/components/reusable/noRecords/NoRecordsTemplate';
import ObligationTableHeaderComponent from './ObligationTableHeaderComponent';
import PaginationComponent from '../nds/pagination/pagination';
import TableBodyComponent from '../reusable/tableBodyComponent/TableBodyComponent';
import TableTopBar from '../reusable/tableTopBar/TableTopBar';
import TitleBar from '../reusable/titleBar/TitleBar';
import { buttonValidation } from 'services/buttonValidation';
import decideOnApplicableColumns from 'services/decideOnApplicableColumns';
import { getUserRole } from 'app/views/home/redux/homeSelectors';
import i18n from 'locales/i18n';
import { limit } from 'constants/constants';
import { obligationsHeaderColumnDefinition, obligationsHeaderColumnDefinitionRwa } from './tableHeader';
import { useAppDispatch } from 'store';
import { useDescriptionModal } from 'hooks/useDescriptionModal';
import { useEditObligationSummaryModal } from 'hooks/useEditObligationSummaryModal';
import { useSelectCheckbox } from 'hooks/useSelectCheckbox';
import { useSelectedPage } from 'hooks/useSelectedPage';
import { useSelector } from 'react-redux';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

/** Helper Pure functions */
type bodyTable = {
    data: IObligationTableScreen1[];
    openDescriptionModal: (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => void;
    handleOnChange: (position: number, checkedState: boolean[], setCheckedState: (value: boolean[]) => void) => void;
    checkedState: boolean[];
    setCheckedState: React.Dispatch<React.SetStateAction<boolean[]>>;
    openEditObligationSummaryModal: (
        title: string,
        subTitle: string,
        confidence: number,
        obligationSummaryText: string,
        obligationHighlightedText: string,
        uniqueKeyID: string,
        rule_id: string,
    ) => void;
    isSubmitted: boolean;
};

const createBodyTable = ({
    checkedState,
    data,
    handleOnChange,
    openDescriptionModal,
    setCheckedState,
    openEditObligationSummaryModal,
    isSubmitted,
}: bodyTable) => {
    const tableBodyData: IObligationRow[] = [];

    data?.forEach(
        (
            {
                confidence,
                justification,
                // ob_id,
                obligation_highlighted_text,
                obligation_name,
                obligation_summary_text,
                status,
                status_to_be_reviewed,
                uniqueKeyID,
                rule_id,
            }: IObligationTableScreen1,
            index: number,
        ) =>
            tableBodyData.push({
                // ! Reviewer role
                analyst_status: (
                    <ButtonsComponentSelectStatus
                        uniqueKeyID={uniqueKeyID}
                        statusAnalyst={status}
                        statusReviewer={status_to_be_reviewed}
                        callbackFn={updateStatusInObligationsToDocumentExtractionTable}
                        name_status_analyst={'status'}
                        name_status_reviewer={'status_to_be_reviewed'}
                        isSubmitted={isSubmitted}
                    />
                ),
                citation_section: obligation_name,
                confidence: <ChipComponentPercent value={confidence} />,
                edit: (
                    <IconButton
                        iconSrc={EditIcon}
                        customOnClick={
                            isSubmitted
                                ? () => {}
                                : () =>
                                      openEditObligationSummaryModal(
                                          'obligationSummary.rulesSummary',
                                          obligation_name,
                                          confidence,
                                          obligation_summary_text,
                                          obligation_highlighted_text,
                                          uniqueKeyID,
                                          rule_id,
                                      )
                        }
                    />
                ),
                indexSelect: index,
                obligation_highlighted_text: (
                    <DescriptionComponentModal
                        description={obligation_highlighted_text}
                        confidenceScore={confidence}
                        title={'obligationSummary.descriptionModalHeader'}
                        subTitle={justification}
                        tooltipText={'obligationSummary.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={true}
                    />
                ),
                obligation_summary_text: (
                    <DescriptionComponentModal
                        description={obligation_summary_text}
                        confidenceScore={confidence}
                        title={'obligationSummary.obligationSummaryText'}
                        subTitle={justification}
                        tooltipText={'obligationSummary.tooltipText'}
                        openDescriptionModal={openDescriptionModal}
                        hideConfidence={true}
                    />
                ),
                saved_analyst_status: status,
                select: (
                    <NexusCheckbox
                        data-testid='obligation-summary-table-checkbox'
                        className='nexus-rhythm-2'
                        onInput={() => handleOnChange(index, checkedState, setCheckedState)}
                        checked={checkedState[index]}
                        disabled={isSubmitted}
                    />
                ),
                // ! Analyst role
                status: (
                    <ButtonsComponentSelectStatus
                        uniqueKeyID={uniqueKeyID}
                        statusAnalyst={status}
                        statusReviewer={status_to_be_reviewed}
                        callbackFn={updateStatusInObligationsToDocumentExtractionTable}
                        name_status_analyst={'status'}
                        name_status_reviewer={'status_to_be_reviewed'}
                        isSubmitted={isSubmitted}
                    />
                ),
                uniqueKeyID,
            }),
    );

    return tableBodyData;
};

type tableRows = { userRole: UserRole; obligations: INewHomeObligationSliceReducer };
const validateTableRows = ({ userRole, obligations }: tableRows, buttonType: ButtonType) => {
    const option = userRole === UserRole.REVIEWER ? 'status_to_be_reviewed' : 'status';
    const rowsStatuses = obligations.obligationsToDocumentExtractionTable.map(
        (obligation: IObligationTableScreen1) => obligation[option],
    );
    const valid = buttonValidation(buttonType, rowsStatuses);
    return !valid;
};

const ObligationSummary: React.FC = () => {
    const userRole = useSelector(getUserRole);
    const notificationsData = useSelector(selectNotificationsData);
    const hash = useSelector(retrieveHashValue);
    const fullObligationsData = useSelector(selectObligation);
    const dispatch = useAppDispatch();
    const allPagesFiles = useSelector(retrieveAllPagesFile);

    const obligationsToSave = useSelector(selectObligationsToSave);
    const obligations: INewHomeObligationSliceReducer = useSelector(selectNewHomeObligation);
    const [closeDescriptionModal, descriptionModal, openDescriptionModal] = useDescriptionModal();
    const [checkedState, setCheckedState, checkedStateAll, setCheckedStateAll, handleOnChange, handleOnChangeAll] =
        useSelectCheckbox();
    const statusBy = userRole === UserRole.ANALYST ? StatusBy.ANALYST : StatusBy.REVIEWER;
    const statusByPropertyStep1 = userRole === UserRole.ANALYST ? 'status' : 'status_to_be_reviewed';
    const isSubmittedAnalyst: boolean = useSelector(selectIsSubmittedDocumentExtractionAnalyst);
    const isSubmittedReviewer: boolean = useSelector(selectIsSubmittedDocumentExtractionReviewer);
    const isSubmitted = userRole === UserRole.ANALYST ? isSubmittedAnalyst : isSubmittedReviewer;
    const { page, selectedPage, pagesVisited } = useSelectedPage();
    const [
        closeEditObligationSummaryModal,
        editObligationSummaryModal,
        openEditObligationSummaryModal,
        saveChangesModal,
    ] = useEditObligationSummaryModal();
    const [tableHeaders, setTableHeaders] = useState<IHeader[]>([]);

    useEffect(() => {
        if (REACT_APP_CUSTOM_FOR_CLIENT === 'rwa') {
            setTableHeaders(obligationsHeaderColumnDefinitionRwa);
        } else {
            setTableHeaders(obligationsHeaderColumnDefinition);
        }
    }, [REACT_APP_CUSTOM_FOR_CLIENT]);

    useEffect(() => {
        if (userRole === UserRole.REVIEWER && allPagesFiles.home.length) {
            dispatch(getSavedObligation(hash));
        }
    }, [userRole]);

    useEffect(() => {
        if (obligations.obligationsToDocumentExtractionTable.length === 0) {
            setCheckedState([]);
            setCheckedStateAll(false);
        }
    }, [obligations.obligationsToDocumentExtractionTable]);

    if (!obligationsToSave?.length || (userRole === UserRole.REVIEWER && !isSubmittedAnalyst))
        return (
            <div data-testid='nexus-draft-obligation-summary'>
                <TitleBar title={i18n.t('obligationSummary.titleDraftObligations')} />
                <NoRecordsTemplate />
            </div>
        );

    const handleSave = () => {
        const body: SavedObligationData = {
            Reviewer: 'string',
            Submit: 'string',
            User: 'string',
            User_ID: 'string',
            citation: fullObligationsData.citation,
            comments: 'string',
            draftobg: obligationsToSave,
            filesize: 0,
            regulation_id: fullObligationsData.regulation_id,
            regulation_name: fullObligationsData.regulation_name,
            regulation_type: 'string',
            run_id: hash,
            source_url: fullObligationsData.source_url,
            status: 'string',
        };

        if (userRole !== UserRole.ANALYST) {
            /**
             * @Backend not ready before DEMO 28th October 2022
             * todo when new JAVA Backend has endpoint create ACTION.saveReviewerObligations
             * @Backend not ready before 17th January 2023
             * todo still need to implement this JAVA API
             * */
            dispatch(saveReviewerObligations(body));
            return;
        }

        dispatch(saveAnalystObligations(body));
    };

    const handleSubmit = () => {
        // handleSave();

        dispatch(setStatus({ status: 'isSubmittedDocumentExtraction', userRole, value: true }));
        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 3000,
                    variant: 'success',
                },
            ]),
        );
    };

    const tableData = createBodyTable({
        checkedState,
        data: obligations.obligationsToDocumentExtractionTable,
        handleOnChange,
        isSubmitted,
        openDescriptionModal,
        openEditObligationSummaryModal,
        setCheckedState,
    });
    return (
        <div className='obligation-summary'>
            <TitleBar title={i18n.t('obligationSummary.titleDraftObligations')} />
            <TableTopBar
                isHidden={checkedState.every((e) => !e) || isSubmitted}
                isSearchVisible={REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa'}
                tableData={tableData}
                checkedState={checkedState}
                statusBy={statusByPropertyStep1}
                callbackFn={updateStatusInObligationsToDocumentExtractionTable}
            />
            <NexusTable type='custom'>
                <ObligationTableHeaderComponent
                    statusBy={statusBy}
                    checkedStateAll={checkedStateAll}
                    handleOnChangeAll={handleOnChangeAll}
                    checkedState={checkedState}
                    setCheckedState={setCheckedState}
                    setCheckedStateAll={setCheckedStateAll}
                    disabledSelectAll={isSubmitted}
                    headers={tableHeaders}
                />
                {!!tableData?.length && (
                    <TableBodyComponent
                        bodyTestId='obligation-summary-table-body-id'
                        tableData={tableData}
                        pagesVisited={pagesVisited}
                        checkedState={checkedState}
                        setCheckedState={setCheckedState}
                        decideOn={statusBy}
                        decideOnApplicableColumns={() => decideOnApplicableColumns(statusBy, tableHeaders)}
                    />
                )}
                <PaginationComponent
                    selectedPage={selectedPage}
                    offset={0}
                    limit={limit}
                    size={obligationsToSave.length}
                    page={page}
                />
            </NexusTable>
            <ControlButtons
                submit={handleSubmit}
                save={handleSave}
                validate={isSubmitted || validateTableRows({ userRole, obligations }, ButtonType.SUBMIT)}
                validateSave={isSubmitted || validateTableRows({ userRole, obligations }, ButtonType.SAVE)}
            />
            <ModalComponent
                showModal={descriptionModal.open}
                closeModal={closeDescriptionModal}
                headerContent={descriptionModal.header ?? ''}
                modalBodyContent={descriptionModal.description}
                primaryBtnText={'Close'}
                primaryBtnOnclickCallBack={closeDescriptionModal}
                isSecondButton={false}
                size='lg'
                footerOnRight={true}
            />
            <ModalComponent
                showModal={editObligationSummaryModal.open}
                closeModal={closeEditObligationSummaryModal}
                modalBodyContent={editObligationSummaryModal.bodyContent}
                headerContent={editObligationSummaryModal.header ?? ''}
                primaryBtnText={'Cancel'}
                primaryBtnOnclickCallBack={closeEditObligationSummaryModal}
                secondaryBtnText={'Save changes'}
                secondaryBtnOnclickCallBack={saveChangesModal}
                footerOnRight={true}
                className={`edit-modal-${REACT_APP_CUSTOM_FOR_CLIENT}`}
            />
        </div>
    );
};

export default ObligationSummary;
