import './overallSkeletonPane.scss';

import { NexusCard } from '@nexus/react';
import NotificationsContainer from 'app/components/reusable/notificationsContainer/NotificationsContainer';
import { ReactNode } from 'react';
import { clearNotifications } from '../home/redux/homeActions';
import { selectNotificationsData } from '../home/redux/homeSelectors';
import { useAppDispatch } from 'store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

type Props = {
    user?: ReactNode;
    navigation?: ReactNode;
    operationsTop?: ReactNode;
    operationsBottom?: ReactNode;
};

const OverallSkeletonPane: React.FC<Props> = ({ user, navigation, operationsTop, operationsBottom }) => {
    const notificationsData = useSelector(selectNotificationsData);
    const dispatch = useAppDispatch();

    useEffect(() => {
        return () => {
            dispatch(clearNotifications());
        };
    }, []);

    return (
        <div className='nexus-row main-container extended-container-bug-fix' data-testid='nexus-overall-skeleton-pane'>
            <NotificationsContainer notifications={notificationsData} />
            {REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa' ? (
                <>
                    <div className='nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-sm-4 nexus-col-md-3 nexus-col-lg-4 nexus-col-xl-4 nexus-col-2xl-4 left-box nexus-p-1'>
                        <NexusCard className='left-box__user-card'>{user}</NexusCard>
                    </div>
                    <div className='nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-sm-4 nexus-col-md-5 nexus-col-lg-8 nexus-col-xl-8 nexus-col-2xl-8 right-box nexus-flex nexus-flex-col nexus-pt-1 nexus-pb-1 nexus-pr-1'>
                        <NexusCard className='nexus-rhythm-bottom-1'>{navigation}</NexusCard>
                        <NexusCard className='nexus-flex nexus-flex-col nexus-flex-grow'>
                            <div className='nexus-rhythm-bottom-2'>{operationsTop}</div>
                            <div className='nexus-flex-grow'>{operationsBottom}</div>
                        </NexusCard>
                    </div>
                </>
            ) : (
                <div
                    className={`nexus-col-2xs-2 nexus-col-xs-4 nexus-col-sm-6 nexus-col-sm-6 nexus-col-md-8 nexus-col-lg-10 nexus-col-xl-12 nexus-col-2xl-14 right-box-${REACT_APP_CUSTOM_FOR_CLIENT} nexus-flex nexus-flex-col nexus-pt-1 nexus-pb-1 nexus-pr-1`}
                >
                    <NexusCard className='nexus-rhythm-bottom-1'>{navigation}</NexusCard>
                    <NexusCard className='nexus-flex nexus-flex-col nexus-flex-grow'>
                        <div className='nexus-rhythm-bottom-2'>{operationsTop}</div>
                        <div className='nexus-flex-grow'>{operationsBottom}</div>
                    </NexusCard>
                </div>
            )}
        </div>
    );
};

export default OverallSkeletonPane;
