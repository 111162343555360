import { AnyAction, combineReducers } from 'redux';
import { auth, dashboard, homeData, newHomeObligation } from './coreReducers';

import persistConfig from '../config/persistConfig';
import { persistReducer } from 'redux-persist';

// If specific reducers are unnecessary for an implementation, simply remove the references here.
//const { auth, account, individual, messaging } = core;

const rootReducer = persistReducer<ReturnType<typeof auth>, AnyAction>(
    persistConfig,
    combineReducers({
        auth,
        dashboard,
        homeData,
        newHomeObligation,
    }),
);

export default rootReducer;
