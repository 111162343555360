import './descriptionComponentModal.scss';

import * as React from 'react';

import TooltipComponent from '../../nds/tooltip/tooltip';
import i18n from 'locales/i18n';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

interface IDescriptionComponentModal {
    description: string;
    confidenceScore: number;
    subTitle: string;
    title: string;
    openDescriptionModal: (
        description: string,
        confidence: number,
        name: string,
        title: string,
        hideConfidence: boolean,
    ) => void;
    tooltipText: string;
    popupDisplayText?: string;
    hideConfidence?: boolean;
}

const DescriptionComponentModal: React.FC<IDescriptionComponentModal> = ({
    description,
    confidenceScore = 0,
    subTitle,
    title,
    openDescriptionModal,
    tooltipText,
    popupDisplayText = '',
    hideConfidence = false,
}) => {
    return (
        <div
            className='area-to-click'
            onClick={(e) => {
                e.stopPropagation();
                openDescriptionModal(description, confidenceScore, subTitle, title, hideConfidence);
            }}
        >
            <TooltipComponent
                allowClick={false}
                message={i18n.t(tooltipText)}
                placement='bottom'
                className={`tooltip-${REACT_APP_CUSTOM_FOR_CLIENT}`}
            >
                <span data-testid='description-to-click-tableToAuMapping' className='ellipsis'>
                    {popupDisplayText ? popupDisplayText : description}
                </span>
            </TooltipComponent>
        </div>
    );
};

export default DescriptionComponentModal;
