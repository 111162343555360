import * as ACTIONS from './dashboardActions';

import {
    IDashboardAPIData,
    ITimeNotification,
    SelectOption,
    User,
    VariantChose,
} from 'constants/commonExportedInterfaces';
import { createSlice, isRejected } from '@reduxjs/toolkit';

import dashboardFakeData from '../fakeTableData';
import i18n from 'locales/i18n';

interface IDashboard {
    cases: IDashboardAPIData[];
    analysts: SelectOption[];
    notifications: ITimeNotification[];
}

const INITIAL_STATE_DASHBOARD: IDashboard = {
    // TODO: change dashboardFakeData to [] when API call wil be established
    analysts: [] as SelectOption[],
    cases: [...dashboardFakeData] as IDashboardAPIData[],
    notifications: [] as ITimeNotification[],
};

const dashboard = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(ACTIONS.getUsers.fulfilled, (state, action) => {
                const options: SelectOption[] = [];
                action.payload?.data.forEach((user: User) =>
                    options.push({
                        disabled: false,
                        label: user.user_full_name,
                        value: user.user_full_name,
                    }),
                );

                return {
                    ...state,
                    analysts: options,
                };
            })
            .addCase(ACTIONS.deleteCase, (state, action) => {
                return {
                    ...state,
                    cases: state.cases.map((elem) => {
                        if (elem.caseID === action.payload.caseID)
                            return { ...elem, cancelationReason: action.payload.cancelationReason };
                        return elem;
                    }),
                    notifications: [
                        ...state.notifications,
                        {
                            message: `${i18n.t('cancelCaseModal.deleteCaseSuccessMessage')}`,
                            time: 5000,
                            variant: VariantChose.SUCCESS,
                        },
                    ],
                };
            })
            .addCase(ACTIONS.createNewCase, (state, action) => {
                return {
                    ...state,
                    cases: [...state.cases, action.payload],
                    notifications: [
                        ...state.notifications,
                        {
                            message: `${i18n.t('newCaseModal.createCasesSuccessMessage')}`,
                            time: 5000,
                            variant: VariantChose.SUCCESS,
                        },
                    ],
                };
            })
            .addCase(ACTIONS.changeAssignedAnalyst, (state, action) => {
                return {
                    ...state,
                    cases: state.cases.map((elem) => {
                        if (elem.caseID === action.payload.caseID)
                            return { ...elem, assignedTo: action.payload.analyst };
                        return elem;
                    }),
                    notifications: [
                        ...state.notifications,
                        {
                            message: `${i18n.t('reassignWorkModal.reassignWorkSuccessMessage')}`,
                            time: 5000,
                            variant: VariantChose.SUCCESS,
                        },
                    ],
                };
            })
            .addCase(ACTIONS.addNotification, (state, action) => {
                return {
                    ...state,
                    notifications: action.payload,
                };
            })
            .addCase(ACTIONS.clearNotifications, (state) => {
                return {
                    ...state,
                    notifications: [] as ITimeNotification[],
                };
            })
            .addCase(ACTIONS.sortCases, (state, action) => {
                return {
                    ...state,
                    cases: state.cases
                        .slice()
                        .sort((a, b) =>
                            a[action.payload as keyof typeof a].localeCompare(b[action.payload as keyof typeof b]),
                        ),
                };
            })
            .addMatcher(isRejected(ACTIONS.getUsers), (state, action) => {
                state.notifications = [
                    ...state.notifications,
                    {
                        message: ` ${action.payload}: ${i18n.t('dashboard.downloadingUsersFail')}`,
                        time: 5000,
                        variant: VariantChose.ERROR,
                    },
                ];
            });
    },
    initialState: INITIAL_STATE_DASHBOARD,
    name: 'dashboard',
    reducers: {},
});

export default dashboard.reducer;
