import { DecisionDescriptor, IDashboardBodyData, IHeader } from 'constants/commonExportedInterfaces';
import { IAssessControlsRow } from 'constants/commonExportedInterfaces';
import {
    IObligationRow as IObligationRowAuMapping,
    IObligationRowScreen2,
    ISummaryRowScreen5,
} from 'app/components/tableToAuMapping/ITableToAuMapping';
import { IObligationRow as IObligationRowObligationSummary } from 'app/components/obligationSummary/ITableToObligationSummary';
import React from 'react';

interface IRowBody {
    data:
        | IObligationRowAuMapping
        | IDashboardBodyData
        | IObligationRowObligationSummary
        | IAssessControlsRow
        | IObligationRowScreen2
        | ISummaryRowScreen5;
    decideOn: DecisionDescriptor;
    decideOnApplicableColumns?: (decideOn: DecisionDescriptor) => IHeader[];
    classes?: string;
}

const RowBody: React.FC<IRowBody> = ({ data, decideOn, decideOnApplicableColumns, classes }) => {
    return (
        <tr data-testid='table-body-row' className={classes}>
            {decideOnApplicableColumns &&
                decideOnApplicableColumns(decideOn).map((elem: IHeader) => (
                    <td
                        style={{ width: `${elem.minWidth}px` }}
                        data-testid={`table-body-row-${elem.field}`}
                        className={`table-${elem.field}`}
                        key={elem?.field}
                    >
                        {data[elem?.field as keyof typeof data]}
                    </td>
                ))}
        </tr>
    );
};

const MemoRowBody = React.memo(RowBody);

export default MemoRowBody;
