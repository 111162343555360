import { IUserProfileAttributes } from 'constants/commonExportedInterfaces';
import { NexusCard } from '@nexus/react';
import React from 'react';
import i18n from 'locales/i18n';
import { selectObligation } from 'app/views/home/redux/homeSelectors';
import { useSelector } from 'react-redux';

const ReviewerProfileView: React.FC = () => {
    const approverData = useSelector(selectObligation);

    const approverAttributes: Array<IUserProfileAttributes> = [
        {
            label: i18n.t('userProfileView.regulationID'),
            testId: 'regulation-id',
            value: approverData?.regulation_id,
        },
        {
            label: i18n.t('userProfileView.regulationName'),
            testId: 'regulation-name',
            value: approverData?.regulation_name,
        },
        {
            label: i18n.t('userProfileView.citation'),
            testId: 'citation',
            value: approverData?.citation,
        },
        {
            label: i18n.t('userProfileView.sourceURL'),
            testId: 'source_url',
            value: approverData?.source_url,
        },
    ];

    return (
        <NexusCard className='nexus-mt-2'>
            <>
                {approverAttributes.map((element, index) => {
                    return (
                        <div
                            className='nexus-row nexus-col-xs-4'
                            data-testid={element.testId}
                            key={`${element}-${index}`}
                        >
                            <p>
                                {element.label} : {element.value}
                            </p>
                        </div>
                    );
                })}
            </>
        </NexusCard>
    );
};

export default ReviewerProfileView;
