import './controlsMapping.scss';

import {
    AU_Mapping,
    Applicable_aus,
    IAuMappingData,
    IExpandedRow,
    SavedControlMapping,
    StatusChose,
    UserRole,
    ButtonType,
} from 'constants/commonExportedInterfaces';
import React, { useEffect, useState } from 'react';
import {
    addNotificationData,
    getSavedMapAu,
    saveAnalystControlMapping,
    setPaginationExternally,
} from 'app/views/home/redux/homeActions';
import {
    getUserRole,
    retrieveAllPagesFile,
    retrieveHashValue,
    selectAuMappingToSave,
    selectNotificationsData,
} from 'app/views/home/redux/homeSelectors';

import Arrows from '../reusable/arrows/Arrows';
import ControlButtons from '../reusable/controlButtons/ControlButtons';
import NoRecordsTemplate from '../reusable/noRecords/NoRecordsTemplate';
import PaginationComponent from '../nds/pagination/pagination';
import RowExpansionControlsMapping from '../reusable/rowExpansion/RowExpansionControlsMapping';
import TitleBar from '../reusable/titleBar/TitleBar';
import { headerParent } from './tableHeaders';
import i18n from 'locales/i18n';
import { limit } from 'constants/constants';
import { updateStatusInAuToSave } from 'app/views/home/redux/homeActions';
import { useAppDispatch } from 'store';
import { useSelector } from 'react-redux';
import { buttonValidation } from 'services/buttonValidation';
import { setStatus } from 'app/views/home/redux/newHomeObligationAction';
import { selectIsSubmittedControlMappingTableAnalyst } from 'app/views/home/redux/newHomeSelectors';

const ControlsMapping: React.FC = () => {
    const dispatch = useAppDispatch();
    const auMappingToSave = useSelector(selectAuMappingToSave);
    const notificationsData = useSelector(selectNotificationsData);
    const allPagesFiles = useSelector(retrieveAllPagesFile);
    const userRole = useSelector(getUserRole);
    const hash = useSelector(retrieveHashValue);
    const [page, setPage] = useState<number>(1);
    const [expandedRow, setExpandedRow] = useState<IExpandedRow>({} as IExpandedRow);
    const isSubmittedAnalyst: boolean = useSelector(selectIsSubmittedControlMappingTableAnalyst);

    const selectedPage = (data: number) => {
        setPage(data);
        dispatch(setPaginationExternally(false));
    };

    const pagesVisited = () => {
        return (page - 1) * limit;
    };

    useEffect(() => {
        setPage(1);
        if (userRole === UserRole.REVIEWER && allPagesFiles.step2.length) {
            dispatch(getSavedMapAu(hash));
        }
    }, [userRole]);

    const onClickRowChange = (name: string, isOpen: boolean) => setExpandedRow({ ...expandedRow, [name]: isOpen });

    const validateTableRows = (buttonType: ButtonType) => {
        const option = userRole === UserRole.REVIEWER ? 'status' : 'status_to_be_reviewed';
        const rowsStatuses = auMappingToSave.flatMap((control: AU_Mapping) =>
            control.applicable_aus.map((aus: Applicable_aus) => aus[option as keyof typeof aus]),
        );
        const valid = buttonValidation(buttonType, rowsStatuses);
        return !valid;
    };

    const handleSave = async () => {
        // TODO SavedControlMapping must be filled

        const body: SavedControlMapping = {
            Reviewer: 'string',
            Submit: 'string',
            User: 'string',
            User_ID: 'string',
            au_risk_control_filename: 'string',
            au_risk_control_filesize: 0,
            aumapping: auMappingToSave,
            citation: 'string',
            comments: 'string',
            regulation_id: 'string',
            regulation_name: 'string',
            run_id: hash,
            source_url: 'string',
            status: 'string',
        };

        dispatch(saveAnalystControlMapping(body));
    };

    const handleSubmit = () => {
        handleSave();

        dispatch(
            addNotificationData([
                ...notificationsData,
                {
                    message: i18n.t('generalOnSubmitAnswer.success'),
                    time: 5000,
                    variant: 'success',
                },
            ]),
        );

        dispatch(setStatus({ status: 'isSubmittedControlMappingTable', userRole, value: true }));
    };

    const handleOptionsChange = (value: StatusChose, name: string, detectOpenRow: string) => {
        dispatch(updateStatusInAuToSave({ detectOpenRow, name, status: value, user: userRole }));
    };

    return (
        <div data-testid='nexus-au-to-controls-mapping' className='nexus-au-to-controls-mapping'>
            <TitleBar title={i18n.t<string>('controlsMapping.controlMappingTitle')} />
            {!auMappingToSave?.length || (userRole === UserRole.REVIEWER && !isSubmittedAnalyst) ? (
                <NoRecordsTemplate />
            ) : (
                <>
                    <table className='nexus-table'>
                        <thead className='border'>
                            <tr>
                                {headerParent.map(({ id, label }) => (
                                    <th key={id} data-testid={`column-header-${id}`}>
                                        {label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {auMappingToSave
                                ?.slice(pagesVisited(), pagesVisited() + limit)
                                .map((values: IAuMappingData) => {
                                    const { obligation_name, ob_id, applicable_aus } = values;
                                    return (
                                        <React.Fragment key={obligation_name}>
                                            <tr data-testid={`row-${ob_id}`} className='border'>
                                                <td>
                                                    <Arrows
                                                        obligation={values}
                                                        onClickRowChange={onClickRowChange}
                                                        expandedRow={expandedRow}
                                                    />
                                                </td>
                                                <td data-testid='au-mapping-obligation_id'>{ob_id}</td>
                                                <td data-testid='au-mapping-obligation_name'>{obligation_name}</td>
                                            </tr>
                                            {expandedRow[obligation_name] && (
                                                <RowExpansionControlsMapping
                                                    data={applicable_aus}
                                                    detectOpenRow={obligation_name}
                                                    handleOptionsChange={handleOptionsChange}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </tbody>
                    </table>

                    <PaginationComponent selectedPage={selectedPage} offset={0} limit={limit} size={1} page={page} />

                    <ControlButtons
                        submit={handleSubmit}
                        save={handleSave}
                        validate={validateTableRows(ButtonType.SUBMIT)}
                        validateSave={validateTableRows(ButtonType.SAVE)}
                    />
                </>
            )}
        </div>
    );
};

export default ControlsMapping;
