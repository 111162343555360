import {
    AssessControlStatus,
    EditedObligationSumText,
    IAllDocumentsData,
    IFilterRequest,
    IObigationWIthRulesID,
    ISummaryListRequest,
    ITimeNotification,
    ObligationStatus,
    ReviewerDataStep,
    SavedAssessControl,
    SavedControlMapping,
    SavedObligationData,
    UploadFunctionProp,
} from 'constants/commonExportedInterfaces';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch, post } from 'utils/fetch';
import fileSaver from 'file-saver';

import { REACT_APP_BASE_URL, REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';
import actionConst from './homeConst';
import { getBaseUrl } from './newHelperFunction';

export const getSavedMapAu = createAsyncThunk(actionConst.auMapping, async (run_id: string, { rejectWithValue }) => {
    try {
        const response = await get({ baseURL: `${REACT_APP_BASE_URL}/api/get_aumapping/${run_id}`, timeout: 1000000 });
        return response;
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
});

export const getSavedObligation = createAsyncThunk(
    actionConst.obligationData,
    async (run_id: number, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${REACT_APP_BASE_URL}/api/get_draftobligation/${run_id}`,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getSummaryList = createAsyncThunk(
    actionConst.summaryDataForEndToEndMapping,
    async (payload: ISummaryListRequest, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/rcm-demo/v1/rcm/obligations/summary`,
                data: payload,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getRestFilterResults = createAsyncThunk(
    actionConst.filterResults,
    async (payload: IFilterRequest, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/searchCriteria`,
                data: payload,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const downloadExcelSummary = createAsyncThunk(
    actionConst.downloadExcel,
    async (payload: IFilterRequest, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `https://nexus4risk-backend-rcm-rwa-flex.sbp.eyclienthub.com/rcm-demo/v1/rcm/obligations/download`,
                data: payload,
                responseType: 'arraybuffer',
                timeout: 1000000,
            });
            var blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            fileSaver.saveAs(blob, 'FilteredData.xlsx');
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getSavedAssessControl = createAsyncThunk(
    actionConst.assessControl,
    async (run_id: string, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${REACT_APP_BASE_URL}/api/get_validate5ws/${run_id}`,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const uploadObligationFileToBackend = createAsyncThunk(
    actionConst.uploadedObligationFileData,
    async (payload: UploadFunctionProp, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/${getBaseUrl()}/pdfObligationData/${payload.docType}`,
                data: payload.data,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getRulesToProcessMapping = createAsyncThunk(
    actionConst.getRulesToProcessMapping,
    async (run_id: number, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${REACT_APP_BASE_URL}/${getBaseUrl()}/mapProcesses/${run_id}?size=100`,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getProcessToRiskMapping = createAsyncThunk(
    actionConst.getProcessToRiskMapping,
    async (run_id: number, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${REACT_APP_BASE_URL}/${getBaseUrl()}/mapRisks/${run_id}`,
                timeout: 1000000,
            });

            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const getRiskToControlMapping = createAsyncThunk(
    actionConst.getRiskToControlMapping,
    async (run_id: number, { rejectWithValue }) => {
        try {
            const response = await get({
                baseURL: `${REACT_APP_BASE_URL}/${getBaseUrl()}/mapControls/${run_id}`,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const uploadAuMappingFileToBackend = createAsyncThunk(
    actionConst.uploadAuMappingFileToBackend,
    async (payload: UploadFunctionProp, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/api/mapAUs`,
                data: payload.data,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const uploadAssessControlFileToBackend = createAsyncThunk(
    actionConst.uploadAssessControlFileToBackend,
    async (payload: UploadFunctionProp, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/api/validate5ws`,
                data: payload.data,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const saveReviewerObligations = createAsyncThunk(
    actionConst.saveReviewerObligations,
    async (body: SavedObligationData, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/api/save_draftobligation/` /** replace API */,
                data: body,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const saveAnalystObligations = createAsyncThunk(
    actionConst.saveAnalystObligations,
    async (body: SavedObligationData, { rejectWithValue }) => {
        try {
            if (REACT_APP_CUSTOM_FOR_CLIENT) {
                const response = 'test';
                return response;
            } else {
                const response = await post({
                    baseURL: `${REACT_APP_BASE_URL}/api/save_draftobligation/`,
                    data: body,
                    timeout: 1000000,
                });
                return response;
            }
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const saveSummaryTextWithRulesID = createAsyncThunk(
    actionConst.saveSummaryTextWithRulesID,
    async (body: IObigationWIthRulesID, { rejectWithValue }) => {
        try {
            if (REACT_APP_CUSTOM_FOR_CLIENT) {
                const ruleId = body.rule_id;
                const splittedRuleId = '%23' + ruleId.replace('#', '');
                const response = await patch({
                    baseURL: `${REACT_APP_BASE_URL}/rcm-demo/v1/rcm/obligations/${splittedRuleId}?page=1&size=0`,
                    // data: testR,
                    data: body.putBody,
                    timeout: 1000000,
                });
                return response;
            } else {
                const response = await post({
                    baseURL: `${REACT_APP_BASE_URL}/api/save_draftobligation/`,
                    data: body,
                    timeout: 1000000,
                });
                return response;
            }
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const saveAnalystControlMapping = createAsyncThunk(
    actionConst.saveAnalystControlMapping,
    async (body: SavedControlMapping, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/api/save_aumapping/`,
                data: body,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const saveAnalystAssessControl = createAsyncThunk(
    actionConst.saveAnalystAssessControl,
    async (body: SavedAssessControl, { rejectWithValue }) => {
        try {
            const response = await post({
                baseURL: `${REACT_APP_BASE_URL}/api/save_validate5ws/`,
                data: body,
                timeout: 1000000,
            });
            return response;
        } catch (error: any) {
            return rejectWithValue(error.message);
        }
    },
);

export const addReviewerData = createAction<ReviewerDataStep>(actionConst.addReviewerData);
export const selectedHomeCategory = createAction<string>(actionConst.selectedHomeCategory);
export const selectedHomeTypeFile = createAction<string>(actionConst.selectedHomeTypeFile);
export const selectedStep1Category = createAction<string>(actionConst.selectedStep1Category);
export const uploadAllPageFile = createAction<IAllDocumentsData>(actionConst.uploadAllPageFile);
export const storeTextAreaValue = createAction<string>(actionConst.textAreaData);
export const storeEditSumText = createAction<string>(actionConst.editSumText);
export const updateUserRole = createAction<string>(actionConst.userRole);
export const addNotificationData = createAction<ITimeNotification[]>(actionConst.notificationData);
export const saveObligationData = createAction<SavedObligationData[]>(actionConst.savedObligationData);
export const updateStatusInObligationsToSave = createAction<ObligationStatus>(actionConst.obligationsToSave);
export const updateStatusInAuToSave = createAction<ObligationStatus>(actionConst.auToSave);
export const updateEditSumText = createAction<EditedObligationSumText>(actionConst.obligationsToSaveSumText);
export const clearObligationsData = createAction<void>(actionConst.clearObligationsData);
export const clearAuMappingData = createAction<void>(actionConst.clearAuMappingData);
export const clearAssessControlData = createAction<void>(actionConst.clearAssessControlData);
export const updateStatusInAssessControlsToSave = createAction<AssessControlStatus>(actionConst.assessControlsToSave);
export const setPaginationExternally = createAction<boolean>(actionConst.setPaginationFromOutside);
export const clearNotifications = createAction<void>(actionConst.clearNotifications);
export const clearAllData = createAction<void>(actionConst.clearAllData);
