import HttpApi from 'i18next-http-backend';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEng from './en/translation.json';
import translationClient from './en/clientTranslation.json';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

i18n.use(initReactI18next)
    .use(HttpApi)
    .init({
        debug: false,
        defaultNS: 'translations',
        fallbackLng: 'en', // use en if detected lng is not available
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        keySeparator: '.', // we do not use keys in form messages.welcome
        lng: `${REACT_APP_CUSTOM_FOR_CLIENT}`,
        // have a common namespace used around the full app
        ns: ['form', 'translations'],
        resources: {
            en: {
                translations: translationEng,
            },
            rwa: {
                translations: translationClient,
            },
        },
    });

export default i18n;
