import { DataDescriptor, DecisionDescriptor, IHeader } from 'constants/commonExportedInterfaces';
import { isOdd } from 'services/isOdd';
import React from 'react';
import RowBody from '../rowBody/RowBody';
import { limit } from 'constants/constants';
import { useEffect } from 'react';
import './TableBodyComponent.scss';

interface IDashboardTableBody {
    tableData: DataDescriptor;
    pagesVisited: () => number;
    checkedState: boolean[];
    setCheckedState: (value: boolean[]) => void;
    decideOn?: DecisionDescriptor;
    decideOnApplicableColumns?: (decideOn: DecisionDescriptor) => IHeader[];
    bodyTestId?: string;
}
const TableBodyComponent: React.FC<IDashboardTableBody> = ({
    tableData,
    pagesVisited,
    checkedState,
    setCheckedState,
    decideOn,
    decideOnApplicableColumns,
    bodyTestId,
}) => {
    useEffect(() => {
        if (!checkedState?.length) setCheckedState(new Array(tableData.length).fill(false));
    }, []);

    return (
        <tr slot='tbody' className='table-body' data-testid={bodyTestId ?? ''}>
            {tableData?.slice(pagesVisited(), pagesVisited() + limit).map((data, index) => (
                <>
                    {decideOn && decideOnApplicableColumns ? (
                        <RowBody
                            classes={isOdd(index) ? '' : 'table-row-background'}
                            decideOnApplicableColumns={decideOnApplicableColumns}
                            decideOn={decideOn}
                            data={data}
                        />
                    ) : null}
                </>
            ))}
        </tr>
    );
};

export default TableBodyComponent;
