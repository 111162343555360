import * as ACTIONS from './homeActions';

import {
    IDistinctFilters,
    IFileUploadResponse,
    INewHomeObligationSliceReducer,
    IObligation,
    IObligationTable,
    IObligationTableScreen1,
    IProcessToRiskMappingStructure,
    IRiskToControlsMappingStructure,
    IRulesStatementStructure,
    ISummaryScreen5MappingStructure,
} from './newHomeObligationInterfaces';
import {
    // createFilterResults,
    createObligationsToAssessControl,
    createObligationsToAuTable,
    createObligationsToExtractionTable,
    createObligationsToRulesStatementTable,
    createProcessToRiskMapping,
    createRiskToControlMapping,
    createSummaryList,
} from './newHelperFunction';
import {
    getAllObligations,
    rejectionReasonForObligationAtScreen2,
    setStatus,
    updateEditSummaryText,
    updateStatusInObligationsToAssessControl,
    updateStatusInObligationsToAuTable,
    updateStatusInObligationsToDocumentExtractionTable,
    updateStatusInObligationsToRulesStatementTable,
    updateStatusInProcessToRiskMapping,
    updateStatusInRiskToControlMapping,
} from './newHomeObligationAction';
import { newApiDataInterface, newApiResponseBodyPostUploadAFile } from './newHomeObligationTestData';

import { IAssessControls } from 'constants/commonExportedInterfaces';
import { createSlice } from '@reduxjs/toolkit';

/** newHomeObligation.reducer.ts */

const INITIAL_STATE_NEW_HOME_OBLIGATION: INewHomeObligationSliceReducer = {
    assessControl: [] as IAssessControls[],
    assessControlToSave: [] as IAssessControls[],
    didAnalystSaveOnScreen1: false,
    didAnalystSaveOnScreen2: false,
    didAnalystSaveOnScreen3: false,
    didAnalystSaveOnScreen4: false,
    fileUploadResponse: { ...newApiResponseBodyPostUploadAFile } as IFileUploadResponse,
    filterResults: {} as IDistinctFilters,
    isSubmittedAssessControlTableAnalyst: false,
    isSubmittedAssessControlTableReviewer: false,
    isSubmittedControlMappingTableAnalyst: false,
    isSubmittedControlMappingTableReviewer: false,
    isSubmittedDocumentExtractionAnalyst: false,
    isSubmittedDocumentExtractionReviewer: false,
    isSubmittedObligationsToAuTableAnalyst: false,
    isSubmittedObligationsToAuTableReviewer: false,
    isSubmittedProcessToRiskMappingAnalyst: false,
    isSubmittedRiskToControlMappingAnalyst: false,
    obligations: [...newApiDataInterface] as IObligation[],
    obligationsToAuTable: [] as IObligationTable[],
    obligationsToDocumentExtractionTable: [] as IObligationTableScreen1[],
    obligationsToRulesStatementTable: [] as IRulesStatementStructure[],
    processToRiskMapping: [] as IProcessToRiskMappingStructure[],
    riskToControlsMapping: [] as IRiskToControlsMappingStructure[],
    status: false,
    summaryDataForEndToEndMapping: [] as ISummaryScreen5MappingStructure[],
};

type NewHomeObligation = Readonly<typeof INITIAL_STATE_NEW_HOME_OBLIGATION>;

const newHomeObligation = createSlice({
    extraReducers: (builder) => {
        builder
            /** Use when newAPI ready */
            // todo newApi https://nexus4risk-backend-rcm-dev.sbp.eyclienthub.com/ui-service/v1/swagger-ui/index.html#/
            .addCase(getAllObligations.pending, (state) => {
                state.status = true;
            })
            /** 1 get OLD get_draftobligation */
            .addCase(ACTIONS.uploadObligationFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    obligationsToDocumentExtractionTable: createObligationsToExtractionTable(
                        action.payload?.data?.obligations,
                    ),
                    // obligationsToRulesStatementTable: createObligationsToRulesStatementTable(mockRulesData?.result),
                };
            })
            .addCase(ACTIONS.getRulesToProcessMapping.fulfilled, (state, action) => {
                return {
                    ...state,
                    obligationsToRulesStatementTable: createObligationsToRulesStatementTable(
                        action.payload?.data?.result,
                    ),
                };
            })
            .addCase(ACTIONS.getProcessToRiskMapping.fulfilled, (state, action) => {
                return {
                    ...state,
                    processToRiskMapping: createProcessToRiskMapping(action.payload?.data?.result),
                };
            })
            .addCase(ACTIONS.getRiskToControlMapping.fulfilled, (state, action) => {
                return {
                    ...state,
                    riskToControlsMapping: createRiskToControlMapping(action.payload?.data?.result),
                };
            })
            .addCase(ACTIONS.getSummaryList.fulfilled, (state, action) => {
                return {
                    ...state,
                    summaryDataForEndToEndMapping: createSummaryList(action.payload?.data?.summaryList),
                };
            })
            // .addCase(ACTIONS.getRestFilterResults.fulfilled, (state, action) => {
            //     return {
            //         ...state,
            //         filterResults: createFilterResults(action.payload?.data),
            //     };
            // })
            .addCase(updateEditSummaryText, (state, action) => {
                return {
                    ...state,
                    obligationsToDocumentExtractionTable: [
                        ...state.obligationsToDocumentExtractionTable.map((obligation) => {
                            if (obligation.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return obligation;
                            return {
                                ...obligation,
                                obligation_summary_text: action.payload.editSummaryText,
                            };
                        }),
                    ],
                };
            })
            /** 2 get OLD get_aumapping */
            .addCase(ACTIONS.uploadAuMappingFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    obligationsToAuTable: createObligationsToAuTable(action.payload?.data.result),
                };
            })
            .addCase(ACTIONS.uploadAssessControlFileToBackend.fulfilled, (state, action) => {
                return {
                    ...state,
                    assessControl: createObligationsToAssessControl(action.payload?.data.result),
                };
            })
            /** 3 get OLD get_aumapping */
            /** 4 get OLD get_validate5ws */
            .addCase(updateStatusInObligationsToDocumentExtractionTable, (state, action) => {
                return {
                    ...state,
                    obligationsToDocumentExtractionTable: [
                        ...state.obligationsToDocumentExtractionTable.map((obligation) => {
                            if (obligation.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return obligation;
                            return {
                                ...obligation,
                                [action.payload.statusBy]: action.payload.value,
                            };
                        }),
                    ],
                };
            })
            .addCase(updateStatusInObligationsToAuTable, (state, action) => {
                return {
                    ...state,
                    obligationsToAuTable: [
                        ...state.obligationsToAuTable.map((obligation) => {
                            if (obligation.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return obligation;
                            return {
                                ...obligation,
                                [action.payload.statusBy]: action.payload.value,
                            };
                        }),
                    ],
                };
            })
            .addCase(updateStatusInObligationsToAssessControl, (state, action) => {
                return {
                    ...state,
                    assessControl: [
                        ...state.assessControl.map((obligation) => {
                            if (obligation.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return obligation;
                            return {
                                ...obligation,
                                [action.payload.statusBy]: action.payload.value,
                            };
                        }),
                    ],
                };
            })
            .addCase(updateStatusInObligationsToRulesStatementTable, (state, action) => {
                return {
                    ...state,
                    obligationsToRulesStatementTable: [
                        ...state.obligationsToRulesStatementTable.map((obligation) => {
                            if (obligation.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return obligation;
                            return {
                                ...obligation,
                                status: action.payload.value,
                            };
                        }),
                    ],
                };
            })
            .addCase(updateStatusInProcessToRiskMapping, (state, action) => {
                return {
                    ...state,
                    processToRiskMapping: [
                        ...state.processToRiskMapping.map((process) => {
                            return {
                                ...process,
                                risks: process.risks?.map((risk) => {
                                    if (risk.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return risk;
                                    return {
                                        ...risk,
                                        status: action.payload.value,
                                    };
                                }),
                            };
                        }),
                    ],
                };
            })
            .addCase(updateStatusInRiskToControlMapping, (state, action) => {
                return {
                    ...state,
                    riskToControlsMapping: [
                        ...state.riskToControlsMapping.map((risk) => {
                            return {
                                ...risk,
                                controls: risk.controls?.map((control) => {
                                    if (control.uniqueKeyID !== `${action.payload.uniqueKeyID}`) return control;
                                    return {
                                        ...control,
                                        status: action.payload.value,
                                    };
                                }),
                            };
                        }),
                    ],
                };
            })
            .addCase(setStatus, (state, action) => {
                return {
                    ...state,
                    [`${action.payload.status}${action.payload.userRole}`]: action.payload.value,
                };
            })
            .addCase(rejectionReasonForObligationAtScreen2, (state, action) => {
                return {
                    ...state,
                    obligationsToAuTable: state.obligationsToAuTable.map((elem) => {
                        if (elem.uniqueKeyID === action.payload.uniqueKeyID)
                            return { ...elem, rejectionReason: action.payload.rejectionReason };
                        return elem;
                    }),
                };
            })
            .addCase(ACTIONS.clearAllData, (state) => {
                return {
                    ...state,
                    isSubmittedRiskToControlMappingAnalyst: false,
                    isSubmittedDocumentExtractionAnalyst: false,
                    isSubmittedObligationsToAuTableAnalyst: false,
                    isSubmittedProcessToRiskMappingAnalyst: false,
                    obligationsToDocumentExtractionTable: [],
                    obligationsToRulesStatementTable: [],
                    processToRiskMapping: [],
                    riskToControlsMapping: [],
                };
            });
    },
    initialState: INITIAL_STATE_NEW_HOME_OBLIGATION as NewHomeObligation,
    name: 'newHomeObligation',
    reducers: {},
});

export default newHomeObligation.reducer;
