import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from '../views/login/loginPage';
import PageNotFound from '../views/404/404';
import HomePage from '../views/home/home';
import Logout from '../views/logout/logout';
import { AuthContainer, UnAuthContainer } from '../containers';
import ProfilePage from '../views/profile/profile';
import Dashboard from 'app/views/dashboard/Dashboard';

const AppRoutes = () => (
    <Router>
        <Routes>
            <Route
                path='/dashboard'
                element={
                    <AuthContainer>
                        <Dashboard />
                    </AuthContainer>
                }
            />
            <Route
                path='/home'
                element={
                    <AuthContainer>
                        <HomePage />
                    </AuthContainer>
                }
            />
            <Route
                path='/profile'
                element={
                    <AuthContainer>
                        <ProfilePage />
                    </AuthContainer>
                }
            />

            <Route
                path='/'
                element={
                    <UnAuthContainer>
                        <LoginPage />
                    </UnAuthContainer>
                }
            />

            <Route path='/logout' element={<Logout />} />

            <Route
                path='*'
                element={
                    <AuthContainer>
                        <PageNotFound />
                    </AuthContainer>
                }
            />
        </Routes>
    </Router>
);

export default AppRoutes;
