import RejectionModalsHeader from 'app/views/dashboard/RejectionModalsHeader';
import i18n from 'locales/i18n';
import { useState } from 'react';

interface IModal {
    open: boolean;
    header: React.ReactNode;
}

export function useRejectionModal(title: string) {
    const [modalData, setModalData] = useState({} as IModal);
    const [modalKey, setModalKey] = useState('');

    const openThisModal = (regulationName: string, caseID: number, uniqueKey?: string) => {
        setModalKey(uniqueKey ?? '');
        setModalData({
            header: (
                <RejectionModalsHeader
                    title={i18n.t<string>(`${title}`)}
                    caseID={caseID}
                    regulationName={regulationName}
                />
            ),
            open: true,
        });
    };

    const handleCloseModal = () => {
        setModalData({ header: '', open: false });
    };

    return [handleCloseModal, modalData, openThisModal, modalKey] as const;
}
