import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
    key: 'root',
    stateReconciler: autoMergeLevel2,
    storage: storageSession,
    whitelist: ['auth'],
};

export default persistConfig;
