import DashboardModalsHeader from 'app/views/dashboard/DashboardModalsHeader';
import i18n from 'locales/i18n';
import { useState } from 'react';

interface IModal {
    open: boolean;
    header: React.ReactNode;
}

export function useModal(title: string) {
    const [modalData, setModalData] = useState({} as IModal);
    const [modalID, setID] = useState('');

    const openThisModal = (caseID: string, regulationName: string) => {
        setID(caseID);
        setModalData({
            header: (
                <DashboardModalsHeader
                    title={i18n.t<string>(`${title}`)}
                    caseID={caseID}
                    regulationName={regulationName}
                />
            ),
            open: true,
        });
    };

    const handleCloseModal = () => {
        setModalData({ header: '', open: false });
    };

    return [handleCloseModal, modalData, openThisModal, modalID] as const;
}
