import { IHeader, StatusBy } from 'constants/commonExportedInterfaces';

import { NexusCheckbox } from '@nexus/react';
import React from 'react';
import decideOnApplicableColumns from 'services/decideOnApplicableColumns';

interface ITableHeaderComponent {
    statusBy: StatusBy;
    checkedState: boolean[];
    checkedStateAll: boolean;
    setCheckedState: (value: boolean[]) => void;
    setCheckedStateAll: (value: boolean) => void;
    handleOnChangeAll: (
        checkedState: boolean[],
        checkedStateAll: boolean,
        setCheckedState: (value: boolean[]) => void,
        setCheckedStateAll: (value: boolean) => void,
    ) => void;
    disabledSelectAll: boolean;
    headers: IHeader[];
}

const ObligationTableHeaderComponent: React.FC<ITableHeaderComponent> = ({
    statusBy,
    checkedStateAll,
    handleOnChangeAll,
    checkedState,
    setCheckedState,
    setCheckedStateAll,
    disabledSelectAll,
    headers,
}) => {
    return (
        <tr slot='thead'>
            {decideOnApplicableColumns(statusBy, headers).map((element) => (
                <th key={element.label} className={`custom-thead-style table-${element.field}`}>
                    {element.field === 'select' && (
                        <NexusCheckbox
                            checked={checkedStateAll}
                            onInput={() =>
                                handleOnChangeAll(checkedState, checkedStateAll, setCheckedState, setCheckedStateAll)
                            }
                            data-testid='obligation-summary-header-checkbox'
                            className='nexus-rhythm-2'
                            disabled={disabledSelectAll}
                        />
                    )}
                    {element.label}
                </th>
            ))}
        </tr>
    );
};

const MemoObligationTableHeaderComponent = React.memo(ObligationTableHeaderComponent);

export default MemoObligationTableHeaderComponent;
