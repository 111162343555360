import './container.scss';

import { AuthProvider } from '../../auth';
import Header from '../components/nds/header/header';
import React from 'react';
import { routeNames } from '../../constants/constants';
import store from '../../store';

type Props = {
    children?: React.ReactNode;
};
const headerNavBarData = [
    { title: routeNames.Home, link: '/home' },
    { title: routeNames.Profile, link: '/profile' },

    { title: routeNames.Logout, link: '/logout' },
];
const AuthContainer = ({ children }: Props) => (
    <React.Fragment>
        <AuthProvider store={store}>
            <Header home={'/home'} menuItems={headerNavBarData}></Header>
            <div className='hero-image'>
                <div>{children}</div>
            </div>
        </AuthProvider>
    </React.Fragment>
);

export default AuthContainer;
