import { IHeader } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';

export const assessControlHeaderColumnDefinition: IHeader[] = [
    {
        field: 'select',
        label: '',
        minWidth: 20,
    },
    {
        field: 'Control_Name',
        label: i18n.t('assessControlTable.controlType'),
        minWidth: 50,
    },
    {
        field: 'Control_Description',
        label: i18n.t('assessControlTable.controlDescription'),
        minWidth: 50,
    },
    {
        field: 'obligation_status_by_analyst',
        label: i18n.t('assessControlTable.approve/reject'),
        minWidth: 50,
    },
    {
        field: 'saved_analyst_status',
        label: i18n.t('assessControlTable.analystStatus'),
        minWidth: 50,
    },
    {
        field: 'obligation_status_by_reviewer',
        label: i18n.t('assessControlTable.approve/reject'),
        minWidth: 50,
    },
    {
        field: 'What',
        label: i18n.t('assessControlTable.what'),
        minWidth: 20,
    },
    {
        field: 'When',
        label: i18n.t('assessControlTable.when'),
        minWidth: 20,
    },
    {
        field: 'Who',
        label: i18n.t('assessControlTable.who'),
        minWidth: 20,
    },
    {
        field: 'Where',
        label: i18n.t('assessControlTable.where'),
        minWidth: 20,
    },
    {
        field: 'Why',
        label: i18n.t('assessControlTable.why'),
        minWidth: 20,
    },
];
