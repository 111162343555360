import React from 'react';

import { NexusHeader, NexusHeaderMenu, NexusMenuItem, NexusHeaderLogo, NexusIcon } from '@nexus/react';
// import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import Logo from '../../../../../src/logo.svg';
import { Link } from 'react-router-dom';
import './header.scss';
import { REACT_APP_CUSTOM_FOR_CLIENT } from '../../../../config/settings';

interface menuItem {
    icon?: string;
    link: string;
    title: string;
}
interface Props {
    home: string;
    menuItems: menuItem[];
}
const HeaderComponent: React.FC<Props> = ({ home, menuItems }) => (
    <NexusHeader class={`nexus-header-sticky main-header-${REACT_APP_CUSTOM_FOR_CLIENT}`} data-testid='nexus-header'>
        <NexusHeaderLogo class={`logo-style-${REACT_APP_CUSTOM_FOR_CLIENT}`}>
            <Link to={home}>
                <NexusIcon src={Logo} />
                <span className='nexus-visually-hidden'></span>
            </Link>
            <div className={`header-flex-style-${REACT_APP_CUSTOM_FOR_CLIENT}`}>
                <span className={`ref-header-part-${REACT_APP_CUSTOM_FOR_CLIENT}`}></span>
            </div>
        </NexusHeaderLogo>

        <NexusHeaderMenu data-testid='nexus-header-menu'>
            {menuItems.map((menuItem, idx) =>
                menuItem.icon ? (
                    <NexusMenuItem key={idx} data-testid={menuItem.title}>
                        <Link to={menuItem.link}>
                            <NexusIcon src={menuItem.icon}>{menuItem.title}</NexusIcon>
                        </Link>
                    </NexusMenuItem>
                ) : (
                    <NexusMenuItem key={idx} data-testid={menuItem.title}>
                        <Link to={menuItem.link}>{menuItem.title}</Link>
                    </NexusMenuItem>
                ),
            )}
        </NexusHeaderMenu>
    </NexusHeader>
);

export default HeaderComponent;
