import { IHeader } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';

export const endToEndMappingColumnDefinitionRwa: IHeader[] = [
    {
        field: 'ruleId',
        isSortable: false,
        label: i18n.t('tableToAuMapping.rulesid'),
    },
    {
        field: 'citationSection',
        isSortable: false,
        label: i18n.t('obligationSummary.citationSection'),
    },
    {
        field: 'rulesHighlightedText',
        isSortable: false,
        label: i18n.t('obligationSummary.descriptionModalHeader'),
    },
    {
        field: 'rulesSummaryText',
        isSortable: false,
        label: i18n.t('tableToAuMapping.ruleshighlightedtext'),
    },
    {
        field: 'processName',
        isSortable: false,
        label: i18n.t('tableToAuMapping.baselprocessname'),
    },
    {
        field: 'subProcessName',
        isSortable: false,
        label: i18n.t('tableToAuMapping.baselsubprocess'),
    },
    {
        field: 'riskLevel1',
        isSortable: false,
        label: i18n.t('endToEndMapping.riskLevel1'),
    },
    {
        field: 'riskLevel2',
        isSortable: false,
        label: i18n.t('endToEndMapping.riskLevel2'),
    },
    {
        field: 'riskId',
        isSortable: false,
        label: i18n.t('endToEndMapping.riskId'),
    },
    {
        field: 'riskDescription',
        isSortable: false,
        label: i18n.t('endToEndMapping.riskDescription'),
    },
    {
        field: 'controlId',
        isSortable: false,
        label: i18n.t('endToEndMapping.controlId'),
    },
    {
        field: 'controlType',
        isSortable: false,
        label: i18n.t('endToEndMapping.controlType'),
    },
    {
        field: 'controlDescription',
        isSortable: false,
        label: i18n.t('endToEndMapping.controlDescription'),
    },
];
