import { IHeader } from 'constants/commonExportedInterfaces';
import i18n from 'locales/i18n';

export const auMappingHeaderColumnDefinition: IHeader[] = [
    {
        field: 'select',
        isSortable: false,
        label: '',
    },
    {
        field: 'obligation_id',
        isSortable: false,
        label: i18n.t('tableToAuMapping.obligationId'),
    },
    {
        field: 'obligation_title',
        isSortable: false,
        label: i18n.t('tableToAuMapping.obligationName'),
    },
    {
        field: 'obligation_description',
        isSortable: false,
        label: i18n.t('tableToAuMapping.obligationDescription'),
    },
    {
        field: 'bu_name',
        isSortable: false,
        label: i18n.t('tableToAuMapping.auName'),
    },
    {
        field: 'obligation_confidence_score',
        isSortable: false,
        label: i18n.t('tableToAuMapping.confidence'),
    },
    {
        field: 'obligation_status_by_analyst',
        isSortable: false,
        label: i18n.t('tableToAuMapping.approveReject'),
    },
    {
        field: 'saved_analyst_status',
        isSortable: false,
        label: i18n.t('tableToAuMapping.analystStatus'),
    },
    {
        field: 'obligation_status_by_reviewer',
        isSortable: false,
        label: i18n.t('tableToAuMapping.approveReject'),
    },
];

export const auMappingHeaderColumnDefinitionForClient: IHeader[] = [
    {
        field: 'select',
        isSortable: false,
        label: '',
    },
    {
        field: 'rules_id',
        isSortable: false,
        label: i18n.t('tableToAuMapping.rulesid'),
    },
    {
        field: 'rules_highlighted_text',
        isSortable: false,
        label: i18n.t('tableToAuMapping.ruleshighlightedtext'),
    },
    {
        field: 'basel_process_name',
        isSortable: false,
        label: i18n.t('tableToAuMapping.baselprocessname'),
    },
    {
        field: 'basel_sub_process',
        isSortable: false,
        label: i18n.t('tableToAuMapping.baselsubprocess'),
    },
    {
        field: 'obligation_confidence_score',
        isSortable: false,
        label: i18n.t('tableToAuMapping.confidence'),
    },
    {
        field: 'obligation_status_by_analyst',
        isSortable: false,
        label: i18n.t('tableToAuMapping.approveReject'),
    },
    {
        field: 'saved_analyst_status',
        isSortable: false,
        label: i18n.t('tableToAuMapping.analystStatus'),
    },
    {
        field: 'obligation_status_by_reviewer',
        isSortable: false,
        label: i18n.t('tableToAuMapping.approveReject'),
    },
];
