import i18n from 'locales/i18n';

interface IHeaderControlsMapping {
    id: string;
    label: string;
}

export const headerParent: IHeaderControlsMapping[] = [
    {
        id: 'show_more',
        label: '',
    },
    {
        id: 'obligation_id',
        label: i18n.t('controlsMapping.obligationId'),
    },
    {
        id: 'obligation_name',
        label: i18n.t('controlsMapping.obligationName'),
    },
];

export const headerChildAnalyst: IHeaderControlsMapping[] = [
    {
        id: 'au_name',
        label: i18n.t('controlsMapping.assessmentUnitAuName'),
    },
    {
        id: 'control_id',
        label: i18n.t('controlsMapping.controlID'),
    },
    {
        id: 'control_type',
        label: i18n.t('controlsMapping.controlType'),
    },
    {
        id: 'control_desc',
        label: i18n.t('controlsMapping.controlDescription'),
    },
    {
        id: 'confidence',
        label: i18n.t('controlsMapping.confidence'),
    },
    {
        id: 'approve/reject',
        label: i18n.t('controlsMapping.approveReject'),
    },
];

export const headerChildReviewer: IHeaderControlsMapping[] = [
    {
        id: 'au_name',
        label: i18n.t('controlsMapping.assessmentUnitAuName'),
    },
    {
        id: 'control_id',
        label: i18n.t('controlsMapping.controlID'),
    },
    {
        id: 'control_type',
        label: i18n.t('controlsMapping.controlType'),
    },
    {
        id: 'control_desc',
        label: i18n.t('controlsMapping.controlDescription'),
    },
    {
        id: 'confidence',
        label: i18n.t('controlsMapping.confidence'),
    },
    {
        id: 'analyst_status',
        label: i18n.t('obligationSummary.analystStatus'),
    },
    {
        id: 'approve/reject',
        label: i18n.t('controlsMapping.approveReject'),
    },
];
