import i18n from 'locales/i18n';
import './rejectionRationaleModal.scss';
import TextareaComponent from 'app/components/nds/textarea/textarea';

interface IRejectionRationaleModalBody {
    setRejectionRationaleReason: React.Dispatch<React.SetStateAction<string>>;
    rejectionRationaleReason: string;
}

const RejectionRationaleModalBody: React.FC<IRejectionRationaleModalBody> = ({
    setRejectionRationaleReason,
    rejectionRationaleReason,
}) => {
    const typeRejectionRationaleReason = (event: React.FormEvent<HTMLNexusTextareaElement>) => {
        const target = event.target as HTMLNexusTextareaElement;
        setRejectionRationaleReason(target.value);
    };

    return (
        <div data-testid='rejection-rationale-modal-body' className='rejection-rationale-modal-body-container'>
            <label>{i18n.t<string>('rejectionRationaleModal.rejectionReason')}</label>
            <TextareaComponent
                placeholder={i18n.t<string>('rejectionRationaleModal.rejectionReasonHint')}
                onInputHandler={(event: React.FormEvent<HTMLNexusTextareaElement>) =>
                    typeRejectionRationaleReason(event)
                }
                value={rejectionRationaleReason}
                maxLength={300}
            />
            <div className='rejection-rationale-modal-body-characters-info nexus-row nexus-middle-2xs'>
                <p className='rejection-rationale-modal-body-characters-info-p'>300 characters max</p>
                <p
                    data-testid='cancel-case-modal-body-characters-check'
                    className='rejection-rationale-modal-body-characters-info-p right'
                >
                    {rejectionRationaleReason === '' ? '0' : rejectionRationaleReason.trim().length}/300
                </p>
            </div>
        </div>
    );
};

export default RejectionRationaleModalBody;
