const dashboardFakeData = [
    {
        assignedTo: 'User I',
        cancelationReason: '',
        caseID: '123',
        caseName: 'Lorem ipsum lorem lorem ipsum',
        caseType: 'Process',
        lastEdit: '1/11/2022',
        owner: 'User I',
        status: 'In progress',
    },
    {
        assignedTo: 'User I',
        cancelationReason: '',
        caseID: '124',
        caseName: 'Lorem ipsum lorem lorem ipsum',
        caseType: 'Process',
        lastEdit: '1/11/2022',
        owner: 'User I',
        status: 'In progress',
    },
    {
        assignedTo: 'User 3',
        cancelationReason: '',
        caseID: '125',
        caseName: 'Lorem ipsum lorem lorem ipsum',
        caseType: 'Process',
        lastEdit: '1/11/2022',
        owner: 'User I',
        status: 'Action required',
    },
    {
        assignedTo: 'User 4',
        cancelationReason: '',
        caseID: '126',
        caseName: 'Lorem ipsum lorem lorem ipsum',
        caseType: 'Process',
        lastEdit: '1/11/2022',
        owner: 'User I',
        status: 'Closed',
    },
    {
        assignedTo: 'User 5',
        cancelationReason: '',
        caseID: '127',
        caseName: 'Lorem ipsum lorem lorem ipsum',
        caseType: 'Process',
        lastEdit: '2/11/2022',
        owner: 'User 3',
        status: 'Closed',
    },
    {
        assignedTo: 'User I',
        cancelationReason: '',
        caseID: '128',
        caseName: 'Ipsum ipsum lorem',
        caseType: 'Process',
        lastEdit: '14/11/2022',
        owner: 'User 2',
        status: 'Closed',
    },
    {
        assignedTo: 'User I',
        cancelationReason: '',
        caseID: '129',
        caseName: 'Lorem ipsum lorem lorem ipsum',
        caseType: 'Process',
        lastEdit: '1/11/2022',
        owner: 'User I',
        status: 'In progress',
    },
];

export default dashboardFakeData;

export const fakeOptions = [
    {
        disabled: false,
        label: 'user 01',
        value: 'user 01',
    },
    {
        disabled: false,
        label: 'user 02',
        value: 'user 02',
    },
    {
        disabled: false,
        label: 'user 03',
        value: 'user 03',
    },
];
