import './tableTopBar.scss';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { IAuObligationStatus } from 'app/views/home/redux/newHomeObligationInterfaces';
import {
    IObligationRow as IObligationRowAuMapping,
    IObligationRowScreen2,
} from 'app/components/tableToAuMapping/ITableToAuMapping';
import { IObligationRow as IObligationRowObligationSummary } from 'app/components/obligationSummary/ITableToObligationSummary';
import MultipleChoiceConfirmationPanel from '../multipleChoiceConfirmationPanel/MultipleChoiceConfirmationPanel';
import React from 'react';
import SearchboxPanel from '../searchboxPanel/SearchboxPanel';
import { IAssessControlsRow } from 'constants/commonExportedInterfaces';

export interface ITableTopBar {
    tableData:
        | IObligationRowAuMapping[]
        | IObligationRowObligationSummary[]
        | IAssessControlsRow[]
        | IObligationRowScreen2[];
    checkedState: boolean[] | boolean[][];
    statusBy: string;
    isHidden?: boolean;
    isSearchVisible?: boolean;
    callbackFn: ActionCreatorWithPayload<IAuObligationStatus, string>;
    isCustomStatus?: boolean;
}

const TableTopBar: React.FC<ITableTopBar> = ({ isHidden, isSearchVisible = true, ...props }) => {
    return (
        <div className='nexus-row' data-testid='table-top-bar'>
            <SearchboxPanel isHidden={isHidden && isSearchVisible} />
            <MultipleChoiceConfirmationPanel isHidden={isHidden} {...props} />
        </div>
    );
};

export default TableTopBar;
