import './uploadedDocuments.scss';

import { IDocument, takeHomeDocumentsData, takeStep1DocumentsData } from 'app/views/home/redux/homeSelectors';
import React, { useEffect, useState } from 'react';

import DocumentDataBlock from '../reusable/documentDataBlock/DocumentDataBlock';
import { NexusCard } from '@nexus/react';
import i18n from 'locales/i18n';
import { useSelector } from 'react-redux';
import { REACT_APP_CUSTOM_FOR_CLIENT } from 'config/settings';

const UploadedDocuments: React.FC = () => {
    const nameLabel = i18n.t('uploadedDocuments.name');
    const docTypeLabel = i18n.t('uploadedDocuments.documentType');
    const homeDocuments: IDocument[] = useSelector(takeHomeDocumentsData);
    const step1Documents: IDocument[] = useSelector(takeStep1DocumentsData);
    const [docs, setDocs] = useState<IDocument[]>([]);

    useEffect(() => {
        setDocs([...homeDocuments, ...step1Documents]);
    }, [homeDocuments, step1Documents]);

    return (
        <>
            {REACT_APP_CUSTOM_FOR_CLIENT !== 'rwa' && (
                <NexusCard className='docs-card' data-testid='nexus-uploaded-documents'>
                    {docs && docs.length > 0 ? (
                        docs.map(({ name, docType }, index) => (
                            <div key={`${name}-${index}`} className='doc nexus-p-1'>
                                <DocumentDataBlock label={nameLabel} data={name} />
                                <DocumentDataBlock label={docTypeLabel} data={docType} />
                            </div>
                        ))
                    ) : (
                        <div className='doc nexus-p-1'>
                            <DocumentDataBlock label={nameLabel} data='not found' />
                            <DocumentDataBlock label={docTypeLabel} data='not found' />
                        </div>
                    )}
                </NexusCard>
            )}
        </>
    );
};

export default UploadedDocuments;
