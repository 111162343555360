import { NexusIcon, NexusInput, NexusSearch } from '@nexus/react';

import ActionIcSearch24px from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import React from 'react';

interface ISearchboxPanel {
    isHidden?: boolean;
}

const SearchboxPanel: React.FC<ISearchboxPanel> = ({ isHidden }) => {
    return (
        <div className={`nexus-col nexus-m-1 ${isHidden ? '' : 'nexus-visually-hidden'}`}>
            <NexusSearch>
                <NexusInput autocomplete='off' placeholder='Search'>
                    <NexusIcon src={ActionIcSearch24px}></NexusIcon>
                </NexusInput>
            </NexusSearch>
        </div>
    );
};

export default SearchboxPanel;
