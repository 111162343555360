import { newCaseModalCaseOptions, newCaseModalDocumentsOptions } from '../constants/newCaseModalConstants';

import { IFile } from 'constants/commonExportedInterfaces';
import Select from 'app/components/nds/select/select';
import SingleFileUploadComponent from 'app/components/nds/singleFileUpload/singleFileUpload';
import i18n from 'locales/i18n';

interface INewCaseModalFirstStep {
    uploadedDoc: IFile;
    setUploadedDoc: React.Dispatch<React.SetStateAction<IFile>>;
    additionalFnOnRemoveFile: () => void;
    newCaseType: string;
    setNewCaseType: React.Dispatch<React.SetStateAction<string>>;
    newDocType: string;
    setNewDocType: React.Dispatch<React.SetStateAction<string>>;
}

const NewCaseModalFirstStep: React.FC<INewCaseModalFirstStep> = ({
    uploadedDoc,
    setUploadedDoc,
    additionalFnOnRemoveFile,
    newCaseType,
    setNewCaseType,
    newDocType,
    setNewDocType,
}) => {
    const chooseCaseType = (value: string) => {
        setNewCaseType(value);
    };

    const chooseDocumentType = (value: string) => {
        setNewDocType(value);
    };

    return (
        <div data-testid='new-case-modal-first-step'>
            <div className='nexus-mb-3'>
                <label>{i18n.t<string>('newCaseModal.caseTypeLabel')}</label>
                <Select
                    options={newCaseModalCaseOptions}
                    selectedValue={newCaseType}
                    customOnChange={chooseCaseType}
                    specificTestId='new-case-modal-first-step-case-type'
                    specificOptionTestId='new-case-modal-first-step-case-type'
                    showSelectedValueOnInit={true}
                />
            </div>
            <div className='nexus-mb-3'>
                <label>{i18n.t<string>('newCaseModal.documentTypeLabel')}</label>
                <Select
                    classes='new-case-modal-body-document-type-select'
                    options={newCaseModalDocumentsOptions}
                    selectedValue={newDocType}
                    customOnChange={chooseDocumentType}
                    specificTestId='new-case-modal-first-step-document-type'
                    specificOptionTestId='new-case-modal-first-step-document-type'
                    showSelectedValueOnInit={true}
                />
            </div>
            <p className='nexus-mb-2'>{i18n.t<string>('newCaseModal.uploadInfo')}</p>
            <p className='upload-size-info'>{i18n.t<string>('newCaseModal.uploadSizeInfo')}</p>
            <SingleFileUploadComponent
                label={i18n.t<string>('uploadComponent.chooseFile')}
                fileSizeInfo={i18n.t<string>('uploadComponent.fileSize')}
                uploadedDoc={uploadedDoc}
                setUploadedDoc={setUploadedDoc}
                additionalFnOnRemoveFile={additionalFnOnRemoveFile}
            />
        </div>
    );
};

export default NewCaseModalFirstStep;
