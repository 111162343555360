import ChipComponent from 'app/components/nds/chip/chip';
import { percentage } from 'constants/constants';
import i18n from 'locales/i18n';
import { getValuePercentage } from 'services/getPercentagevalue';
import './dashboard.scss';

interface IRejectionModalsHeader {
    title: string;
    caseID: number;
    regulationName: string;
}

const RejectionModalsHeader: React.FC<IRejectionModalsHeader> = ({ title, caseID, regulationName }) => {
    return (
        <div className='dashboard-modal-header-container'>
            <h3 className='nexus-mb-1'>{title}</h3>
            <div className='nexus-row nexus-middle-xs'>
                <p className='nexus-mr-1 dashboard-modal-header-case-id'>{regulationName}</p>
                <p className='nexus-mr-1 dashboard-modal-header-regulation-name'>
                    | {i18n.t<string>('rejectionRationaleModal.confidence')}&nbsp;
                </p>
                <p>
                    <ChipComponent
                        title={getValuePercentage(caseID) + percentage}
                        contentStyles='rcm-chip-content-style'
                    />
                </p>
            </div>
        </div>
    );
};

export default RejectionModalsHeader;
