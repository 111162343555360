import i18n from 'locales/i18n';
import './dashboard.scss';

interface IDashboardModalsHeader {
    title: string;
    caseID: string;
    regulationName: string;
}

const DashboardModalsHeader: React.FC<IDashboardModalsHeader> = ({ title, caseID, regulationName }) => {
    return (
        <div className='dashboard-modal-header-container'>
            <h3 className='nexus-mb-1'>{title}</h3>
            <div className='nexus-row nexus-middle-xs'>
                <p className='nexus-mr-1 dashboard-modal-header-case-id'>
                    {i18n.t<string>('reassignWorkModal.reassignWorkModalCaseID')}: {caseID}{' '}
                </p>
                <p className='nexus-mr-1 dashboard-modal-header-regulation-name'>
                    | {i18n.t<string>('reassignWorkModal.reassignWorkModalRegulationName')}: {regulationName}
                </p>
            </div>
        </div>
    );
};

export default DashboardModalsHeader;
